import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import Rollbar from 'rollbar'
import vuetify from '@/plugins/vuetify'
import VueCompositionApi from '@vue/composition-api'
import { Chart, registerables } from 'chart.js'

import App from './App.vue'
import router from './router'
import store from './store'

Chart.register(...registerables)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = `Bearer ${store.state.token}`
        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)
Vue.prototype.$http = axios

Vue.prototype.$rollbar = new Rollbar({
    accessToken: 'dc3af83374e04a7f9f5a7057b9f382f0',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production',
        client: {
            javascript: {
                code_version: process.env.VUE_APP_VERSION,
            },
        },
    },
})

Vue.config.errorHandler = (err, vm) => {
    if (process.env.NODE_ENV != 'development') {
        vm.$rollbar.error(err)
    }
    throw err
}

Vue.use(Vuelidate)

Vue.use(VueClipboard)

Vue.use(VueCompositionApi)

Vue.filter('formatDate', function (value) {
    return moment(value).format('DD. MM. YYYY')
})

Vue.filter('formatDateTime', function (value) {
    return moment(value).format('DD. MM. YYYY HH:mm')
})

Vue.filter('formatTime', function (value) {
    return moment(value).format('HH:mm')
})

Vue.filter('limit', function (arr, limit) {
    return arr.slice(0, limit)
})

Vue.filter('truncate', function (value, length) {
    if (screen.width > 768) {
        return value
    }
    return value.length > length ? value.slice(0, length) + '...' : value
})

router.beforeEach((to, from, next) => {
    store.dispatch('resetBreadcrumbs')
    if (to.meta && to.meta.guestAuth) {
        next()
    } else {
        if (!store.getters.isAuthenticated) {
            next({ name: 'Login' })
        } else {
            next()
        }
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
