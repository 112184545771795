<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card :loading="loading">
                    <v-card-title>Suspend membership</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="!loading">
                        <v-row>
                            <v-col>
                                <v-subheader v-text="'Dates'"></v-subheader>
                            </v-col>
                            <v-col>
                                <vuetify-date-picker
                                    :initialDate="suspend_date"
                                    @changed="updateDate"
                                    label="Suspend date"
                                    :min-date="minDate"
                                    :max-date="maxDate"
                                    :error-messages="suspendDateErrors"
                                ></vuetify-date-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3 px-2">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="suspend()"
                            :loading="sending"
                            :disabled="sending"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import { required } from 'vuelidate/lib/validators'
import date from '../../validators/Date'

export default {
    name: 'MembershipSuspendForm',
    mixins: [WithLoading, ErrorMixin],
    components: {
        VuetifyDatePicker,
    },
    data() {
        return {
            suspend_date: null,
            membership: null,
            minDate: null,
            maxDate: null,
            sending: false,
            loading: true,
        }
    },
    validations: {
        suspend_date: { required, date },
    },
    computed: {
        suspendDateErrors() {
            const errors = []
            if (!this.$v.suspend_date.$dirty) return errors
            !this.$v.suspend_date.required &&
                errors.push('Suspend date is required.')
            !this.$v.suspend_date.date &&
                errors.push('Suspend date format is invalid')
            return errors
        },
    },
    methods: {
        updateDate(date) {
            this.suspend_date = date
        },
        suspend() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return false
            }

            this.sending = true

            this.$http
                .post(
                    `v1/tenant/${this.$store.state.tenant}/memberships/${this.membership.id}/suspend`,
                    { suspend_date: this.suspend_date }
                )
                .then(() => {
                    this.sending = false
                    this.$store.dispatch(
                        'showSuccessMessage',
                        'Membership was suspended.'
                    )
                    this.$router.push(`/users/${this.membership.user_id}`)
                })
                .catch((err) => {
                    this.sending = false
                    this.handleResponseErrors(err)
                })
        },
        fetchData() {
            this.loading = true
            return Promise.all([
                this.$http.get(
                    `v1/tenant/${this.$store.state.tenant}/memberships/${this.$route.params.membershipId}`
                ),
            ])
                .then((response) => {
                    this.membership = response[0].data
                    this.minDate = this.membership.dateStart
                    this.maxDate = this.membership.dateEnd
                    this.loading = false
                })
                .catch(this.handleResponseErrors)
        },
    },
}
</script>
