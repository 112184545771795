var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Training Presence "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'TrainingsModifyPresence',
              params: { trainingId: _vm.training.id },
            })}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"purple--text"},[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("Add free user")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'SubstituteTraining',
              params: { trainingId: _vm.training.id },
            })}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"blue--text"},[_vm._v("published_with_changes")])],1)]}}])},[_c('span',[_vm._v("Substitute training")])])],1),_c('v-divider'),(_vm.items.length == 0)?_c('p',{staticClass:"text-center pt-3"},[_vm._v("No users yet.")]):_vm._e(),(_vm.items.length > 0)?_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.limitedItems),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.open(item)}}},[_c('v-list-item-avatar',{staticClass:"mt-6"},[_c('v-icon',{class:_vm.trainingCssClass(item)},[_vm._v(_vm._s(_vm.trainingIcon(item)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))])]),_c('v-list-item-subtitle',[_c('span',[_vm._v(_vm._s(item.email))])]),_c('v-list-item-subtitle',[_c('span',{class:_vm.membershipStatusCss(item)},[_vm._v(_vm._s(_vm.membershipStatusText(item)))])])],1),(item.pivot.type === -1)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.undoExcusion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"orange--text"},[_vm._v("undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo excusion")])])],1):_vm._e(),(item.pivot.type === 2)?_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.undoSubstitute(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"blue--text"},[_vm._v("undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo substitution")])])],1):_vm._e(),(item.pivot.type === 10)?_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.undoFreeTraining(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"purple--text"},[_vm._v("undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo free training")])])],1):_vm._e(),(false)?_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text text--lighten-1"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove from training")])])],1):_vm._e(),((item.pivot.type === -5 || item.pivot.type === 0) && _vm.hasActiveMembership(item))?_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.excuse(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"orange--text"},[_vm._v("person_remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Excuse training")])])],1):_vm._e(),(item.pivot.type != 0 && item.pivot.type != -2 && item.pivot.type != -1)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.markMissing(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"red--text"},[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark as missing")])])],1):_vm._e(),(item.pivot.type != 1 && item.pivot.type != -2 && item.pivot.type != -1)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.markPresent(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark as present")])])],1):_vm._e()],1)}),1):_vm._e(),(_vm.remaining > 0)?_c('div',{staticClass:"list-more"},[_c('button',{staticClass:"btn btn--raised btn--light primary",on:{"click":function($event){return _vm.showMore()}}},[_vm._v("Show more ("+_vm._s(_vm.remaining)+" hidden)")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }