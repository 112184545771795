export default {
  data() {
    return {
      loading: true,
      tenant: null,
      headers: null,
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(`${path}`)
    },
    loadingStart() {
      this.tenant = this.$store.state.tenant
      this.loading = true
      this.headers = 'Bearer ' + this.$store.token
    },
    loadingCompleted() {
      this.loading = false
    },
    loadingFailed(response) {
      if (response.response.status === 401) {
        this.$router.push('/logout')
      } else {
        this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.response.status}`)
        this.$router.go(-1)
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  watch: {
    $route: 'fetchData',
  },
}
