<template>
    <LineChart v-bind="barChartProps" />
</template>

<script>
import { LineChart, useBarChart } from 'vue-chart-3'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'ZLineChart',
    components: {
        LineChart,
    },
    props: {
        datasets: {
            type: Array,
            default() {
                return []
            },
        },
        labels: {
            type: Array,
            default() {
                return []
            },
        },
    },
    setup(props) {
        const options = computed(() => ({
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        }))

        const chartData = computed(() => ({
            labels: props.labels,
            datasets: props.datasets,
        }))

        const { barChartProps, barChartRef } = useBarChart({
            chartData,
            options,
        })

        return { barChartProps, barChartRef }
    },
})
</script>
