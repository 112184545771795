<template>
    <v-row>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Groups</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="!loading">
                    <div class="display-2 mt-10 mb-10">
                        {{ groups_count }}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Gym capacity (monthly)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[monthlyGymCapacitySet, monthlyInGroupsSet]"
                        :labels="monthlyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Gym capacity (90 days)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[dailyGymCapacitySet, dailyInGroupsSet]"
                        :labels="dailyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ZLineChart from '../../../components/charts/ZLineChart.vue'
import StatsService from '../../../services/StatsService'

export default {
    components: { ZLineChart },
    props: {
        groups_count: {
            default: 0,
        },
    },
    data() {
        return {
            loading: true,
            monthlyGymCapacitySet: null,
            monthlyInGroupsSet: null,
            monthlyLabels: [],
            dailyGymCapacitySet: null,
            dailyInGroupsSet: null,
            dailyLabels: [],
        }
    },
    mounted() {
        StatsService.load(this, this.drawCharts)
    },
    methods: {
        drawCharts(response) {
            this.loading = true

            let rawMonthlyStats = response[0].data
            let rawDailyStats = response[1].data

            this.monthlyGymCapacitySet = {
                label: 'Gym capacity',
                data: [],
                backgroundColor: '#FF5252',
                borderColor: '#FF5252',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            this.monthlyInGroupsSet = {
                label: 'Average users in groups',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawMonthlyStats.map((stat) => {
                this.monthlyLabels.push(stat.f_date)
                this.monthlyGymCapacitySet.data.push(stat.avg_gym_capacity)
                this.monthlyInGroupsSet.data.push(stat.avg_users_in_groups)
            })

            this.dailyGymCapacitySet = {
                label: 'Gym capacity',
                data: [],
                backgroundColor: '#FF5252',
                borderColor: '#FF5252',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            this.dailyInGroupsSet = {
                label: 'Users in groups',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawDailyStats.map((stat) => {
                this.dailyLabels.push(stat.f_date)
                this.dailyGymCapacitySet.data.push(stat.gym_capacity)
                this.dailyInGroupsSet.data.push(stat.users_in_groups)
            })

            this.loading = false
        },
    },
}
</script>
