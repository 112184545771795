<template>
  <v-app>
    <v-navigation-drawer app fixed clipped v-model="drawer" overflow enable-resize-watcher>
      <v-list dense>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-list-item-content>
            <v-list-item-title class="grey--text text--darken-4">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader> <v-icon class="pr-3">person</v-icon>Users </v-subheader>
        <v-list-item :to="{ name: 'Users' }">
          <v-list-item-content>
            <v-list-item-title class="grey--text text--darken-4">Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'UsersRegistrations' }">
          <v-list-item-content>
            <v-list-item-title>Registrations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'ActiveUsers' }">
          <v-list-item-content>
            <v-list-item-title>Active Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'LostUsers' }">
          <v-list-item-content>
            <v-list-item-title>Lost Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader> <v-icon class="pr-3">assignment_ind</v-icon>Memberships</v-subheader>
        <v-list-item :to="{ name: 'Memberships' }">
          <v-list-item-content>
            <v-list-item-title>Memberships</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'MembershipTypes' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Membership Types</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader> <v-icon class="pr-3">supervisor_account</v-icon>Groups &amp; Trainings </v-subheader>
        <v-list-item :to="{ name: 'Groups' }">
          <v-list-item-content>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Trainings' }">
          <v-list-item-content>
            <v-list-item-title>Trainings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader> <v-icon class="pr-3">credit_card</v-icon>Finance </v-subheader>
        <v-list-item :to="{ name: 'Payments' }">
          <v-list-item-content>
            <v-list-item-title>Payments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'FinanceSalary' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Salaries</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'FinanceCashflow' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader> <v-icon class="pr-3">email</v-icon>Communication </v-subheader>
        <v-list-item :to="{ name: 'Emails' }">
          <v-list-item-content>
            <v-list-item-title>Emails</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader v-if="hasRole(['administrator', 'manager'])">
          <v-icon class="pr-3">settings</v-icon>Settings
        </v-subheader>
        <v-list-item
          :to="{
            name: 'GymsView',
            params: { gymId: $store.state.tenant },
          }"
          v-if="hasRole(['administrator', 'manager'])"
        >
          <v-list-item-content>
            <v-list-item-title>General</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'EmailTemplates' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Email Templates</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Triggers' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Triggers &amp; Actions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Permissions' }" v-if="hasRole(['administrator'])">
          <v-list-item-content>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Texts' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Texts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'UserStates' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>User States</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Salutations' }" v-if="hasRole(['administrator', 'manager'])">
          <v-list-item-content>
            <v-list-item-title>Salutations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed dark app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>{{ $store.getters.getTenantName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip, attrs }">
              <v-btn dark v-bind="attrs" v-on="{ ...tooltip, ...menu }" icon>
                <v-icon>sync_alt</v-icon>
              </v-btn>
            </template>
            <span>Switch gym</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="tenant in $store.state.permissions" :key="tenant.id" @click="changeTenant(tenant.id)">
            <v-list-item-title>{{ tenant.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon ripple :to="{ name: 'Logout' }" v-bind="attrs" v-on="on">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <router-view :gym="gym">
        <template v-slot:breadcrumbs>
          <v-breadcrumbs v-if="$store.state.breadcrumbs" :items="$store.state.breadcrumbs" divider="-"></v-breadcrumbs>
        </template>
      </router-view>
    </v-main>
    <the-footer />
    <the-snackbar />
  </v-app>
</template>

<script>
import TheFooter from './TheFooter.vue'
import TheSnackbar from './TheSnackbar.vue'

export default {
  components: { TheFooter, TheSnackbar },
  data() {
    return {
      gym: null,
      role: null,
      drawer: true,
      tenant: this.$store.state.tenant,
    }
  },
  mounted() {
    document.title = this.$store.getters.getTenantName
  },
  methods: {
    changeTenant(tenant) {
      this.$store.dispatch('setTenant', tenant)
      if (this.$route.name == 'Dashboard') {
        this.$router.go()
      } else {
        this.$router.push({ name: 'Dashboard' })
      }
    },
    hasRole(roles) {
      if (this.$store.getters.role === '') {
        return false
      }
      if (roles.indexOf(this.$store.getters.role) >= 0) {
        return true
      }
      return false
    },
  },
}
</script>
