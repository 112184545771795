<template>
    <v-dialog v-model="show" persistent max-width="175">
        <v-card>
            <v-card-title class="text-center">Are you sure?</v-card-title>
            <v-card-actions>
                <v-btn
                    text
                    color="green"
                    :loading="loading"
                    :disabled="loading"
                    @click="confirm"
                >
                    Yes
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    :disabled="loading"
                    @click="$emit('decline')"
                >
                    No
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['show'],
    data() {
        return {
            loading: false,
        }
    },
    watch: {
        show: 'resetLoading',
    },
    methods: {
        resetLoading() {
            this.loading = false
        },
        confirm() {
            this.loading = true
            this.$emit('confirm')
        },
    },
}
</script>
