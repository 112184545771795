<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col>
                <v-card class="mb-4">
                    <v-card-title>
                        <span v-if="isCreate">Create new membership type</span>
                        <span v-if="!isCreate">{{ membershipType.name }}</span>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-subheader v-text="'General'"></v-subheader>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    name="name"
                                    label="Name"
                                    id="name"
                                    v-model="membershipType.name"
                                    @input="$v.membershipType.name.$touch()"
                                    :error-messages="nameErrors"
                                ></v-text-field>
                                <v-text-field
                                    name="price"
                                    label="Price"
                                    id="price"
                                    suffix="CZK"
                                    v-model="membershipType.price"
                                    @input="$v.membershipType.price.$touch()"
                                    :error-messages="priceErrors"
                                ></v-text-field>
                                <v-text-field
                                    name="duration"
                                    label="Duration"
                                    id="duration"
                                    suffix="days"
                                    hint="How long is the membership."
                                    v-model="membershipType.duration"
                                    @input="$v.membershipType.duration.$touch()"
                                    :error-messages="durationErrors"
                                ></v-text-field>
                                <v-text-field
                                    name="slots_protection_duration"
                                    label="Slots protection duration"
                                    id="slots_protection_duration"
                                    hint="How many days after the membership ends will substitution slots be reseted to zero."
                                    suffix="days"
                                    v-model="
                                        membershipType.slots_protection_duration
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-subheader
                                    v-text="'Membership extensions'"
                                ></v-subheader>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    name="extendDuration"
                                    label="Extend duration"
                                    id="extendDuration"
                                    suffix="days"
                                    v-model="membershipType.extendDuration"
                                ></v-text-field>
                                <v-text-field
                                    name="trainings_per_week"
                                    label="Trainings per Week"
                                    id="trainings_per_week"
                                    v-model="membershipType.trainings_per_week"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-btn
                            light
                            @click.prevent="showAreYouSure = true"
                            v-if="membershipType.id"
                            >Delete</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click.prevent="save()"
                            :loading="sending"
                            :disabled="sending"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <are-you-sure
            :show="showAreYouSure"
            @decline="showAreYouSure = false"
            @confirm="doDelete()"
        ></are-you-sure>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'
import { required, integer } from 'vuelidate/lib/validators'

export default {
    mixins: [WithLoading, ErrorMixin],
    components: { AreYouSure },
    data() {
        return {
            showAreYouSure: false,
            membershipType: {},
            isCreate: false,
            sending: false,
        }
    },
    validations: {
        membershipType: {
            name: { required },
            price: { required, integer },
            duration: { required, integer },
        },
    },
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.membershipType.name.$dirty) return errors
            !this.$v.membershipType.name.required &&
                errors.push('Name is required.')
            return errors
        },
        priceErrors() {
            const errors = []
            if (!this.$v.membershipType.price.$dirty) return errors
            !this.$v.membershipType.price.required &&
                errors.push('Price is required.')
            !this.$v.membershipType.price.integer &&
                errors.push('Price must be a number.')
            return errors
        },
        durationErrors() {
            const errors = []
            if (!this.$v.membershipType.duration.$dirty) return errors
            !this.$v.membershipType.duration.required &&
                errors.push('Duration is required.')
            !this.$v.membershipType.duration.integer &&
                errors.push('Duration must be a number.')
            return errors
        },
    },
    methods: {
        doDelete() {
            this.$http
                .delete(
                    'v1/tenant/' +
                        this.tenant +
                        '/membershipTypes/' +
                        this.membershipType.id
                )
                .then(() => {
                    this.$store.dispatch(
                        'showSuccessMessage',
                        'Membership type has been deleted.'
                    )
                    this.$router.push({ name: 'MembershipTypes' })
                })
                .catch((err) => {
                    this.handleResponseErrors(err)
                    this.showAreYouSure = false
                })
        },
        save() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return false
            }

            this.sending = true

            if (this.isCreate) {
                this.$http
                    .post(
                        'v1/tenant/' + this.tenant + '/membershipTypes',
                        this.membershipType
                    )
                    .then(() => {
                        this.$router.go(-1)
                    })
                    .catch((err) => {
                        this.handleResponseErrors(err)
                        this.sending = false
                    })
            } else {
                this.$http
                    .put(
                        'v1/tenant/' +
                            this.tenant +
                            '/membershipTypes/' +
                            this.membershipType.id,
                        this.membershipType
                    )
                    .then(() => {
                        this.$router.go(-1)
                    })
                    .catch((err) => {
                        this.handleResponseErrors(err)
                        this.sending = false
                    })
            }
        },
        fetchData() {
            this.loadingStart()
            if (this.$route.params.membershipTypeId) {
                return Promise.all([
                    this.$http.get(
                        'v1/tenant/' +
                            this.tenant +
                            '/membershipTypes/' +
                            this.$route.params.membershipTypeId
                    ),
                ])
                    .then((response) => {
                        this.membershipType = response[0].data
                        this.loadingCompleted()
                    })
                    .catch((response) => {
                        this.loadingFailed(response)
                    })
            }
            this.isCreate = true
            this.loadingCompleted()
        },
    },
}
</script>
