<template>
  <v-container fluid>
    <v-row v-if="!hasMembership && !pageLoading">
      <v-col class="text-center mt-4">
        <h2>Nemáš žádné aktivní členství</h2>
        <p>Chybí nám u tebe aktivní členství, takže si nemůžeš omlouvat a nahrazovat tréninky.</p>
      </v-col>
    </v-row>
    <v-row v-if="hasSuspendedMembership && !pageLoading">
      <v-col class="text-center mt-4">
        <h2>Tvoje členství je aktuálně pozastavené</h2>
        <p>Požádej na recepci nebo telefonicky o jeho rozběhnutí, abys měl znovu přítup k nahrazování tréninků.</p>
      </v-col>
    </v-row>
    <v-row v-if="!hasSuspendedMembership && hasMembership">
      <v-col cols="12" xs="12" md="4">
        <v-card :loading="pageLoading">
          <v-card-title>Tréninky k nahrazení</v-card-title>
          <v-divider />
          <v-card-text class="text-center" v-if="!pageLoading && expiringAndActiveMemberships.length > 0"
            ><span class="text-h6" v-for="(membership, index) in expiringAndActiveMemberships" :key="membership.id">
              <span v-if="index !== 0"> / </span>
              <span :class="[{ 'red--text': index !== 0 }]">{{ membership.free_slots }}</span>
            </span>
          </v-card-text>
          <v-card-text class="text-center" v-if="!pageLoading && expiringAndActiveMemberships.length <= 0"
            ><span class="text-h6">0</span></v-card-text
          >
          <v-divider v-if="!pageLoading" />
          <v-card-text class="text-center" v-if="!pageLoading && expiringAndActiveMemberships.length > 0">
            <span class="caption">Náhrady ti propadnou:</span>
            <span class="caption" v-for="(membership, index) in expiringAndActiveMemberships" :key="membership.id">
              <span v-if="index !== 0"> / </span>
              <span :class="[{ 'red--text': index !== 0 }]">
                {{ membership.slotsExpirationDate | formatDate }}</span
              ></span
            >
          </v-card-text>
          <v-card-text class="text-center" v-if="!pageLoading && expiringAndActiveMemberships.length <= 0">
            <span class="caption">Žádné aktivní nebo expirující členství</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card :loading="pageLoading">
          <v-card-title>Aktivní členství</v-card-title>
          <v-divider v-if="!pageLoading" />
          <v-card-text class="text-center" v-if="!pageLoading"
            ><span class="text-h6" v-if="activeMemberhip !== null">{{ activeMemberhip.humanDescription }}</span>
            <span v-else class="text-h6">Žádné aktivní členství</span>
          </v-card-text>
          <v-divider />
          <v-card-text class="text-center" v-if="!pageLoading"> </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card :loading="pageLoading">
          <v-card-title>Budoucí členství</v-card-title>
          <v-divider />
          <v-card-text v-if="!pageLoading" class="text-center">
            <div class="text-center" v-if="futureMembership !== null">
              <span class="text-h6">{{ futureMembership.humanDescription }}</span>
            </div>
            <div class="text-h6" v-else>Žádné budoucí členství</div></v-card-text
          >
          <v-divider v-if="!pageLoading" />
          <v-card-text class="text-center" v-if="!pageLoading"
            ><span class="text-caption" v-if="futureMembership !== null">
              Budoucí náhrady:
              {{ futureMembership.free_slots }}
            </span>
            <span v-else class="caption">Kup si nové členství v gymu!</span></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!hasSuspendedMembership && hasMembership">
      <v-col cols="12" xs="12" md="4">
        <v-card height="100%" :loading="pageLoading || excuseSending">
          <v-card-title>Moje naplánované tréninky</v-card-title>
          <v-divider />
          <v-card-text v-if="!pageLoading">
            <v-list v-if="plannedTrainings.length > 0">
              <v-list-item v-for="(training, index) in plannedTrainingsPaginated" :key="training.id">
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'blue--text': training.pivot.type === 2,
                      'orange--text': training.pivot.type === -1,
                    }"
                    v-html="formatTrainingHeader(training)"
                  >
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="{
                      'blue--text': training.pivot.type === 2,
                      'orange--text': training.pivot.type === -1,
                    }"
                    >{{ training.start | formatTime }} - {{ training.end | formatTime }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom v-if="training.pivot.type === -5 && showConfirmationIndex != index">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon ripple :disabled="true" color="grey" v-bind="attrs" v-on="on">
                        <v-icon>person_remove</v-icon>
                      </v-btn>
                    </template>
                    <span>Omluvit trénink</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="training.pivot.type === 2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon ripple color="grey" :disabled="true" v-bind="attrs" v-on="on">
                        <v-icon>undo</v-icon>
                      </v-btn>
                    </template>
                    <span>Zrušit náhradu</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-pagination v-model="plannedPage" :length="plannedPages" v-if="plannedPages > 1"></v-pagination>
            <div class="text-center" v-if="plannedTrainings.length <= 0">Žádné tréninky k omluvení</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="8">
        <v-card :loading="pageLoading || substituteSending || substituableLoading">
          <v-card-title>Výběr náhrad</v-card-title>
          <v-divider />
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" xs="12" md="7">
                  <div class="text-center">
                    <v-date-picker
                      v-model="calendarDate"
                      @click:date="fetchSubstituableTrainings"
                      color="#FFCD00"
                      elevation="1"
                      :min="minimalDate"
                      :max="maximalDate"
                      locale="cs-CZ"
                      :first-day-of-week="1"
                      :events="functionEvents"
                    ></v-date-picker>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" md="5">
                  <v-list v-if="substituableTrainings.length > 0">
                    <v-list-item v-for="training in substituableTrainings" :key="training.id">
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ training.start | formatTime }}
                          -
                          {{ training.end | formatTime }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ training.user_name }}
                          (volná místa:
                          {{ freeSlots(training) }})</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip bottom v-if="slotsAvailable">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon ripple color="grey" :disabled="true" v-bind="attrs" v-on="on">
                              <v-icon>person_add</v-icon>
                            </v-btn>
                          </template>
                          <span>Nahradit trénink</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="!slotsAvailable">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon ripple color="grey" v-bind="attrs" v-on="on">
                              <v-icon>person_add</v-icon>
                            </v-btn>
                          </template>
                          <span>Nemáš volné sloty k náhradě</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <span v-else>Žádné dostupné tréninky</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ErrorMixin from '../../mixins/ErrorMixin'
import moment from 'moment'

export default {
  name: 'NewSubstitutions',
  mixins: [ErrorMixin],
  data() {
    return {
      calendarDate: moment().format('YYYY-MM-DD'),
      plannedTrainings: [],
      excusedTrainings: [],
      substituableTrainings: [],
      substitutedTrainings: [],
      memberships: [],
      pageLoading: true,
      substituableLoading: false,
      excuseSending: false,
      substituteSending: false,
      unSubstituteSending: false,
      plannedPage: 1,
      showConfirmationIndex: -1,
    }
  },
  computed: {
    plannedPages() {
      return Math.ceil(this.plannedTrainings.length / 10)
    },
    plannedTrainingsPaginated() {
      return this.plannedTrainings.slice((this.plannedPage - 1) * 10, this.plannedPage * 10)
    },
    hasMembership() {
      if (this.expiringMembership || this.activeMemberhip || this.futureMembership) {
        return true
      }
      return false
    },
    slotsAvailable() {
      if (this.expiringMembership !== null && this.expiringMembership.free_slots > 0) {
        return true
      }
      if (this.activeMemberhip !== null && this.activeMemberhip.free_slots > 0) {
        return true
      }
      return false
    },
    minimalDate() {
      return moment().format('YYYY-MM-DD')
    },
    maximalDate() {
      if (this.futureMembership !== null) {
        return moment(this.futureMembership.slotsExpirationDate).format('YYYY-MM-DD')
      }
      if (this.activeMemberhip !== null) {
        return moment(this.activeMemberhip.slotsExpirationDate).format('YYYY-MM-DD')
      }
      if (this.expiringMembership !== null) {
        return moment(this.expiringMembership.slotsExpirationDate).format('YYYY-MM-DD')
      }
      return moment().add(14, 'days').format('YYYY-MM-DD')
    },
    hasSuspendedMembership() {
      const matchedMembership = this.memberships.find((membership) => {
        return membership.status === 'Suspended'
      })

      return matchedMembership ? matchedMembership : null
    },
    activeMemberhip() {
      const matchedMembership = this.memberships.find((membership) => {
        return membership.status === 'Active'
      })

      return matchedMembership ? matchedMembership : null
    },
    expiringMembership() {
      const matchedMembership = this.memberships.find((membership) => {
        return membership.status === 'Expiring'
      })

      return matchedMembership ? matchedMembership : null
    },
    futureMembership() {
      const matchedMembership = this.memberships.find((membership) => {
        return membership.status === 'Future'
      })

      return matchedMembership ? matchedMembership : null
    },
    activeMemberships() {
      return this.memberships.filter((membership) => {
        return membership.status === 'Active'
      })
    },
    futureMemberships() {
      return this.memberships.filter((memberhsip) => {
        return memberhsip.status === 'Future'
      })
    },
    expiringAndActiveMemberships() {
      return this.memberships.filter((membership) => {
        return membership.status === 'Expiring' || membership.status === 'Active'
      })
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    freeSlots(training) {
      const present = training.memberCounts[1] !== undefined ? training.memberCounts[1] : 0
      const neutral = training.memberCounts[-5] !== undefined ? training.memberCounts[-5] : 0
      const first = training.memberCounts[10] !== undefined ? training.memberCounts[10] : 0
      const substituted = training.memberCounts[2] !== undefined ? training.memberCounts[2] : 0

      let freeSlots = training.capacity - neutral - present - first
      if (freeSlots < 0) {
        freeSlots = 0
      }

      freeSlots = freeSlots + training.substitution_slots - substituted

      if (freeSlots < 0) {
        freeSlots = 0
      }

      return freeSlots
    },
    formatTrainingHeader(training) {
      var date = moment(training.start).format('D. M.')
      var formatted = ''
      switch (moment(training.start).format('d')) {
        case '0':
          formatted = `ne ${date}`
          break
        case '1':
          formatted = `po ${date}`
          break
        case '2':
          formatted = `út ${date}`
          break
        case '3':
          formatted = `st ${date}`
          break
        case '4':
          formatted = `čt ${date}`
          break
        case '5':
          formatted = `pá ${date}`
          break
        case '6':
          formatted = `so ${date}`
          break
      }
      return formatted
    },
    functionEvents(date) {
      let training = null
      training = this.plannedTrainings.find((item) => {
        return moment(item.start).format('YYYY-MM-DD') == date
      })
      if (typeof training !== 'undefined') {
        switch (training.pivot.type) {
          case -1:
            return 'orange'
          case -5:
            return 'grey'
          case 2:
            return 'blue'
        }
      }
      return false
    },
    async excuseTraining(training) {
      this.excuseSending = true
      try {
        await this.$http.post(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/${training.id}/excuse`
        )
        this.fetchData()
        this.$store.dispatch('showSuccessMessage', 'Trénink byl omluven.')
        this.excuseSending = false
        this.showConfirmationIndex = -1
      } catch (err) {
        this.errorHandler(err)
        this.excuseSending = false
        this.showConfirmationIndex = -1
      }
    },
    async substituteTraining(training) {
      this.substituteSending = true
      try {
        await this.$http.post(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/${training.id}/substitute`
        )
        this.fetchData()
        this.$store.dispatch('showSuccessMessage', 'Náhradní trénink byl vytvořen.')
        this.substituteSending = false
      } catch (err) {
        this.errorHandler(err)
        this.substituteSending = false
      }
    },
    async unsubstituteTraining(training) {
      this.unSubstituteSending = true
      try {
        await this.$http.delete(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/${training.id}/substitute`
        )
        this.fetchData()
        this.$store.dispatch('showSuccessMessage', 'Náhradní trénink byl zrušen.')
        this.unSubstituteSending = false
      } catch (err) {
        this.errorHandler(err)
        this.unSubstituteSending = false
      }
    },
    async fetchSubstituableTrainings() {
      this.substituableLoading = true
      this.substituableTrainings = []
      try {
        this.substituableTrainings = (
          await this.$http.get(
            `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/substituable?date=${this.calendarDate}`
          )
        ).data
        this.substituableLoading = false
      } catch (err) {
        this.errorHandler(err)
        this.substituableLoading = false
      }

      // Get active membership
      const matchedMembership = this.memberships.find((membership) => {
        return membership.status === 'Active'
      })

      // Parse gym from membership type
      const regex = /.+\[(.+)\]$/
      let gym = ''
      const match = regex.exec(matchedMembership.type.name)
      if (match) {
        gym = match[1]
      }

      this.substituableTrainings = this.substituableTrainings.filter((item) => {
        // Bypass for gyms other than Amazing
        if (item.gym_id !== 4) {
          return true
        }

        // Bypass if gym was not identified from membership type
        if (gym === '') {
          return true
        }
        return item.group_name.includes(gym)
      })
    },
    async fetchPlannedTrainings() {
      let plannedTrainings = (
        await this.$http.get(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/planned`
        )
      ).data

      this.plannedTrainings = plannedTrainings.filter((item) => {
        if (item.pivot.type === -10 || item.pivot.type === 1 || item.pivot.type === 0) {
          return false
        }
        if (this.activeMemberhip !== null) {
          if (item.pivot.type === -5 && moment(item.start) >= moment(this.activeMemberhip.dateEnd).endOf('day')) {
            if (this.futureMembership !== null) {
              return true
            }
            return false
          }
        } else {
          if (item.pivot.type === -5 && moment(item.start) >= moment().endOf('day')) {
            if (this.futureMembership !== null) {
              return true
            }
            return false
          }
        }
        return true
      })
    },
    async fetchData() {
      this.pageLoading = true
      try {
        this.memberships = (
          await this.$http.get(`v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/memberships`)
        ).data
        this.excusedTrainings = (
          await this.$http.get(
            `v1/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/trainings/excused`
          )
        ).data

        this.fetchPlannedTrainings()
        this.fetchSubstituableTrainings()
      } catch (err) {
        this.errorHandler(err)
      }
      this.pageLoading = false
    },
  },
}
</script>
