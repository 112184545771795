var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),(!_vm.loading)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"xs12":"","lg8":"","offset-lg2":""}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v("Edit text "+_vm._s(_vm.$route.params.slug))]),_c('v-divider'),_c('v-card-text',[_c('editor',{attrs:{"initialValue":_vm.text,"api-key":"e8c9yad0xu5cscudzuquigaztxkjjplb4z8zygifm61o0d7c","init":{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap',
                                'searchreplace visualblocks code fullscreen',
                                'print preview anchor insertdatetime media',
                                'paste code help wordcount table' ],
                            toolbar:
                                'undo redo | formatselect | bold italic | \
    alignleft aligncenter alignright | \
    bullist numlist outdent indent | help',
                        }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3 px-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }