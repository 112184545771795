<template>
    <v-list-item
        :to="{ name: 'UsersView', params: { tenant, userId: item.id } }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">assignment_ind</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong
                    >{{ item.user.lastname }} {{ item.user.firstname }}</strong
                >
            </v-list-item-title>
            <v-list-item-subtitle>
                <span>Correct end: {{ item.correctDateEnd | formatDate }}</span
                ><br />
                <span>Extended end: {{ item.dateEnd | formatDate }}</span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
            <v-list-item-title class="text-center"
                ><strong>{{ item.trainingCount }}</strong></v-list-item-title
            >
            <v-list-item-subtitle class="text-center"
                >Trainings</v-list-item-subtitle
            >
        </v-list-item-content>
        <v-list-item-content>
            <v-list-item-title class="text-center"
                ><strong
                    >{{ item.trainingCount * 150 }} CZK</strong
                ></v-list-item-title
            >
            <v-list-item-subtitle class="text-center"
                >Lost Money</v-list-item-subtitle
            >
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'extended-membership-list-item',
    props: ['item', 'tenant'],
}
</script>
