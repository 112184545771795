var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{attrs:{"to":{ name: 'UsersView', params: { tenant: _vm.tenant, userId: _vm.item.id } }}},[_c('v-list-item-avatar',[_c('v-icon',{class:_vm.classes},[_vm._v("person")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(_vm.item.firstname)+" "+_vm._s(_vm.item.lastname))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.item.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.item.stateName)+" - "+_vm._s(_vm._f("formatDate")(_vm.item.stateUpdated))+" ")])],1),_c('v-list-item-action',[_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                        name: 'FirstTraining',
                        params: { userId: _vm.item.id },
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"purple--text"},[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("Assign first training")])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":"","to":{
                        name: 'UsersEdit',
                        params: { tenant: _vm.tenant, userId: _vm.item.id },
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text text--lighten-1"},[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Modify")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }