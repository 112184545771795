<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>
                        <span>{{ salutation.name }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            name="salutation"
                            label="Salutation"
                            id="salutation"
                            v-model="salutation.salutation"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="save()"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
    mixins: [WithLoading],
    data() {
        return {
            salutation: {},
        }
    },
    methods: {
        save() {
            this.$http
                .put(
                    'v1/tenant/' +
                        this.tenant +
                        '/salutations/' +
                        this.salutation.id,
                    this.salutation
                )
                .then(() => {
                    this.$router.go(-1)
                })
                .catch((response) => {
                    this.$store.dispatch(
                        'showWarningMessage',
                        `Something went wrong. Error: ${response.status}`
                    )
                })
        },
        fetchData() {
            this.loadingStart()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/salutations/' +
                        this.$route.params.salutationId
                )
                .then((response) => {
                    this.salutation = response.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
