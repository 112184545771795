<template>
  <div>
    <v-container fluid v-if="loading">
      <v-progress-linear v-bind:indeterminate="true"></v-progress-linear>
    </v-container>
    <v-tabs v-if="!loading">
      <v-tab key="general">
        <span class="black--text">General</span>
      </v-tab>
      <v-tab key="instructor" v-if="user.role === 'instructor' || user.role === 'administrator'">
        <span class="black--text">Instructor</span>
      </v-tab>
      <v-tab-item key="general">
        <v-container fluid>
          <v-row wrap>
            <v-col xs12 md6>
              <v-card class="mb-4">
                <v-card-title>
                  {{ user.firstname }} {{ user.lastname }}
                  <v-spacer></v-spacer>
                  <v-tooltip bottom class="mx-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="navigateTo('/users/' + user.user_id + '/edit')" v-bind="attrs" v-on="on">
                        <v-icon class="primary--text">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Modify</span>
                  </v-tooltip>
                </v-card-title>
                <v-divider></v-divider>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.email }}</v-list-item-title>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip bottom class="mx-0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" v-clipboard:copy="user.email">
                            <v-icon class="grey--text">content_copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy email to clipboard</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="user.phone">
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'tel:' + user.phone">{{ user.phone }}</a>
                      </v-list-item-title>
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip bottom class="mx-0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" v-clipboard:copy="user.phone">
                            <v-icon class="grey--text">content_copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy phone to clipboard</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-if="showChangeState"
                        :items="userStates"
                        v-model="userState"
                        label="Select user state"
                        item-text="name"
                        item-value="id"
                        single-line
                        bottom
                      ></v-select>
                      <v-list-item-title v-if="!showChangeState">{{ user.state.name }}</v-list-item-title>
                      <v-list-item-subtitle v-if="!showChangeState">State</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="showChangeState">
                      <v-btn icon @click.stop="showChangeState = false">
                        <v-icon class="grey--text">clear</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="showChangeState" class="mr-2">
                      <v-btn icon @click.stop="changeState">
                        <v-icon class="green--text">check_circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="!showChangeState">
                      <v-tooltip bottom class="mx-0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click.stop="showChangeState = true" v-bind="attrs" v-on="on">
                            <v-icon class="grey--text">edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Modify user state</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="user.birthdate">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.birthdate }}</v-list-item-title>
                      <v-list-item-subtitle>Datum narození</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.registration_source">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.registration_source.name }}</v-list-item-title>
                      <v-list-item-subtitle>Registration Source</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.registration_source_other">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.registration_source_other }}</v-list-item-title>
                      <v-list-item-subtitle>Other Registration Source</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.referral_user">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        user.referral_user.firstname + ' ' + user.referral_user.lastname
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Referral User</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.leaving_reason_email_sent">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.leaving_reason_email_sent | formatDate }}</v-list-item-title>
                      <v-list-item-subtitle>Leaving Email Sent</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.leaving_reason_feedback_received">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.leaving_reason_feedback_received | formatDate }}</v-list-item-title>
                      <v-list-item-subtitle>Leaving Feedback Collected</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.leaving_reason">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.leaving_reason.name }}</v-list-item-title>
                      <v-list-item-subtitle>Leaving Reason</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.leaving_reason_note">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.leaving_reason_note }}</v-list-item-title>
                      <v-list-item-subtitle>Other Leaving Reason</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-card class="mb-4" v-if="user.description">
                <v-card-title>Note</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <div style="white-space: pre-wrap; word-wrap: break-word" v-html="user.description"></div>
                </v-card-text>
              </v-card>

              <membership-list />

              <training-groups-list :tenantId="tenant" :userId="user.user_id"></training-groups-list>

              <trainings-list :tenantId="tenant" :userId="user.user_id"></trainings-list>

              <waiting-list-items />

              <v-card class="mb-4">
                <v-card-title>
                  Registrace a objednávka
                  <v-spacer />
                  <v-tooltip bottom class="mx-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="extendLinkExpiration"
                      >
                        <v-icon class="grey--text">refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Prodloužit expiraci objednávkového odkazu</span>
                  </v-tooltip>
                </v-card-title>
                <v-divider></v-divider>
                <v-list two-line>
                  <v-list-item v-if="user.registration_token">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.registration_token_expiration_date | formatDate }}</v-list-item-title>
                      <v-list-item-subtitle>Expirace objednávkového odkazu </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip bottom class="mx-0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            v-clipboard:copy="
                              user.gym.public_url + '/registration/' + user.registration_token + '/order'
                            "
                          >
                            <v-icon class="grey--text">content_copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Kopírovat odkaz na objednávkový formulář</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="user.ordered_date">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.ordered_date | formatDate }}</v-list-item-title>
                      <v-list-item-subtitle>Datum objednávky</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mb-4">
                <v-card-title> Adresa trvalého bydliště </v-card-title>
                <v-divider></v-divider>
                <v-list two-line>
                  <v-list-item v-if="user.contact_street">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.contact_street }}</v-list-item-title>
                      <v-list-item-subtitle>Ulice</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.contact_city">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.contact_city }}</v-list-item-title>
                      <v-list-item-subtitle>Město</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.contact_zip">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.contact_zip }}</v-list-item-title>
                      <v-list-item-subtitle>PSČ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.contact_country">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.contact_country }}</v-list-item-title>
                      <v-list-item-subtitle>Země</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-card class="mb-4">
                <v-card-title> Fakturační adresa </v-card-title>
                <v-divider></v-divider>
                <v-list two-line>
                  <v-list-item v-if="user.invoice_company_name">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_company_name }}</v-list-item-title>
                      <v-list-item-subtitle>Firma</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.invoice_company_number">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_company_number }}</v-list-item-title>
                      <v-list-item-subtitle>IČ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.invoice_street">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_street }}</v-list-item-title>
                      <v-list-item-subtitle>Ulice</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.invoice_city">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_city }}</v-list-item-title>
                      <v-list-item-subtitle>Město</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.invoice_zip">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_zip }}</v-list-item-title>
                      <v-list-item-subtitle>PSČ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.invoice_country">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.invoice_country }}</v-list-item-title>
                      <v-list-item-subtitle>Země</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-card class="mb-4">
                <v-card-title>Payments</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="payments.length == 0">
                  <p class="text-center pt-3">No payments assigned yet.</p>
                </v-card-text>
                <o-list
                  v-if="payments.length > 0"
                  :loadedItems="payments"
                  :filterData="null"
                  :filter="null"
                  :tenant="tenant"
                  listItemComponent="UserPaymentItem"
                ></o-list>
              </v-card>
            </v-col>

            <users-feed :user-id="user.user_id" :tenant="tenant"></users-feed>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="instructor">
        <v-container fluid>
          <v-card class="my-2 mx-2" v-if="ledTrainings.length > 0">
            <v-card-title>Instructor Trainings</v-card-title>
            <v-divider></v-divider>
            <o-list
              v-if="filteredLedTrainings.length > 0"
              :loadedItems="filteredLedTrainings"
              :filterData="null"
              :filter="null"
              :tenant="tenant"
              listItemComponent="TrainingItem"
            ></o-list>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import UsersFeed from './Feed.vue'
import moment from 'moment'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'
import TrainingGroupsList from './components/TrainingGroupsList.vue'
import TrainingsList from './components/TrainingsList.vue'
import MembershipList from './components/MembershipList.vue'
import WaitingListItems from './components/WaitingListItems.vue'

export default {
  mixins: [WithLoading],
  components: {
    UsersFeed,
    OList,
    TrainingGroupsList,
    TrainingsList,
    MembershipList,
    WaitingListItems,
  },
  data() {
    return {
      user: {
        gym: {},
      },
      presence: [],
      presenceLimit: 20,
      payments: [],
      ledTrainings: [],
      ledTrainingsLimit: 5,
      paymentsLimit: 5,
      dateAdd14days: moment().add(14, 'd').format('YYYY-MM-DD'),
      dateAdd1Month: moment().add(1, 'M').format('YYYY-MM-DD'),
      loading: true,
      showChangeState: false,
      userStates: [],
      userState: null,
    }
  },
  methods: {
    changeState() {
      this.$http
        .post(`v1/tenant/${this.tenant}/users/${this.user.user_id}/states`, { user_state_id: this.userState })
        .then((response) => {
          this.user.state.name = response.data.name
          this.showChangeState = false
        })
        .catch(() => {
          this.$store.dispatch('showWarningMessage', 'Something went wrong')
        })
    },
    extendLinkExpiration() {
      this.$http
        .post(`v1/tenant/${this.tenant}/users/${this.user.id}/extend-magic-link`)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Platnost odkazu prodloužena o 14 dní.')
          this.fetchData()
        })
        .catch(() => {
          this.$store.dispatch('showWarningMessage', 'Something went wrong')
        })
    },
    navigateTo(path) {
      this.$router.push(`${path}`)
    },
    showTrainings() {
      this.$router.push('/users/' + this.user.user_id + '/instructor-trainings')
    },
    hasRole(roles) {
      if (this.$store.getters.role === null) {
        return false
      }
      if (roles.indexOf(this.$store.getters.role) >= 0) {
        return true
      }
      return false
    },
    showMorePresence() {
      this.presenceLimit = this.presenceLimit + 10
    },
    showMoreLedTrainings() {
      this.ledTrainingsLimit = this.ledTrainingsLimit + 10
    },
    showMorePayments() {
      this.paymentsLimit = this.paymentsLimit + 10
    },
    openTraining(id) {
      this.$router.push('/trainings/' + id)
    },
    openGroup(id) {
      this.$router.push('/groups/' + id)
    },
    openPayment(id) {
      this.$router.push('/payments/' + id)
    },
    fetchData() {
      this.loadingStart()
      var userId = this.$route.params.userId
      Promise.all([
        this.$http.get('v1/tenant/' + this.tenant + '/users/' + userId),
        this.$http.get('v1/tenant/' + this.tenant + '/users/' + userId + '/trainings'),
        this.$http.get('v1/tenant/' + this.tenant + '/users/' + userId + '/payments'),
        this.$http.get(
          'v1/tenant/' +
            this.tenant +
            '/users/' +
            userId +
            '/led-trainings?start=' +
            moment().subtract(2, 'M').format('YYYY-MM-DD') +
            '&end=' +
            moment().format('YYYY-MM-DD')
        ),
        this.$http.get('v1/tenant/' + this.tenant + '/userStates/'),
      ])
        .then((response) => {
          this.user = response[0].data
          this.presence = response[1].data.data
          this.payments = response[2].data
          this.ledTrainings = response[3].data
          this.userStates = response[4].data
          this.userState = this.user.state.id
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
  computed: {
    filteredPresence() {
      var filteredPresence = this.presence.filter((item) => {
        return item.type !== -10
      })
      return filteredPresence.slice(0, this.presenceLimit)
    },
    remainingPresence() {
      return this.presence.length - this.presenceLimit
    },
    filteredLedTrainings() {
      return this.ledTrainings.slice(0, this.ledTrainingsLimit)
    },
    remainingLedTrainings() {
      return this.ledTrainings.length - this.ledTrainingsLimit
    },
    filteredPayments() {
      return this.payments.slice(0, this.paymentsLimit)
    },
    remainingPayments() {
      return this.payments.length - this.paymentsLimit
    },
    covidStatusText() {
      if (this.user.covid_ok_until === null) return 'Unknown'
      let validUntil = moment(this.user.covid_ok_until)

      let covidStatus = ''
      if (this.user.covid_status !== null) {
        covidStatus = this.user.covid_status + ' - '
      }

      if (moment().isAfter(validUntil)) {
        return covidStatus + 'Expired: ' + validUntil.format('DD. MM. YYYY')
      }
      return covidStatus + 'Valid until: ' + validUntil.format('DD. MM. YYYY')
    },
    covidStatusClass() {
      if (this.user.covid_ok_until === null) return 'orange--text'
      let validUntil = moment(this.user.covid_ok_until)
      if (moment().isAfter(validUntil)) {
        return 'red--text'
      }
      return 'green--text'
    },
  },
}
</script>
