<template>
    <v-list-item
        :to="{ name: 'GroupsView', params: { tenant, groupId: item.id } }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">supervisor_account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title
                ><strong>{{ item.name }}</strong></v-list-item-title
            >
            <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
            <v-list-item-title class="text-center"
                ><strong>{{ item.avgPresence }}</strong></v-list-item-title
            >
            <v-list-item-subtitle class="text-center"
                >avg. presence</v-list-item-subtitle
            >
        </v-list-item-content>
        <v-list-item-content>
            <v-list-item-title class="text-center"
                ><strong>{{ item.members }}</strong></v-list-item-title
            >
            <v-list-item-subtitle class="text-center"
                >total members</v-list-item-subtitle
            >
        </v-list-item-content>
        <v-list-item-content class="pr-4">
            <v-list-item-title class="text-xs-right"
                >Payed members: {{ item.payedMembers }}</v-list-item-title
            >
            <v-list-item-subtitle class="text-xs-right"
                >Unpayed members:
                {{ item.unPayedMembers }}</v-list-item-subtitle
            >
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'group-report-list-item',
    props: ['item', 'tenant'],
}
</script>
