<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon class="grey lighten-3">date_range</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <strong
          >{{ readableParity(item) }} {{ item.humanWeekday }} ({{ item.timeStart.substring(0, 5) }} -
          {{ item.timeEnd.substring(0, 5) }}) <span v-if="item.title">- {{ item.title }}</span></strong
        >
      </v-list-item-title>
      <v-list-item-subtitle>{{ item.user_name }} </v-list-item-subtitle>
      <v-list-item-subtitle
        >Capacity: {{ item.capacity }} / Overbooking: {{ item.overbooking_count }} / Substitution slots:
        {{ item.substitution_slots }}</v-list-item-subtitle
      >
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            ripple
            :to="{
              name: 'TrainingPatternsEdit',
              params: { tenant, trainingPatternId: item.id },
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="grey--text text--lighten-1">edit</v-icon>
          </v-btn>
        </template>
        <span>Modify</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'training-pattern-list-item',
  props: ['item', 'tenant'],
  methods: {
    // TODO: Fix sklonovani
    readableParity(item) {
      if (item.week_parity === null) return 'Každé'
      if (item.week_parity == 1) return 'Sudé'
      if (item.week_parity == 0) return 'Liché'
      return ''
    },
  },
}
</script>
