<template>
    <v-card class="mb-4" :loading="isLoading">
        <v-card-title>
            Training Groups
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        :to="{
                            name: 'UsersGroups',
                            params: { tenant: tenantId, userId: userId },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="primary--text">group_add</v-icon>
                    </v-btn>
                </template>
                <span>Assign user to group</span>
            </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-skeleton-loader type="list-item-two-line" v-if="isLoading"></v-skeleton-loader>
        <v-card-text v-if="!isLoading">
            <p class="text-center pt-3" v-if="patterns.length == 0">No training groups assigned yet.</p>
            <v-list two-line v-if="patterns.length > 0">
                <v-list-item
                    :to="{
                        name: 'GroupsView',
                        params: { tenant: tenantId, groupId: item.group_id },
                    }"
                    v-for="item in patterns"
                    :key="item.id"
                >
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-3">date_range</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>{{ item.title }} - {{ item.humanWeekday }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >{{ item.timeStart.substring(0, 5) }} -
                            {{ item.timeEnd.substring(0, 5) }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon ripple @click.stop.prevent="removePattern(item)" v-bind="attrs" v-on="on">
                                    <v-icon class="grey--text text--lighten-1">delete_forever</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove from group</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import ErrorMixin from '../../../mixins/ErrorMixin'

export default {
    mixins: [ErrorMixin],
    props: ['tenantId', 'userId'],
    data() {
        return {
            patterns: [],
            isLoading: true,
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        removePattern(item) {
            this.$http
                .delete('v1/tenant/' + this.tenantId + '/users/' + this.userId + '/patterns/' + item.id)
                .then(() => {
                    var index = this.patterns.indexOf(item)
                    this.patterns.splice(index, 1)
                    this.$store.dispatch('showSuccessMessage', 'User has been removed from training group')
                })
                .catch(this.handleResponseErrors)
        },
        fetchData() {
            this.$http
                .get('v1/tenant/' + this.tenantId + '/users/' + this.userId + '/patterns')
                .then((response) => {
                    this.patterns = response.data
                    this.isLoading = false
                })
                .catch(this.handleResponseErrors)
        },
    },
}
</script>
