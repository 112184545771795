<template>
    <v-list-item>
        <v-list-item-avatar>
            <v-icon class="green white--text" v-if="item.status == 'Active'"
                >assignment_ind</v-icon
            >
            <v-icon class="blue white--text" v-if="item.status == 'Future'"
                >assignment_ind</v-icon
            >
            <v-icon class="red white--text" v-if="item.status == 'Expired'"
                >assignment_ind</v-icon
            >
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong
                    >{{ item.dateStart | formatDate }} -
                    {{ item.dateEnd | formatDate }}</strong
                >
            </v-list-item-title>
            <v-list-item-subtitle>{{
                item.type ? item.type.name : 'No type'
            }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        ripple
                        :to="{
                            name: 'MembershipsEdit',
                            params: { tenant, membershipId: item.id },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="grey--text text--lighten-1">edit</v-icon>
                    </v-btn>
                </template>
                <span>Modify</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'user-list-item',
    props: ['item', 'tenant'],
}
</script>
