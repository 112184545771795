<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-card height="100%" :loading="loading">
          <v-card-title>Balance (today)</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center" v-if="!loading">
            <div class="display-1 mt-5 mb-5">{{ dailyBalance }} CZK</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card height="100%" :loading="loading">
          <v-card-title>Income</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center" v-if="!loading">
            <div class="display-1 mt-5 mb-5">{{ positiveSum }} CZK</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card height="100%" :loading="loading">
          <v-card-title>Outcome</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center" v-if="!loading">
            <div class="display-1 mt-5 mb-5">{{ negativeSum }} CZK</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            Payments
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="downloadExport()" v-bind="attrs" v-on="on">
                  <v-icon class="grey--text text--lighten-1">file_download</v-icon>
                </v-btn>
              </template>
              <span>Export payments to Excel</span>
            </v-tooltip>
              <v-tooltip
                            bottom
                            class="mx-0"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="navigateTo('payments/create')"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="primary--text mr-3"
                                >
                                    <v-icon>add_box</v-icon>
                                </v-btn>
                            </template>
                            <span>Create new payment</span>
                        </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading">
            <v-select
              v-bind:items="filterItems"
              v-model="paymentFilter"
              label="Type"
              item-text="text"
              item-value="value"
              bottom
            ></v-select>
            <vuetify-date-picker
              label="From"
              :initialDate="rangeStart"
              v-on:changed="updateStart"
            ></vuetify-date-picker>
            <vuetify-date-picker label="To" :initialDate="rangeEnd" v-on:changed="updateEnd"></vuetify-date-picker>
            <v-divider></v-divider>
            <v-card-text v-if="filteredData.length === 0">
              <p class="text-center pt-3">No payments matching selected filter.</p>
            </v-card-text>
            <o-list
              v-if="filteredData.length > 0"
              :loadedItems="filteredData"
              :tenant="tenant"
              listItemComponent="PaymentItem"
            ></o-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'

export default {
  name: 'payments',
  mixins: [WithLoading],
  components: { VuetifyDatePicker, OList },
  data() {
    return {
      income: [],
      outcome: [],
      payments: [],
      paymentLimit: 10,
      paymentCount: null,
      cashflowChart: null,
      cashflowPredictionChart: null,
      showCashflowDetails: false,
      paymentFilter: null,
      date: null,
      rangeStart: null,
      rangeEnd: null,
      positiveSum: 0,
      negativeSum: 0,
      filterItems: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Income',
          value: 'income',
        },
        {
          text: 'Outcome',
          value: 'outcome',
        },
      ],
      cashflowChartData: {
        labels: [''],
        datasets: [],
      },
      distributionChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#36A2EB', '#FF6384', '#9C27B0', '#00BCD4', '#8BC34A'],
          },
        ],
      },
      chartOptions: {
        legend: {
          position: 'bottom',
        },
      },
      transactionsSumChartDatasets: [
        {
          label: 'Income',
          backgroundColor: 'rgba(63,152,82,0.5)',
          borderColor: 'rgba(63,152,82,0.5)',
          data: [0, 0],
        },
        {
          label: 'Outcome',
          backgroundColor: 'rgba(204,36,40,0.5)',
          borderColor: 'rgba(204,36,40,0.5)',
          data: [0, 0],
        },
      ],
      dailyBalance: 0,
      token: localStorage.getItem('access_token'),
    }
  },
  computed: {
    filteredData() {
      if (this.paymentFilter === 'income') {
        return this.payments.filter((item) => {
          return item.amount > 0
        })
      }
      if (this.paymentFilter === 'outcome') {
        return this.payments.filter((item) => {
          return item.amount < 0
        })
      }
      return this.payments
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(`/${path}`)
    },
    downloadExport() {
      window.location =
        process.env.VUE_APP_API_URL +
        'api/v1/tenant/' +
        this.tenant +
        '/export/payments?filter=' +
        this.paymentFilter +
        '&rangeStart=' +
        this.rangeStart +
        '&rangeEnd=' +
        this.rangeEnd +
        '&token=' +
        this.$store.state.token
    },
    changeFiltering() {
      sessionStorage.setItem('payments-filter', this.paymentFilter)
    },
    updateStart(value) {
      this.rangeStart = value
      sessionStorage.setItem('payments-start', value)
      this.getTransactions()
    },
    updateEnd(value) {
      this.rangeEnd = value
      sessionStorage.setItem('payments-end', value)
      this.getTransactions()
    },
    setDateRange() {
      this.paymentFilter = sessionStorage.getItem('payments-filter')
      if (this.paymentFilter === null) {
        this.paymentFilter = 'all'
      }
      this.rangeStart = sessionStorage.getItem('payments-start')
      if (this.rangeStart === null) {
        this.rangeStart = moment().subtract(30, 'days').format('YYYY-MM-DD')
        sessionStorage.setItem('payments-start', this.rangeStart)
      }
      this.rangeEnd = sessionStorage.getItem('payments-end')
      if (this.rangeEnd === null) {
        this.rangeEnd = moment().format('YYYY-MM-DD')
        sessionStorage.setItem('payments-end', this.rangeEnd)
      }
    },
    getTransactions() {
      this.loadingStart()
      this.$http
        .get('v1/tenant/' + this.tenant + '/payments?rangeStart=' + this.rangeStart + '&rangeEnd=' + this.rangeEnd)
        .then((data) => {
          this.payments = data.data
          this.paymentCount = this.payments.length
          this.paymentLimit = 13

          this.positiveSum = 0
          this.negativeSum = 0

          var membershipsSum = 0
          var otherSum = 0

          this.payments.forEach((item) => {
            if (item.amount > 0) {
              this.positiveSum += item.amount
              if (item.membership_id !== null) {
                membershipsSum += item.amount
              } else {
                otherSum += item.amount
              }
            } else {
              this.negativeSum -= item.amount
            }
          })

          this.cashflowChartData.datasets = []
          this.cashflowChartData.datasets.push({
            label: 'Income',
            backgroundColor: 'rgba(63,152,82,0.5)',
            data: [this.positiveSum, 0],
          })
          this.cashflowChartData.datasets.push({
            label: 'Outcome',
            backgroundColor: 'rgba(204,36,40,0.5)',
            data: [this.negativeSum, 0],
          })

          this.distributionChartData.labels = []
          this.distributionChartData.labels.push('Memberships')
          this.distributionChartData.labels.push('Other')

          this.distributionChartData.datasets[0].data = []
          this.distributionChartData.datasets[0].data.push(membershipsSum)
          this.distributionChartData.datasets[0].data.push(otherSum)

          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
    fetchData() {
      this.loadingStart()
      this.setDateRange()
      Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/cashierBalance')])
        .then((response) => {
          this.dailyBalance = response[0].data
          this.getTransactions()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
