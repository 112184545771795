<template>
    <v-container fluid>
        <v-row wrap>
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4" :loading="loading">
                    <v-card-title>
                        <span>Substitute</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <user-autocomplete
                            @change="updateUser"
                            :error-messages="userErrors"
                        ></user-autocomplete>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="save()"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'SubstituteTraining',
    mixins: [WithLoading, WithSubmitting, ErrorMixin],
    components: {
        UserAutocomplete,
    },
    data: () => ({
        training: null,
        user: null,
    }),
    validations: {
        user: { required },
    },
    computed: {
        userErrors() {
            const errors = []
            if (!this.$v.user.$dirty) return errors
            !this.$v.user.required && errors.push('User is required.')
            return errors
        },
    },
    methods: {
        async updateUser(user) {
            this.user = user
        },
        async save() {
            if (!this.isFormValid()) {
                return
            }

            try {
                await this.$http.post(
                    `v1/tenant/${this.$store.state.tenant}/users/${this.user.id}/trainings/${this.$route.params.trainingId}/substitute`
                )
                this.$store.dispatch(
                    'showSuccessMessage',
                    'Substitution was created.'
                )
                this.$router.push({
                    name: 'TrainingsView',
                    params: { trainingId: this.$route.params.trainingId },
                })
            } catch (err) {
                this.handleResponseErrors(err)
            }
        },
        fetchData() {
            this.loadingStart()
            this.$http
                .get(
                    `v1/tenant/${this.$store.state.tenant}/trainings/${this.$route.params.trainingId}`
                )
                .then((response) => {
                    this.training = response.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
