<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col cols="12" md="8">
        <v-card class="mb-4">
          <v-card-title>
            <span v-if="isCreate">Create new email template</span>
            <span v-if="!isCreate">{{ template.name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field name="name" label="Name" id="name" v-model="template.name"></v-text-field>
            <v-text-field name="from_name" label="From name" id="from_name" v-model="template.from_name"></v-text-field>
            <v-text-field
              name="from_email"
              label="From email"
              id="from_email"
              v-model="template.from_email"
            ></v-text-field>
            <v-text-field name="subject" label="Subject" id="subject" v-model="template.subject"></v-text-field>
            <editor
              :initialValue="template.body"
              api-key="e8c9yad0xu5cscudzuquigaztxkjjplb4z8zygifm61o0d7c"
              v-model="template.body"
              :init="{
                height: 500,
                images_upload_handler: uploadImage,
                images_upload_credentials: true,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap',
                  'searchreplace visualblocks code fullscreen',
                  'print preview anchor insertdatetime media',
                  'paste code help wordcount table emoticons',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic underline strikethrough | \
        alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | \
        bullist numlist outdent indent | table | link image media | emoticons',
              }"
            ></editor>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn light @click.prevent="showAreYouSure = true" v-if="template.id">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mb-4">
          <v-card-title> Special tags </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list dense>
              <v-subheader>Trainings</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#first-training-date#</v-list-item-title>
                  <v-list-item-subtitle>Date of first training</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#first-training-time#</v-list-item-title>
                  <v-list-item-subtitle>First training time</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#training-date#</v-list-item-title>
                  <v-list-item-subtitle>Training date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#training-time#</v-list-item-title>
                  <v-list-item-subtitle>Training time</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>User</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#salutation#</v-list-item-title>
                  <v-list-item-subtitle>Salutation</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#email#</v-list-item-title>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#phone#</v-list-item-title>
                  <v-list-item-subtitle>Phone number</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#password#</v-list-item-title>
                  <v-list-item-subtitle>Generated password</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#fullname#</v-list-item-title>
                  <v-list-item-subtitle>Full name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#password-reset-link#</v-list-item-title>
                  <v-list-item-subtitle>Link to reset password</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#user-list#</v-list-item-title>
                  <v-list-item-subtitle>Link to user profile (for admins only)</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>Training Group</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#group-name#</v-list-item-title>
                  <v-list-item-subtitle>Group name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>Payments</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#amount#</v-list-item-title>
                  <v-list-item-subtitle>Payment amount</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>Memberships</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#membership-date-start#</v-list-item-title>
                  <v-list-item-subtitle>Membership start date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#membership-date-end#</v-list-item-title>
                  <v-list-item-subtitle>Membership end date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#membership-expiration-date#</v-list-item-title>
                  <v-list-item-subtitle>Substitution slots expiration date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>Gym</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>#gym-name#</v-list-item-title>
                  <v-list-item-subtitle>Name of the gym</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <are-you-sure :show="showAreYouSure" @decline="showAreYouSure = false" @confirm="doDelete()"></are-you-sure>
  </v-container>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import WithLoading from '../../mixins/WithLoading.js'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'
import ErrorMixin from '../../mixins/ErrorMixin.js'

export default {
  mixins: [WithLoading, ErrorMixin],
  components: { Editor, AreYouSure },
  data() {
    return {
      showAreYouSure: false,
      template: {
        name: 'New email template',
        body: 'Insert your text here...',
      },
      editor: null,
      isCreate: false,
    }
  },
  methods: {
    uploadImage(blobInfo, success, failure, progress) {
      var xhr, formData

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', process.env.VUE_APP_API_URL + 'v2/tenant/1/upload-image')
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.state.token)

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }

      xhr.onload = function () {
        var json

        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
      }

      formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    },
    applyTextEdit(newBody) {
      this.template.body = newBody
    },
    doDelete() {
      this.$http
        .delete('v1/tenant/' + this.tenant + '/emailTemplates/' + this.template.id)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Email template has been deleted.')
          this.$router.push({ name: 'EmailTemplates' })
        })
        .catch(this.handleResponseErrors)
    },
    save() {
      if (this.isCreate) {
        this.$http
          .post('v1/tenant/' + this.tenant + '/emailTemplates', this.template)
          .then(() => {
            this.$store.dispatch('showSuccessMessage', 'Email template has been created.')
            this.$router.go(-1)
          })
          .catch((response) => {
            this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
          })
      } else {
        this.$http
          .put('v1/tenant/' + this.tenant + '/emailTemplates/' + this.template.id, this.template)
          .then(() => {
            this.$store.dispatch('showSuccessMessage', 'Email template has been updated.')
            this.$router.go(-1)
          })
          .catch((response) => {
            this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
          })
      }
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.templateId) {
        this.$http
          .get('v1/tenant/' + this.tenant + '/emailTemplates/' + this.$route.params.templateId)
          .then((data) => {
            this.template = data.data
            this.tags = this.template.allowed_tags
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        this.isCreate = true
        this.loadingCompleted()
      }
    },
  },
}
</script>
