export default {
  data() {
    return {
      sending: false,
      idleSubmitText: 'Save',
      workingSubmitText: 'Saving...',
      svMessages: {},
    }
  },
  methods: {
    isFormValid() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return false
      }
      this.sending = true
      return true
    },
    // Server validation only
    submitStarted() {
      this.sending = true
    },
    submitCompleted() {
      this.sending = false
    },
    submitFailed(error) {
      this.sending = false
      if (error.response.data && error.response.data.message) {
        this.$store.dispatch('showWarningMessage', `${error.response.data.message}`)
      } else {
        this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${error.response.status}`)
      }
    },
    failedRequest(error) {
      var response = error.response
      this.sending = false
      this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
    },
    // Client validation
    submittingCompleted() {
      this.sending = false
    },
    submittingFailed(response) {
      this.sending = false
      this.$v.$reset()
      this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
    },
  },
  computed: {
    submitText() {
      return this.sending ? this.workingSubmitText : this.idleSubmitText
    },
  },
}
