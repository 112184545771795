<template>
    <v-list-item :to="{ name: 'UsersView', params: { tenant, userId: item.id } }">
        <v-list-item-avatar>
            <v-icon :class="classes">person</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong>{{ item.firstname }} {{ item.lastname }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
                {{ item.email }}
                <br />
                {{ item.stateName }} - {{ item.stateUpdated | formatDate }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom class="mx-0"
                ><template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        :to="{
                            name: 'FirstTraining',
                            params: { userId: item.id },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="purple--text">person_add</v-icon>
                    </v-btn></template
                >
                <span>Assign first training</span>
            </v-tooltip>
        </v-list-item-action>
        <v-list-item-action>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        ripple
                        :to="{
                            name: 'UsersEdit',
                            params: { tenant, userId: item.id },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="grey--text text--lighten-1">edit</v-icon>
                    </v-btn>
                </template>
                <span>Modify</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'user-registration-item',
    props: ['item', 'tenant'],
    computed: {
        classes() {
            return this.item.backgroundColor + ' white--text'
        },
    },
}
</script>
