<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>
                        <span v-if="isCreate">Create new membership</span>
                        <span v-if="!isCreate">Modify membership</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col xs12 md4>
                                <v-subheader
                                    v-text="'Membership Type'"
                                ></v-subheader>
                            </v-col>
                            <v-col xs12 md8>
                                <v-select
                                    :items="membershipTypes"
                                    v-model="membershipType"
                                    label="Membership type"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    v-on:input="updateMembershipType"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="membershipType">
                            <v-col xs12 md4>
                                <v-subheader v-text="'Dates'"></v-subheader>
                            </v-col>
                            <v-col xs12 md8>
                                <div
                                    class="form-group"
                                    :class="{
                                        'form-group--error':
                                            $v.membership.dateStart.$error,
                                    }"
                                >
                                    <vuetify-date-picker
                                        :initialDate="membership.dateStart"
                                        @changed="updateStart"
                                        label="Start"
                                    ></vuetify-date-picker>
                                    <div
                                        class="red--text"
                                        v-if="$v.membership.dateStart.$error"
                                    >
                                        <span
                                            v-if="
                                                !$v.membership.dateStart
                                                    .required
                                            "
                                            >Start date is required</span
                                        >
                                    </div>
                                </div>
                                <vuetify-date-picker
                                    :initialDate="membership.dateEnd"
                                    @changed="updateEnd"
                                    label="End"
                                    :min-date="minEndDate"
                                    :max-date="maxEndDate"
                                ></vuetify-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xs12 md4>
                                <v-subheader
                                    v-text="'Notifications'"
                                ></v-subheader>
                            </v-col>
                            <v-col xs12 md8>
                                <v-checkbox
                                    label="Send email notification"
                                    v-model="sendEmail"
                                    light
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3 px-2">
                        <v-btn
                            light
                            @click="showAreYouSure = true"
                            v-if="!isCreate"
                            >Delete</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="save()"
                            :loading="sending"
                            :disabled="sending"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <are-you-sure
            :show="showAreYouSure"
            @decline="showAreYouSure = false"
            @confirm="doDelete(membership)"
        ></are-you-sure>
    </v-container>
</template>

<script>
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import moment from 'moment'
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import { required } from 'vuelidate/lib/validators'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'

export default {
    mixins: [WithLoading, WithSubmitting, ErrorMixin],
    components: {
        VuetifyDatePicker,
        AreYouSure,
    },
    data() {
        return {
            showAreYouSure: false,
            userId: null,
            membershipTypes: [],
            membership: {
                dateStart: null,
                dateEnd: null,
            },
            membershipType: null,
            isCreate: true,
            sendEmail: true,
            newDateEnd: null,
            minEndDate: null,
            maxEndDate: null,
        }
    },
    validations: {
        membershipType: { required },
        membership: {
            dateStart: { required },
        },
    },
    methods: {
        updateMembershipType(newMembershipType) {
            this.membershipType = newMembershipType
            this.membership.membership_type_id = this.membershipType.id
            if (this.membership.dateStart) {
                this.membership.dateEnd = moment(this.membership.dateStart)
                    .add(this.membershipType.duration - 1, 'days')
                    .format('YYYY-MM-DD')
                this.newDateEnd = this.membership.dateEnd
                this.updateLimits()
            }
        },
        updateStart(date) {
            this.membership.dateStart = date
            this.membership.dateEnd = moment(this.membership.dateStart)
                .add(this.membershipType.duration - 1, 'days')
                .format('YYYY-MM-DD')
            this.newDateEnd = this.membership.dateEnd
            this.updateLimits()
        },
        updateEnd(date) {
            this.membership.dateEnd = date
        },
        hasRole(roles) {
            if (this.$store.getters.role === null) {
                return false
            }
            if (roles.indexOf(this.$store.getters.role) >= 0) {
                return true
            }
            return false
        },
        updateLimits() {
            this.minEndDate = moment(this.membership.dateStart)
                .add(this.membershipType.duration - 1, 'days')
                .format('YYYY-MM-DD')
            if (this.hasRole('administrator') || this.hasRole('manager')) {
                this.maxEndDate = null
            } else {
                this.maxEndDate = moment(this.membership.dateStart)
                    .add(
                        this.membershipType.duration +
                            this.membershipType.extendDuration -
                            1,
                        'days'
                    )
                    .format('YYYY-MM-DD')
            }
        },
        doDelete(membership) {
            this.$http
                .delete(
                    'v1/tenant/' + this.tenant + '/memberships/' + membership.id
                )
                .then(() => {
                    this.$store.dispatch(
                        'showSuccessMessage',
                        'Membership has been deleted.'
                    )
                    this.$router.push('/memberships/')
                })
                .catch(this.handleResponseErrors)
        },
        save() {
            if (!this.isFormValid()) {
                return
            }
            if (typeof this.membership.id === 'undefined') {
                this.membership.sendEmail = this.sendEmail
                this.membership.user_id = this.userId
                this.$http
                    .post(
                        'v1/tenant/' + this.tenant + '/memberships',
                        this.membership
                    )
                    .then(() => {
                        this.submittingCompleted()
                        this.$store.dispatch(
                            'showSuccessMessage',
                            'Membership has been created.'
                        )
                        this.$router.go(-1)
                    })
                    .catch(this.submitFailed)
            } else {
                this.membership.sendEmail = this.sendEmail
                this.$http
                    .put(
                        'v1/tenant/' +
                            this.tenant +
                            '/memberships/' +
                            this.membership.id,
                        this.membership
                    )
                    .then(() => {
                        this.submittingCompleted()
                        this.$router.go(-1)
                    })
                    .catch(this.submitFailed)
            }
        },
        fetchData() {
            this.loadingStart()
            var membershipId = this.$route.params.membershipId
            this.userId = this.$route.params.userId

            if (membershipId) {
                this.isCreate = false
                return Promise.all([
                    this.$http.get(
                        'v1/tenant/' + this.tenant + '/membershipTypes'
                    ),
                    this.$http.get(
                        'v1/tenant/' +
                            this.tenant +
                            '/memberships/' +
                            membershipId
                    ),
                ])
                    .then((data) => {
                        data[1].data.dateStart = moment(
                            data[1].data.dateStart
                        ).format('YYYY-MM-DD')
                        data[1].data.dateEnd = moment(
                            data[1].data.dateEnd
                        ).format('YYYY-MM-DD')

                        this.membershipType = data[1].data.type
                        this.minEndDate = moment(data[1].data.dateStart)
                            .add(data[1].data.type.duration - 1, 'days')
                            .format('YYYY-MM-DD')
                        this.maxEndDate = moment(data[1].data.dateStart)
                            .add(
                                data[1].data.type.duration +
                                    data[1].data.type.extendDuration -
                                    1,
                                'days'
                            )
                            .format('YYYY-MM-DD')

                        this.membershipTypes = data[0].data
                        this.membership = data[1].data

                        this.updateLimits()

                        this.loadingCompleted()
                    })
                    .catch((response) => {
                        this.loadingFailed(response)
                    })
            } else {
                return Promise.all([
                    this.$http.get(
                        'v1/tenant/' + this.tenant + '/membershipTypes'
                    ),
                ])
                    .then((data) => {
                        this.membershipTypes = data[0].data
                        this.loadingCompleted()
                    })
                    .catch((response) => {
                        this.loadingFailed(response)
                    })
            }
        },
    },
}
</script>
