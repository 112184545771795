var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Memberships "),_c('v-spacer'),(!_vm.loading)?_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.hasFutureMembership,"to":{
                            name: 'MembershipsCreate',
                        }}},[_c('v-icon',[_vm._v("add_box")])],1)],1)]}}],null,false,1929169939)},[_c('span',[_vm._v(_vm._s(_vm.hasFutureMembership ? 'User already has a future membership' : 'Create new membership'))])]):_vm._e()],1),_c('v-divider'),(!_vm.loading)?_c('v-card-text',[(_vm.filteredMemberships.length == 0)?_c('p',{staticClass:"text-center pt-3"},[_vm._v("No memberships")]):_vm._e(),_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.filteredMemberships),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(item.dateStart))+" - "+_vm._s(_vm._f("formatDate")(item.dateEnd))+" "),(item.status === 'Suspended')?_c('span',{staticClass:"red--text"},[_vm._v(" ("+_vm._s(item.remainingDays)+" days) ")]):_vm._e()])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.type ? item.type.name : 'No type'))]),_c('v-list-item-subtitle',[_c('span',{class:_vm.statusCssClasses(item)},[_vm._v(" "+_vm._s(item.status)+" "),(item.status == 'Expiring')?_c('span',[_vm._v("("+_vm._s(item.free_slots)+" slots)")]):_vm._e()])])],1),(item.status === 'Active')?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":"","to":{
                                    name: 'MembershipSuspend',
                                    params: { membershipId: item.id },
                                }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("pause")])],1)]}}],null,true)},[_c('span',[_vm._v("Suspend")])])],1):_vm._e(),(item.status === 'Suspended')?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":"","to":{
                                    name: 'MembershipResume',
                                    params: { membershipId: item.id },
                                }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("play_arrow")])],1)]}}],null,true)},[_c('span',[_vm._v("Resume")])])],1):_vm._e(),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","ripple":"","to":{
                                    name: 'MembershipsEdit',
                                    params: { membershipId: item.id },
                                }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Modify")])])],1)],1)}),1),(!_vm.showExpired)?_c('div',{staticClass:"text-center pb-2"},[_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){_vm.showExpired = true}}},[_vm._v(" Show expired memberships ("+_vm._s(_vm.hiddenCount)+") ")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }