<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Attach new action</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Action'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-select
                  label="Select action"
                  v-model="selectedAction"
                  item-text="text"
                  return-object
                  :items="actions"
                  @input="loadResources"
                ></v-select>

                <v-select
                  v-if="selectedAction.resource_type === 'CloudDesk\\Models\\UserState' && userStates.length > 0"
                  label="User state"
                  v-model="triggerAction.resource_id"
                  item-text="name"
                  item-value="id"
                  :items="userStates"
                ></v-select>

                <v-select
                  v-if="
                    selectedAction.resource_type === 'CloudDesk\\Models\\EmailTemplate' && emailTemplates.length > 0
                  "
                  label="Email template"
                  v-model="triggerAction.resource_id"
                  item-text="name"
                  item-value="id"
                  :items="emailTemplates"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Scope'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-select
                  v-if="userStates.length > 0"
                  label="User state"
                  v-model="triggerAction.user_state_id"
                  item-text="name"
                  item-value="id"
                  hint="Do this action only if user is in this state"
                  persistent-hint
                  clearable
                  :items="userStates"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="submitForm()">{{ submitText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [WithLoading, WithSubmitting],
  data() {
    return {
      triggerAction: {
        action_id: null,
        resource_id: null,
        resource_type: null,
        user_state_id: null,
      },
      actions: [],
      emailTemplates: [],
      userStates: [],
      selectedAction: {
        resource_type: null,
      },
    }
  },
  validations: {
    triggerAction: {
      action_id: { required },
    },
  },
  methods: {
    loadResources(action) {
      this.triggerAction.action_id = action.id
      this.triggerAction.resource_type = action.resource_type
    },
    submitForm() {
      if (!this.isFormValid()) {
        return
      }
      this.$http
        .put('v1/tenant/' + this.tenant + '/triggers/' + this.$route.params.id, this.triggerAction)
        .then(() => {
          this.submittingCompleted()
          this.$router.go(-1)
        })
        .catch((response) => {
          this.submittingFailed(response)
        })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([
        this.$http.get('v1/tenant/' + this.tenant + '/actions'),
        this.$http.get('v1/tenant/' + this.tenant + '/userStates'),
        this.$http.get('v1/tenant/' + this.tenant + '/emailTemplates'),
      ])
        .then((response) => {
          this.actions = response[0].data
          this.userStates = response[1].data
          this.emailTemplates = response[2].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
