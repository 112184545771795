<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>Texts</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            name="search"
                            label="Search for an item"
                            v-model="searchText"
                            prepend-icon="search"
                            single-line
                        ></v-text-field>
                    </v-card-text>
                    <o-list
                        :loadedItems="items"
                        :filterData="searchText"
                        :filter="searchFilter"
                        :tenant="tenant"
                        listItemComponent="TextItem"
                    ></o-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import OList from '../../components/list.vue'
import WithLoading from '../../mixins/WithLoading.js'

export default {
    components: { OList },
    mixins: [WithLoading],
    data() {
        return {
            items: [],
            searchText: null,
        }
    },
    methods: {
        searchFilter(item) {
            let search = new RegExp(this.searchText, 'i')
            return search.test(item.slug)
        },
        fetchData() {
            this.loadingStart()
            return Promise.all([
                this.$http.get('v1/tenant/' + this.tenant + '/texts'),
            ])
                .then((response) => {
                    this.items = response[0].data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
