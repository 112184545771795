<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Assign free training</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div
              class="form-group"
              :class="{
                'form-group--error': $v.selectedGroupId.$error,
              }"
            >
              <v-select
                label="Training group"
                v-model="selectedGroupId"
                item-text="name"
                item-value="id"
                :items="groups"
                @input="updateGroup"
              ></v-select>
              <div class="form-messages" v-if="$v.selectedGroupId.$error">
                <span class="form-messages__item" v-if="!$v.selectedGroupId.required">Group is required</span>
              </div>
            </div>
            <div
              v-if="selectedGroupId"
              class="form-group"
              :class="{
                'form-group--error': $v.selectedTrainingId.$error,
              }"
            >
              <label for="training" class="form-group__label">Select training</label>
              <training-select
                :selected="selectedTrainingId"
                :options="trainings"
                placeholder="Click to select..."
                v-on:changed="updateTraining"
              ></training-select>
              <div class="form-messages" v-if="$v.selectedTrainingId.$error">
                <span class="form-messages__item" v-if="!$v.selectedTrainingId.required">Training is required</span>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="submitForm()">{{ submitText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'
import TrainingSelect from '../../components/selects/TrainingSelect.vue'

export default {
  components: { TrainingSelect },
  mixins: [WithLoading, WithSubmitting],
  data() {
    return {
      selectedGroupId: null,
      selectedTrainingId: null,
      userId: null,
      groups: [],
      trainings: [],
    }
  },
  validations: {
    selectedGroupId: { required },
    selectedTrainingId: { required },
  },
  methods: {
    updateGroup() {
      this.selectedTrainingId = null
      this.$http
        .get('v1/tenant/' + this.tenant + '/groups/' + this.selectedGroupId + '/trainings')
        .then((data) => {
          this.trainings = data.data
        })
        .catch(() => {
          console.log('Error loading trainings')
          this.trainings = []
        })
    },
    updateTraining(training) {
      this.selectedTrainingId = training === null ? null : training.id
    },
    submitForm() {
      if (!this.isFormValid()) {
        return
      }
      var sendData = {
        userId: this.userId,
        entryType: 10,
      }
      this.$http
        .post('v1/tenant/' + this.tenant + '/trainings/' + this.selectedTrainingId + '/users', sendData)
        .then(() => {
          this.submittingCompleted()
          this.$router.go(-1)
        })
        .catch((response) => {
          this.submittingFailed(response)
        })
    },
    fetchData() {
      this.loadingStart()
      this.userId = this.$route.params.userId
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/groups')])
        .then((response) => {
          this.groups = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
