<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            {{ isCreate ? 'Create new group' : group.name }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'General'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-text-field
                  name="name"
                  label="Name"
                  id="name"
                  v-model="group.name"
                  @input="$v.group.name.$touch()"
                  :error-messages="nameErrors"
                ></v-text-field>
                <v-textarea
                  name="description"
                  label="Note"
                  id="description"
                  v-model="group.description"
                  @input="$v.group.description.$touch()"
                  :error-messages="descriptionErrors"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-btn
              icon
              v-if="!isCreate"
              @mouseover="deleteBtnColor = 'red darken-4'"
              @mouseout="deleteBtnColor = ''"
              @click.prevent="showAreYouSure = true"
              ><v-icon :color="deleteBtnColor">delete</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()" :loading="sending" :disabled="sending">{{
              isCreate ? 'Create' : 'Save changes'
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <are-you-sure :show="showAreYouSure" @decline="showAreYouSure = false" @confirm="doDelete()"></are-you-sure>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'
import ErrorMixin from '../../mixins/ErrorMixin.js'

export default {
  mixins: [WithLoading, WithSubmitting, ErrorMixin],
  components: { AreYouSure },
  data() {
    return {
      deleteBtnColor: '',
      showAreYouSure: false,
      group: {
        name: null,
        user_id: null,
        user: null,
        capacity: null,
        reserve_threshold: null,
        description: null,
      },
      isCreate: false,
    }
  },
  validations: {
    group: {
      name: { required },
      description: { required },
    },
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.group.name.$dirty) return errors
      !this.$v.group.name.required && errors.push('Name is required.')
      return errors
    },
    userIdErrors() {
      const errors = []
      if (!this.$v.group.user_id.$dirty) return errors
      !this.$v.group.user_id.required && errors.push('Instructor is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.group.description.$dirty) return errors
      !this.$v.group.description.required && errors.push('Description is required.')
      return errors
    },
  },
  methods: {
    doDelete() {
      this.$http
        .delete('v1/tenant/' + this.tenant + '/groups/' + this.group.id)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Group has been deleted.')
          this.$router.push({ name: 'Groups' })
        })
        .catch(this.handleResponseErrors)
    },
    save() {
      if (!this.isFormValid()) {
        return
      }
      if (this.isCreate) {
        this.$http
          .post('v1/tenant/' + this.tenant + '/groups', this.group)
          .then(() => {
            this.submittingCompleted()
            this.$store.dispatch('showSuccessMessage', 'Group has been created.')
            this.$router.go(-1)
          })
          .catch(this.failedSubmit)
      } else {
        this.$http
          .put('v1/tenant/' + this.tenant + '/groups/' + this.group.id, this.group)
          .then(() => {
            this.submittingCompleted()
            this.$store.dispatch('showSuccessMessage', 'Group has been updated.')
            this.$router.go(-1)
          })
          .catch(this.failedSubmit)
      }
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.groupId) {
        return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/groups/' + this.$route.params.groupId)])
          .then((response) => {
            this.group = response[0].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        this.isCreate = true
        this.loadingCompleted()
      }
    },
  },
}
</script>
