<template>
    <v-container fluid>
        <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>Create permission</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <user-autocomplete @change="updateUser" :errorMessages="userErrors"></user-autocomplete>
                        <v-select label="Role" v-model="role" :items="roles" :error-messages="roleErrors"></v-select>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3 px-2">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click.stop="submitForm()"
                            :loading="isSubmitting"
                            :disabled="isSubmitting"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import { required } from 'vuelidate/lib/validators'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'
import ErrorMixin from '../../mixins/ErrorMixin'
import FormMixin from '../../mixins/FormMixin'

export default {
    mixins: [WithLoading, ErrorMixin, FormMixin],
    components: { UserAutocomplete },
    data() {
        return {
            user: null,
            roles: [
                { value: 'administrator', text: 'Administrator' },
                { value: 'manager', text: 'Manager' },
                { value: 'instructor', text: 'Instructor' },
                { value: 'receptionist', text: 'Receptionist' },
            ],
            role: null,
        }
    },
    validations: {
        user: { required },
        role: { required },
    },
    computed: {
        roleErrors() {
            const errors = []
            if (!this.$v.role.$dirty) return errors
            !this.$v.role.required && errors.push('Role is required.')
            return errors
        },
        userErrors() {
            const errors = []
            if (!this.$v.user.$dirty) return errors
            !this.$v.user.required && errors.push('User is required.')
            return errors
        },
    },
    methods: {
        updateUser(user) {
            this.user = user.id
        },
        submit() {
            this.$http
                .post(`v1/tenant/${this.$store.state.tenant}/users/${this.user}/permissions/`, { role: this.role })
                .then(() => {
                    this.$store.dispatch('showSuccessMessage', 'Permission has been created.')
                    this.$router.push({ name: 'Permissions' })
                })
                .catch(this.handleFailedSubmit)
        },
        fetchData() {
            this.loadingCompleted()
        },
    },
}
</script>
