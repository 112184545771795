<template>
    <v-list-item
        :to="{ name: 'EmailsView', params: { tenant, emailId: item.id } }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">mail</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title
                ><strong>{{ item.subject }}</strong></v-list-item-title
            >
            <v-list-item-subtitle>{{
                item.date | formatDate
            }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'email-list-item',
    props: ['item', 'tenant'],
}
</script>
