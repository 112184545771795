<template>
  <v-card class="mb-4" :loading="isLoading">
    <v-card-title>
      <span class="pl-3">Members</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom class="mx-0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="
              $router.push({
                name: 'GroupsUsers',
                params: { groupId: group.id },
              })
            "
            v-bind="attrs"
            v-on="on"
            color="primary"
            class="mr-4"
          >
            <v-icon>person_add</v-icon>
          </v-btn>
        </template>
        <span>Add user to group</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-skeleton-loader type="list-item-three-line" v-if="isLoading"></v-skeleton-loader>
    <v-card-text v-if="!isLoading">
      <span v-if="members.length === 0">Group has no members.</span>
      <v-list two-line v-if="members.length > 0">
        <v-list-item
          v-for="item in members"
          :key="item.id"
          :to="{
            name: 'UsersView',
            params: { userId: item.id },
          }"
          ><v-list-item-avatar>
            <v-icon class="green white--text" v-if="hasActiveMembership(item)">person</v-icon>
            <v-icon class="grey lighten-3" v-if="!hasActiveMembership(item)">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              ><strong>{{ item.firstname }} {{ item.lastname }}</strong></v-list-item-title
            >
            <v-list-item-subtitle>
              {{ item.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom class="mx-0"
              ><template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.stop="removeUser(item)" color="red" v-bind="attrs" v-on="on">
                  <v-icon>person_remove</v-icon>
                </v-btn></template
              >
              <span>Remove from group</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ErrorMixin from '../../mixins/ErrorMixin'
export default {
  props: ['tenantId', 'group'],
  mixins: [ErrorMixin],
  data() {
    return {
      members: [],
      activeMembershipIds: [],
      isLoading: true,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    hasActiveMembership(user) {
      return this.activeMembershipIds.find((item) => {
        return item === user.id
      })
    },
    removeUser(item) {
      this.$http
        .delete(`v1/tenant/${this.tenant}/users/${item.id}/groups/${this.group.id}`)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'User has been removed from the group.')
          this.$router.push(`/groups/${this.group.id}`)
        })
        .catch((response) => {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
        })
    },
    async fetchData() {
      this.isLoading = true
      this.members = (await this.$http.get(`v1/tenant/${this.tenantId}/groups/${this.group.id}/members`)).data
      this.activeMembershipIds = (await this.$http.get(`v1/tenant/${this.tenantId}/memberships/active`)).data
      this.isLoading = false
    },
  },
}
</script>
