<template>
  <v-container fluid>
    <memberships-statistics :memberships_count="activeMembershipsCount" v-if="$vuetify.breakpoint.lgAndUp" />
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>Memberships</v-card-title>
          <v-divider></v-divider>
          <v-list two-line v-if="!loading">
            <v-list-item v-for="membership in limitedData" @click="openUser(membership.user_id)" :key="membership.id">
              <v-list-item-avatar>
                <v-icon class="green white--text" v-if="!isExpiringSoon(membership) && !isExpired(membership)"
                  >assignment_ind</v-icon
                >
                <v-icon class="orange white--text" v-if="isExpiringSoon(membership)">assignment_ind</v-icon>
                <v-icon class="red white--text" v-if="isExpired(membership)">assignment_ind</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ membership.firstname }} {{ membership.lastname }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle
                  >{{ membership.dateStart | formatDate }} - {{ membership.dateEnd | formatDate }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action v-if="isExpired(membership) && membership.leaving_reason_email_sent === null">
                <v-btn icon ripple @click.stop="sendLeavingReason(membership)">
                  <v-icon class="grey--text text--lighten-1">mail</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon ripple @click.stop="editMembership(membership)">
                  <v-icon class="grey--text text--lighten-1">edit</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div v-if="remainingData > 0" class="text-center pb-2">
            <v-btn @click="showMore()" color="primary">Show more ({{ remainingData }} hidden)</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <memberships-statistics :memberships_count="activeMembershipsCount" v-if="$vuetify.breakpoint.mdAndDown" />
  </v-container>
</template>

<script>
import moment from 'moment'
import WithLoading from '../../mixins/WithLoading.js'
import MembershipsStatistics from './components/MembershipsStatistics.vue'

export default {
  mixins: [WithLoading],
  components: {
    MembershipsStatistics,
  },
  data() {
    return {
      memberships: [],
      showLimit: 10,
      typeDistributionChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#36A2EB', '#FF6384', '#9C27B0', '#00BCD4', '#8BC34A'],
          },
        ],
      },
      typeDistributionChartOptions: {
        legend: {
          position: 'bottom',
        },
      },
    }
  },
  methods: {
    isExpired(membership) {
      return moment(membership.dateEnd).isBefore(moment())
    },
    isExpiringSoon(membership) {
      return moment(membership.dateEnd).isBetween(moment(), moment().add(7, 'days'))
    },
    openUser(userId) {
      this.$router.push('/users/' + userId)
    },
    editMembership(membership) {
      this.$router.push('/memberships/' + membership.id + '/edit')
    },
    sendLeavingReason(membership) {
      var index = this.memberships.indexOf(membership)
      this.memberships[index].leaving_reason_email_sent = true
      this.$http
        .post('v1/tenant/' + this.tenant + '/users/' + membership.user_id + '/send/leaving-reason', {})
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Leaving reason email has been sent.')
        })
        .catch((response) => {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
        })
    },
    showMore() {
      this.showLimit = this.showLimit + 10
    },
    mapTypeDistribution(data) {
      data.forEach((item) => {
        this.typeDistributionChartData.labels.push(item.type)
        this.typeDistributionChartData.datasets[0].data.push(item.count)
      })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([
        this.$http.get(
          'v1/tenant/' +
            this.tenant +
            '/memberships?filter=feed&start=' +
            moment().subtract(7, 'days').format('YYYY-MM-DD')
        ),
        this.$http.get('v1/tenant/' + this.tenant + '/membership-stats/current'),
      ])
        .then((response) => {
          this.mapTypeDistribution(response[1].data.membership_types)
          this.memberships = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
  computed: {
    filteredData() {
      return this.memberships
    },
    limitedData() {
      return this.filteredData.slice(0, this.showLimit)
    },
    remainingData() {
      return this.filteredData.length - this.showLimit
    },
    activeMembershipsCount() {
      var activeMemberships = this.memberships.filter((membership) => {
        return moment(membership.dateEnd).isSameOrAfter(moment())
      })
      return activeMemberships.length
    },
  },
}
</script>
