<template>
    <v-card class="mb-4" :loading="loading">
        <v-card-title>
            Memberships
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0" v-if="!loading">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-btn
                            icon
                            color="primary"
                            :disabled="hasFutureMembership"
                            :to="{
                                name: 'MembershipsCreate',
                            }"
                        >
                            <v-icon>add_box</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>{{
                    hasFutureMembership ? 'User already has a future membership' : 'Create new membership'
                }}</span>
            </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!loading">
            <p class="text-center pt-3" v-if="filteredMemberships.length == 0">No memberships</p>

            <v-list three-line>
                <v-list-item v-for="item in filteredMemberships" :key="item.id">
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong
                                >{{ item.dateStart | formatDate }} -
                                {{ item.dateEnd | formatDate }}
                                <span class="red--text" v-if="item.status === 'Suspended'">
                                    ({{ item.remainingDays }} days)
                                </span>
                            </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ item.type ? item.type.name : 'No type' }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <span :class="statusCssClasses(item)">
                                {{ item.status }}
                                <span v-if="item.status == 'Expiring'">({{ item.free_slots }} slots)</span>
                            </span></v-list-item-subtitle
                        >
                    </v-list-item-content>
                    <v-list-item-action v-if="item.status === 'Active'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    ripple
                                    :to="{
                                        name: 'MembershipSuspend',
                                        params: { membershipId: item.id },
                                    }"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mt-3"
                                >
                                    <v-icon class="primary--text">pause</v-icon>
                                </v-btn>
                            </template>
                            <span>Suspend</span>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action v-if="item.status === 'Suspended'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    ripple
                                    :to="{
                                        name: 'MembershipResume',
                                        params: { membershipId: item.id },
                                    }"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mt-3"
                                >
                                    <v-icon class="primary--text">play_arrow</v-icon>
                                </v-btn>
                            </template>
                            <span>Resume</span>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    ripple
                                    :to="{
                                        name: 'MembershipsEdit',
                                        params: { membershipId: item.id },
                                    }"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mt-3"
                                >
                                    <v-icon class="primary--text">edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Modify</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <div class="text-center pb-2" v-if="!showExpired">
                <v-btn color="primary" @click.native="showExpired = true">
                    Show expired memberships ({{ hiddenCount }})
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import ErrorMixin from '../../../mixins/ErrorMixin'

export default {
    mixins: [ErrorMixin],
    data() {
        return {
            memberships: [],
            loading: true,
            showExpired: false,
        }
    },
    mounted() {
        this.fetchData()
    },
    computed: {
        hasFutureMembership() {
            const futureMembership = this.memberships.find((item) => {
                return item.status === 'Future'
            })
            return futureMembership ? true : false
        },
        filteredMemberships() {
            if (!this.showExpired) {
                return this.memberships.filter((item) => {
                    return item.status !== 'Expired'
                })
            }
            return this.memberships
        },
        hiddenCount() {
            return this.memberships.length - this.filteredMemberships.length
        },
    },
    methods: {
        statusCssClasses(membership) {
            if (membership.status === 'Future') return 'blue pa-2 white--text'
            if (membership.status === 'Active') return 'green pa-2 white--text'
            if (membership.status === 'Expiring') return 'orange pa-2 white--text'
            if (membership.status === 'Expired') return 'red pa-2 white--text'
            if (membership.status === 'Suspended') return 'yellow pa-2'
            return 'pa-2'
        },
        fetchData() {
            this.$http
                .get('v1/tenant/' + this.$store.state.tenant + '/users/' + this.$route.params.userId + '/memberships')
                .then((response) => {
                    this.memberships = response.data
                    this.loading = false
                })
                .catch(this.handleResponseErrors)
        },
    },
}
</script>
