var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" Trainings "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","color":"#64748B"},on:{"click":function($event){return _vm.$router.push({
                            name: 'Substitutions',
                            params: { userId: _vm.userId },
                        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("visibility")])],1)]}}])},[_c('span',[_vm._v("User's view")])]),_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'FirstTraining',
                            params: { userId: _vm.userId },
                        })}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"purple--text"},[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("Assign first training")])])],1),_c('v-divider'),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):_vm._e(),(!_vm.isLoading)?_c('v-card-text',[(_vm.trainings.length == 0)?_c('p',{staticClass:"text-center pt-3"},[_vm._v("No trainings")]):_vm._e(),(_vm.trainings.length > 0)?_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.trainings),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
                    name: 'TrainingsView',
                    params: { tenant: _vm.tenantId, trainingId: item.id },
                }}},[_c('v-list-item-avatar',[_c('v-icon',{class:_vm.trainingCssClass(item)},[_vm._v(_vm._s(_vm.trainingIcon(item)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(item.start)))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.start))+" - "+_vm._s(_vm._f("formatTime")(item.end))+" ")])],1)],1)}),1):_vm._e(),(_vm.trainings.length > 0)?_c('v-pagination',{attrs:{"length":_vm.lastPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }