<template>
    <div />
</template>
<script>
export default {
    mounted() {
        localStorage.removeItem('token')
        localStorage.removeItem('tenant')
        localStorage.removeItem('permissions')
        sessionStorage.clear()
        this.$store.commit('SET_TOKEN', '')
        this.$store.commit('SET_PERMISSIONS', [])
        this.$store.commit('SET_TENANT', null)
        this.$router.push({ name: 'Login' })
    },
}
</script>
