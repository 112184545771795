<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Send new email</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Header'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-select label="Sender" v-model="sender" item-text="name" return-object :items="senders"></v-select>
                <v-select
                  label="Target audience"
                  v-model="target"
                  item-text="name"
                  item-value="id"
                  :items="targets"
                ></v-select>
                <v-select
                  v-if="target == 'Gym'"
                  label="Filtering"
                  v-model="filter"
                  item-text="name"
                  item-value="id"
                  :items="filters"
                ></v-select>
                <v-select
                  v-if="target == 'Group'"
                  label="Select target group"
                  v-model="email.groups"
                  item-text="name"
                  return-object
                  :items="groups"
                  multiple
                ></v-select>
                <v-select
                  v-if="target == 'Group'"
                  label="Filtering"
                  v-model="filter"
                  item-text="name"
                  item-value="id"
                  :items="group_filters"
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Body'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-text-field name="subject" label="Subject" id="subject" v-model="email.subject"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <editor
                  :initialValue="body"
                  api-key="e8c9yad0xu5cscudzuquigaztxkjjplb4z8zygifm61o0d7c"
                  v-model="body"
                  :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap',
                      'searchreplace visualblocks code fullscreen',
                      'print preview anchor insertdatetime media',
                      'paste code help wordcount table',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | help',
                  }"
                >
                </editor>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import Editor from '@tinymce/tinymce-vue'

export default {
  mixins: [WithLoading],
  components: { Editor },
  props: ['preSelectedTarget'],
  data() {
    return {
      body: 'Insert your text here...',
      email: {
        groups: [],
      },
      targets: [
        { id: 'Gym', name: 'Gym' },
        { id: 'Group', name: 'Group' },
      ],
      senders: [],
      filters: [
        { id: 'all', name: 'All users' },
        { id: 'in-groups', name: 'All users in training groups' },
        {
          id: 'only-members',
          name: 'Only users with active membership',
        },
        { id: 'waiting', name: 'All waiting users' },
      ],
      group_filters: [
        { id: 'members', name: 'Members' },
        { id: 'waiting', name: 'Waiting users' },
      ],
      target: null,
      sender: null,
      groups: [],
      filter: null,
      sTarget: null,
    }
  },
  methods: {
    applyTextEdit(newBody) {
      this.body = newBody
    },
    save() {
      this.email.body = this.body
      this.email.sender = this.sender
      this.email.target = this.target

      this.email.filter = this.filter !== null ? this.filter : null

      this.$http
        .post('v1/tenant/' + this.tenant + '/emails', this.email)
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {
          console.log('Error sending data')
        })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([
        this.$http.get('v1/tenant/' + this.tenant + '/groups'),
        this.$http.get('v1/tenant/' + this.tenant + '/gyms/' + this.tenant),
      ])
        .then((data) => {
          this.senders.push({
            id: 1,
            name: data[1].data.default_email_name + ' <' + data[1].data.default_email_address + '>',
            email: data[1].data.default_email_address,
          })
          this.groups = data[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
