<template>
    <v-container fluid>
        <lost-users-statistics
            :lost_users_count="users.length"
            v-if="$vuetify.breakpoint.lgAndUp"
        />
        <v-row>
            <v-col>
                <v-card :loading="loading">
                    <v-card-title>Lost Users</v-card-title>
                    <v-divider></v-divider>
                    <v-skeleton-loader
                        type="list-item-two-line"
                        v-if="loading"
                    ></v-skeleton-loader>
                    <v-card-text v-if="!loading">
                        <vuetify-date-picker
                            label="From"
                            :initialDate="rangeStart"
                            v-on:changed="updateStart"
                        ></vuetify-date-picker>
                        <vuetify-date-picker
                            label="To"
                            :initialDate="rangeEnd"
                            v-on:changed="updateEnd"
                        ></vuetify-date-picker>
                        <v-divider></v-divider>

                        <p
                            class="text-center pt-3"
                            v-if="limitedData.length === 0"
                        >
                            No lost users in given range.
                        </p>

                        <v-list three-line v-if="limitedData.length > 0">
                            <v-list-item
                                v-for="user in limitedData"
                                @click="openUser(user.id)"
                                :key="user.id"
                            >
                                <v-list-item-avatar>
                                    <v-icon class="grey lighten-3"
                                        >person</v-icon
                                    >
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <strong
                                            >{{ user.firstname }}
                                            {{ user.lastname }}</strong
                                        >
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ user.email | truncate(20) }}
                                        <br />
                                        {{ user.membership_type }} - ended
                                        {{ user.membership_end | formatDate }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        ripple
                                        @click.stop.native="editUser(user.id)"
                                    >
                                        <v-icon
                                            class="grey--text text--lighten-1"
                                            >edit</v-icon
                                        >
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <div v-if="remainingData > 0" class="text-center">
                            <v-btn @click="showMore()" color="primary"
                                >Show more ({{ remainingData }} hidden)</v-btn
                            >
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <lost-users-statistics
            :lost_users_count="users.length"
            v-if="$vuetify.breakpoint.mdAndDown"
        />
    </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import LostUsersStatistics from './components/LostUsersStatistics.vue'

export default {
    mixins: [WithLoading],
    components: {
        LostUsersStatistics,
        VuetifyDatePicker,
    },
    data() {
        return {
            users: [],
            searchText: null,
            showLimit: 10,
            rangeStart: moment().subtract(60, 'days').format('YYYY-MM-DD'),
            rangeEnd: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            genderDistributionData: {
                labels: ['Male', 'Female', 'Undefined'],
                data: [],
                colors: ['#36A2EB', '#FF6384', '#CCC'],
            },
            ageDistributionData: {
                labels: [
                    '13-17',
                    '13-17',
                    '18-24',
                    '25-34',
                    '35-44',
                    '45-54',
                    '55-64',
                    '65-100',
                    'undefined',
                ],
                datasets: [],
            },
        }
    },
    methods: {
        updateStart(value) {
            this.rangeStart = value
            sessionStorage.setItem('lost-users-start', value)
            this.fetchData()
        },
        updateEnd(value) {
            this.rangeEnd = value
            sessionStorage.setItem('lost-users-end', value)
            this.fetchData()
        },
        setDateRange() {
            this.rangeStart = sessionStorage.getItem('lost-users-start')
            if (this.rangeStart === null) {
                this.rangeStart = moment()
                    .subtract(60, 'days')
                    .format('YYYY-MM-DD')
            }
            this.rangeEnd = sessionStorage.getItem('lost-users-end')
            if (this.rangeEnd === null) {
                this.rangeEnd = moment()
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD')
            }
        },
        fetchData() {
            this.loadingStart()
            this.setDateRange()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/users/lost/?start=' +
                        this.rangeStart +
                        '&end=' +
                        this.rangeEnd
                )
                .then((response) => {
                    this.users = response.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
        openUser(id) {
            this.$router.push('/users/' + id)
        },
        editUser(id) {
            this.$router.push('/users/' + id + '/edit')
        },
        showMore() {
            this.showLimit = this.showLimit + 10
        },
        mapGenderDistributionData(data) {
            this.genderDistributionData.data.push(data.M)
            this.genderDistributionData.data.push(data.F)
            this.genderDistributionData.data.push(data.U)
        },
        mapAgeDistribution(data) {
            var dataset = {
                label: 'Active Users',
                backgroundColor: 'rgba(63,152,82,0.5)',
                borderColor: 'rgba(63,152,82,0.5)',
                data: [
                    data.age_13_17,
                    data.age_13_17,
                    data.age_18_24,
                    data.age_25_34,
                    data.age_35_44,
                    data.age_45_54,
                    data.age_55_64,
                    data.age_65_100,
                    data.undefined,
                ],
            }
            this.ageDistributionData.datasets.push(dataset)
        },
    },
    computed: {
        filteredData() {
            if (!this.searchText) {
                return this.users
            }
            return this.users.filter((user) => {
                var search = new RegExp(this.searchText, 'i')
                return (
                    search.test(user.email) ||
                    search.test(user.firstname) ||
                    search.test(user.lastname) ||
                    search.test(user.firstname + ' ' + user.lastname)
                )
            })
        },
        limitedData() {
            return this.filteredData.slice(0, this.showLimit)
        },
        remainingData() {
            return this.filteredData.length - this.showLimit
        },
    },
}
</script>
