<template>
    <v-list-item
        :to="{ name: 'TextsView', params: { tenant, slug: item.slug } }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">description</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong>{{ item.title }}</strong>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        ripple
                        :to="{
                            name: 'TextsEdit',
                            params: { tenant, slug: item.slug },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="grey--text text--lighten-1">edit</v-icon>
                    </v-btn>
                </template>
                <span>Modify</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'text-item',
    props: ['item', 'tenant'],
}
</script>
