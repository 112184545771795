var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" Training Groups "),_c('v-spacer'),_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                        name: 'UsersGroups',
                        params: { tenant: _vm.tenantId, userId: _vm.userId },
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("group_add")])],1)]}}])},[_c('span',[_vm._v("Assign user to group")])])],1),_c('v-divider'),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):_vm._e(),(!_vm.isLoading)?_c('v-card-text',[(_vm.patterns.length == 0)?_c('p',{staticClass:"text-center pt-3"},[_vm._v("No training groups assigned yet.")]):_vm._e(),(_vm.patterns.length > 0)?_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.patterns),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
                    name: 'GroupsView',
                    params: { tenant: _vm.tenantId, groupId: item.group_id },
                }}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-3"},[_vm._v("date_range")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item.title)+" - "+_vm._s(item.humanWeekday))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.timeStart.substring(0, 5))+" - "+_vm._s(item.timeEnd.substring(0, 5)))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removePattern(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text text--lighten-1"},[_vm._v("delete_forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove from group")])])],1)],1)}),1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }