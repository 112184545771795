<template>
  <v-list-item :to="{ name: 'UsersView', params: { tenant, userId: item.user.id } }">
    <v-list-item-avatar>
      <v-icon class="grey lighten-3">person</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        ><strong>{{ item.user.firstname }} {{ item.user.lastname }}</strong></v-list-item-title
      >
      <v-list-item-subtitle>
        <span>Total: {{ item.total }} CZK</span><br />
        <span>Hour salary: {{ item.user.training_reward }} CZK | Trainings count: {{ item.training_count }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip bottom class="mx-0"
        ><template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            ripple
            :to="{
              name: 'UsersInstructorTrainings',
              params: { tenant, userId: item.user.id },
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="grey--text text--lighten-1">fitness_center</v-icon>
          </v-btn></template
        >
        <slot>Show related trainings</slot>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'salary-list-item',
  props: ['item', 'tenant'],
}
</script>
