<template>
  <v-container fluid>
    <gym-statistics :groups_count="groups.length" v-if="$vuetify.breakpoint.lgAndUp" />
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            Groups
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :to="{ name: 'GroupsCreate' }">
                  <v-icon class="primary--text">add_box</v-icon>
                </v-btn>
              </template>
              <span>Create new group</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading">
            <v-text-field
              name="search"
              label="Search for a group"
              v-model="searchText"
              prepend-icon="search"
              single-line
            ></v-text-field>

            <o-list
              :loadedItems="groups"
              :filterData="searchText"
              :filter="searchFilter"
              :tenant="tenant"
              listItemComponent="GroupItem"
            ></o-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <gym-statistics :groups_count="groups.length" v-if="$vuetify.breakpoint.mdAndDown" />
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'
import GymStatistics from './components/GymStatistics.vue'

export default {
  components: { OList, GymStatistics },
  mixins: [WithLoading],
  data() {
    return {
      groups: [],
      searchText: null,
    }
  },
  methods: {
    searchFilter(item) {
      let search = new RegExp(this.searchText, 'i')
      return search.test(item.name)
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get(`v1/tenant/${this.tenant}/groups`)])
        .then((response) => {
          this.groups = response[0].data

          this.$store.dispatch('setBreadcrumbs', [
            {
              text: 'Home',
              href: this.$router.resolve({ name: 'Dashboard' }).href,
            },
            {
              text: 'Groups',
            },
          ])

          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
