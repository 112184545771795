import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    tenant: parseInt(localStorage.getItem('tenant')) || null,
    permissions: JSON.parse(localStorage.getItem('permissions')) || [],
    popup: false,
    popupMessage: '',
    popupColor: 'black',
    breadcrumbs: [],
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_TENANT(state, payload) {
      state.tenant = payload
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    SHOW_POPUP(state) {
      state.popup = true
    },
    HIDE_POPUP(state) {
      state.popup = false
    },
    SET_POPUP_MESSAGE(state, payload) {
      state.popupMessage = payload
    },
    SET_POPUP_COLOR(state, payload) {
      state.popupColor = payload
    },
    SET_BREADCRUMBS(state, payload) {
      state.breadcrumbs = payload
    },
  },
  actions: {
    resetBreadcrumbs({ commit }) {
      commit('SET_BREADCRUMBS', [])
    },
    setBreadcrumbs({ commit }, payload) {
      commit('SET_BREADCRUMBS', payload)
    },
    setTenant({ commit }, payload) {
      commit('SET_TENANT', payload)
      localStorage.setItem('tenant', payload)
    },
    setToken({ commit }, payload) {
      commit('SET_TOKEN', payload)
      localStorage.setItem('token', payload)
    },
    setPermissions({ commit }, payload) {
      commit('SET_PERMISSIONS', payload)
      localStorage.setItem('permissions', JSON.stringify(payload))
    },
    showSuccessMessage({ commit }, payload) {
      commit('SET_POPUP_MESSAGE', payload)
      commit('SET_POPUP_COLOR', 'success')
      commit('SHOW_POPUP')
    },
    showWarningMessage({ commit }, payload) {
      commit('SET_POPUP_MESSAGE', payload)
      commit('SET_POPUP_COLOR', 'warning')
      commit('SHOW_POPUP')
    },
  },
  getters: {
    role(state) {
      if (state.permissions) {
        return state.permissions.find((permission) => permission.id === state.tenant).role
      } else {
        return ''
      }
    },
    getTenantName(state) {
      if (state.permissions) {
        return state.permissions.find((permission) => permission.id === state.tenant).name
      } else {
        return ''
      }
    },
    isAuthenticated(state) {
      return !!state.token
    },
  },
})
