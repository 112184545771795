<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4" v-for="module in modules" :key="module">
          <v-card-title>{{ module }}</v-card-title>
          <v-divider></v-divider>
          <v-list>
            <v-list-group v-model="item.active" v-for="item in itemsForModule(module)" :key="item.name" no-action>
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span :class="{ 'red--text': item.actions.length === 0 }">{{
                        item.name
                      }}</span></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item v-for="subItem in item.actions" :key="subItem.id">
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-if="subItem.user_state">Ve stavu [{{ subItem.user_state.name }}] </span>
                    {{ subItem.action.text }}
                    <span v-if="subItem.resource"> [{{ subItem.resource.name }}]</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="detachAction(item, subItem)">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="attachAction(item.id)">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="primary--text">Attach new action...</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import swal from 'sweetalert'
export default {
  name: 'triggers-list',
  mixins: [WithLoading],
  data() {
    return {
      items: [],
      modules: [],
    }
  },
  methods: {
    itemsForModule(module) {
      return this.items.filter((item) => {
        return item.module === module
      })
    },
    attachAction(id) {
      this.$router.push(`/triggers/${id}/create`)
    },
    detachAction(item, subItem) {
      swal({
        title: 'Are you sure?',
        icon: 'info',
        buttons: {
          cancel: 'Cancel',
          delete: 'Delete',
        },
      }).then((value) => {
        switch (value) {
          case 'delete':
            this.$http
              .delete('v1/tenant/' + this.tenant + '/triggers/' + subItem.id)
              .then(() => {
                this.$store.dispatch('showSuccessMessage', 'Action has been deleted.')
                var index = this.items.indexOf(item)
                var subIndex = this.items[index].actions.indexOf(subItem)
                this.items[index].actions.splice(subIndex, 1)
              })
              .catch((response) => {
                this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
              })
            break
          default:
        }
      })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/triggers')])
        .then((response) => {
          this.items = response[0].data

          this.modules = this.items
            .map((item) => {
              return item.module
            })
            .filter((value, index, self) => {
              return self.indexOf(value) === index
            })

          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
