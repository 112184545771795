<template>
    <v-container fluid>
        <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>
                        {{ template.name }}
                        <v-spacer></v-spacer>
                        <v-tooltip bottom class="mx-0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="navigateTo('/emailTemplates/' + template.id + '/edit')"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="primary--text mr-3"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Modify</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title
                                    >{{ template.from_name }} - {{ template.from_email }}</v-list-item-title
                                >
                                <v-list-item-subtitle>From</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ template.subject }}</v-list-item-title>
                                <v-list-item-subtitle>Subject</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
                <v-card class="mb-4">
                    <v-card-title>Email template body</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div class="al-l" v-html="template.body"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
    mixins: [WithLoading],
    data() {
        return {
            template: null,
        }
    },
    methods: {
        fetchData() {
            this.loadingStart()
            this.$http
                .get('v1/tenant/' + this.tenant + '/emailTemplates/' + this.$route.params.templateId)
                .then((data) => {
                    this.template = data.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
