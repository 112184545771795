<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Change public announcement</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <editor
              :initialValue="message"
              api-key="e8c9yad0xu5cscudzuquigaztxkjjplb4z8zygifm61o0d7c"
              v-model="message"
              :init="{
                height: 500,
                images_upload_handler: uploadImage,
                images_upload_credentials: true,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap',
                  'searchreplace visualblocks code fullscreen',
                  'print preview anchor insertdatetime media',
                  'paste code help wordcount table emoticons',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic underline strikethrough | \
        alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | \
        bullist numlist outdent indent | table | link image media | emoticons',
              }"
            >
            </editor>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: { Editor },
  mixins: [WithLoading],
  data() {
    return {
      message: '',
    }
  },
  methods: {
    uploadImage(blobInfo, success, failure, progress) {
      var xhr, formData

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', process.env.VUE_APP_API_URL + 'v2/tenant/1/upload-image')
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.state.token)

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }

      xhr.onload = function () {
        var json

        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
      }

      formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    },
    save() {
      this.$http
        .post('v1/tenant/' + this.tenant + '/gyms/' + this.tenant + '/public-announcement', {
          message: this.message,
        })
        .then(() => {
          this.$router.go(-1)
        })
        .catch((response) => {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
        })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/gyms/' + this.tenant)])
        .then((response) => {
          this.message = response[0].data.public_announcement
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
