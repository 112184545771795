<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4">
          <v-card-title>
            Payment #{{ payment.id }}
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="navigateTo('/payments/' + payment.id + '/edit')" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ payment.created_at | formatDate }}</v-list-item-title>
                <v-list-item-subtitle>Date</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="payment.from_external">{{ payment.from_external }}</span>
                  <span v-if="!payment.from_external"
                    >{{ payment.sender.firstname }} {{ payment.sender.lastname }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle>Sender</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="payment.to_external">{{ payment.to_external }}</span>
                  <span v-if="!payment.to_external">{{ payment.user.firstname }} {{ payment.user.lastname }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>Receiver</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ payment.amount }} CZK</v-list-item-title>
                <v-list-item-subtitle>Amount</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ payment.description }}</v-list-item-title>
                <v-list-item-subtitle>Description</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="payment.membership">
              <v-list-item-content>
                <v-list-item-title
                  >{{ payment.membership.dateStart | formatDate }}
                  -
                  {{ payment.membership.dateEnd | formatDate }}</v-list-item-title
                >
                <v-list-item-subtitle>Membership link</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
  name: 'PaymentView',
  mixins: [WithLoading],
  data() {
    return {
      payment: {},
    }
  },
  methods: {
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/payments/' + this.$route.params.paymentId)])
        .then((response) => {
          this.payment = response[0].data
          this.loadingCompleted()
        })
        .catch(() => {
          this.loadingFailed()
        })
    },
  },
}
</script>
