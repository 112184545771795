<template>
    <v-row>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Memberships</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="!loading">
                    <div class="display-2 mt-10 mb-10">
                        {{ memberships_count }}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Start/End memberships</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[startSet, endSet]"
                        :labels="monthlyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>New memberships (daily)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[dailyNewMembershipsSet]"
                        :labels="dailyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ZLineChart from '../../../components/charts/ZLineChart.vue'
import StatsService from '../../../services/StatsService'

export default {
    components: { ZLineChart },
    props: {
        memberships_count: {
            default: 0,
        },
    },
    data() {
        return {
            loading: true,
            startSet: null,
            endSet: null,
            monthlyLabels: [],
            dailyNewMembershipsSet: null,
            dailyLabels: [],
        }
    },
    mounted() {
        StatsService.load(this, this.drawCharts)
    },
    methods: {
        drawCharts(response) {
            this.loading = true

            let rawMonthlyStats = response[0].data
            let rawDailyStats = response[1].data

            this.startSet = {
                label: 'Started memberships',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            this.endSet = {
                label: 'Ended memberships',
                data: [],
                backgroundColor: '#FF5252',
                borderColor: '#FF5252',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawMonthlyStats.map((stat) => {
                this.monthlyLabels.push(stat.f_date)
                this.startSet.data.push(stat.started_memberships)
                this.endSet.data.push(stat.ended_memberships)
            })

            this.dailyNewMembershipsSet = {
                label: 'New memberships',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawDailyStats.map((stat) => {
                this.dailyLabels.push(stat.f_date)
                this.dailyNewMembershipsSet.data.push(stat.new_memberships)
            })

            this.loading = false
        },
    },
}
</script>
