export default (value) => {
    var isFormatOK = /^[0-2][0-9]:[0-5][0-9]$/.test(value)
    if (!isFormatOK) {
        return false
    }
    var parts = value.split(':')
    if (parseInt(parts[0]) > 23 || parseInt(parts[1]) > 59) {
        return false
    }
    return true
}
