<template>
    <v-row>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Members</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="!loading">
                    <div class="display-2 mt-10 mb-10">
                        {{ members_count }}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Members (monthly)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[monthlyActiveMembersSet]"
                        :labels="monthlyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Members (90 days)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[dailyActiveMembersSet]"
                        :labels="dailyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ZLineChart from '../../../components/charts/ZLineChart.vue'
import GroupStatsService from '../../../services/GroupStatsService'

export default {
    components: { ZLineChart },
    props: {
        groupId: [String, Number],
        members_count: {
            default: 0,
        },
    },
    data() {
        return {
            loading: true,
            monthlyActiveMembersSet: null,
            monthlyLabels: [],
            dailyActiveMembersSet: null,
            dailyLabels: [],
        }
    },
    mounted() {
        GroupStatsService.load(this, this.groupId, this.drawCharts)
    },
    methods: {
        drawCharts(response) {
            this.loading = true

            let rawMonthlyStats = response[0].data
            let rawDailyStats = response[1].data

            this.monthlyActiveMembersSet = {
                label: 'Members in group',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawMonthlyStats.map((stat) => {
                this.monthlyLabels.push(stat.f_date)
                this.monthlyActiveMembersSet.data.push(stat.avg_users)
            })

            this.dailyActiveMembersSet = {
                label: 'Members in group',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawDailyStats.map((stat) => {
                this.dailyLabels.push(stat.f_date)
                this.dailyActiveMembersSet.data.push(stat.users)
            })

            this.loading = false
        },
    },
}
</script>
