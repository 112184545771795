<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            <span v-if="!payment.id">Create new payment</span>
            <span v-if="payment.id">Edit payment #{{ payment.id }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-checkbox label="External Sender" v-model="hasExternalSender" light></v-checkbox>
            <v-text-field
              v-if="hasExternalSender"
              name="from_external"
              label="External Sender"
              id="from_external"
              v-model="payment.from_external"
            ></v-text-field>
            <user-autocomplete
              v-if="!hasExternalSender"
              label="Sender"
              :user="sender"
              @change="updateSender"
            ></user-autocomplete>

            <v-checkbox label="External Receiver" v-model="hasExternalReceiver" light></v-checkbox>
            <v-text-field
              v-if="hasExternalReceiver"
              name="to_external"
              label="External Receiver"
              id="to_external"
              v-model="payment.to_external"
            ></v-text-field>
            <user-autocomplete
              v-if="!hasExternalReceiver"
              label="Receiver"
              :user="receiver"
              @change="updateReceiver"
            ></user-autocomplete>

            <v-text-field
              name="amount"
              label="Amount"
              id="amount"
              v-model="payment.amount"
              @input="$v.payment.amount.$touch()"
              :error-messages="amountErrors"
            ></v-text-field>

            <v-text-field
              name="description"
              label="Description"
              id="description"
              v-model="payment.description"
              @input="$v.payment.description.$touch()"
              :error-messages="descriptionErrors"
              multi-line
            ></v-text-field>

            <div v-if="showLinkedResources">
              <!-- Link to membership -->
              <div v-if="event == null">
                <div v-if="!isMembershipFromVisible">
                  <h5 class="al-l">Link a membership</h5>
                  <div class="form-group" v-if="memberships.length > 0">
                    <membership-select
                      :selected="membership"
                      :options="memberships"
                      placeholder="Select membership"
                      v-on:changed="updateMembership"
                    ></membership-select>
                  </div>
                  <div v-if="memberships.length == 0" class="al-l">
                    <p>User has no memberships</p>
                  </div>
                  <div class="al-l">
                    <v-btn v-if="membership == null" color="primary" @click="showMembershipForm()"
                      >Create new membership</v-btn
                    >
                  </div>
                </div>
                <!-- TODO: WARNING but working -->
                <div v-if="isMembershipFromVisible">
                  <h2>Create membership</h2>
                  <membership-form
                    :membership="newMembership"
                    :membership-types="membershipTypes"
                    :membership-type="null"
                    :save="createMembership"
                    :cancel="cancelMembership"
                  ></membership-form>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2" v-if="!isMembershipFromVisible">
            <v-btn light @click.prevent="deleteItem()" v-if="payment.id">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">{{ submitText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MembershipSelect from '../../components/selects/MembershipSelect.vue'
import MembershipForm from '../../components/MembershipForm.vue'
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'
import swal from 'sweetalert'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'

export default {
  mixins: [WithLoading, WithSubmitting],
  components: {
    MembershipSelect,
    MembershipForm,
    UserAutocomplete,
  },
  data() {
    return {
      payment: {
        description: null,
        amount: null,
      },
      isUserProfileComplete: true,
      sender: null,
      receiver: null,
      memberships: [],
      membership: null,
      showLinkedResources: false,
      hasExternalSender: false,
      hasExternalReceiver: false,
      isMembershipFromVisible: false,
      membershipTypes: [],
      membershipType: null,
      newMembership: {
        dateStart: null,
        dateEnd: null,
      },
    }
  },
  validations: {
    payment: {
      amount: { required },
      description: { required },
    },
  },
  computed: {
    descriptionErrors() {
      const errors = []
      if (!this.$v.payment.description.$dirty) return errors
      !this.$v.payment.description.required && errors.push('Description is required.')
      return errors
    },
    amountErrors() {
      const errors = []
      if (!this.$v.payment.amount.$dirty) return errors
      !this.$v.payment.amount.required && errors.push('Amount is required.')
      return errors
    },
  },
  methods: {
    updateMembership(newMembership) {
      this.membership = newMembership
    },
    updateSender(value) {
      this.sender = value
      this.isUserProfileComplete = true
      this.getLinkedResources()
    },
    updateReceiver(value) {
      this.receiver = value
    },
    deleteItem() {
      swal({
        title: 'Are you sure?',
        icon: 'info',
        buttons: {
          cancel: 'Cancel',
          delete: 'Delete',
        },
      }).then((value) => {
        switch (value) {
          case 'delete':
            this.$http
              .delete('v1/tenant/' + this.tenant + '/payments/' + this.payment.id)
              .then(() => {
                this.$store.dispatch('showSuccessMessage', 'Payment has been deleted.')
                this.$router.push('/payments/')
              })
              .catch((data) => {
                if (data.status !== 403 && data.status !== 404 && data.status !== 401 && data.status !== 422) {
                  this.$store.dispatch('showWarningMessage', 'Something went wrong')
                }
              })
            break
          default:
        }
      })
    },
    //
    // New membership creation
    //
    showMembershipForm() {
      //
      // Get membership types
      //
      this.$http
        .get('v1/tenant/' + this.tenant + '/membershipTypes')
        .then((data) => {
          this.membershipTypes = data.data
        })
        .catch(() => {
          console.log('error loading membership types')
        })
      this.isMembershipFromVisible = true
    },
    cancelMembership() {
      this.isMembershipFromVisible = false
    },
    createMembership() {
      this.newMembership.user_id = this.sender.id
      this.$http
        .post('v1/tenant/' + this.tenant + '/memberships', this.newMembership)
        .then((data) => {
          this.memberships.push(data.data)
          this.membership = data.data
          this.isMembershipFromVisible = false
          this.isUserProfileComplete = data.data.is_user_profile_complete
        })
        .catch(() => {})
    },
    //
    // Transaction related
    //
    getLinkedResources() {
      if (this.sender !== null) {
        this.$http
          .get('v1/tenant/' + this.tenant + '/users/' + this.sender.id + '/memberships')
          .then((data) => {
            this.memberships = data.data
          })
          .catch(() => {
            console.log('Unable to fetch user memberships')
          })
        this.showLinkedResources = true
      } else {
        this.showLinkedResources = false
      }
    },
    save() {
      if (!this.isFormValid()) {
        return
      }
      // Internal sender
      if (this.sender !== null && this.hasExternalSender === false) {
        this.payment.sender_id = this.sender.id
        this.payment.from_external = null
      } else {
        this.payment.sender_id = null
      }

      // External receiver
      if (this.receiver !== null && this.hasExternalReceiver === false) {
        this.payment.user_id = this.receiver.id
        this.payment.to_external = null
      } else {
        this.payment.user_id = null
      }

      // Link membership
      if (this.membership !== null) {
        this.payment.membership_id = this.membership.id
      } else {
        this.payment.membership_id = null
      }

      if (typeof this.payment.id === 'undefined') {
        //
        // Create new payment
        //
        this.$http
          .post('v1/tenant/' + this.tenant + '/payments', this.payment)
          .then(() => {
            this.$router.go(-1)
          })
          .catch(this.failedRequest)
      } else {
        //
        // Update existing payment
        //
        this.$http
          .put('v1/tenant/' + this.tenant + '/payments/' + this.payment.id, this.payment)
          .then(() => {
            this.$router.go(-1)
          })
          .catch(() => {
            console.log('Error updating payment')
          })
      }
    },
    fetchData() {
      this.loadingStart()
      var paymentId = this.$route.params.paymentId
      if (paymentId) {
        return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/payments/' + paymentId)])
          .then((response) => {
            this.sender = response[0].data.sender
            this.receiver = response[0].data.user
            this.membership = response[0].data.membership
            if (response[0].data.from_external !== null) {
              this.hasExternalSender = true
            }
            if (response[0].data.to_external !== null) {
              this.hasExternalReceiver = true
            }
            this.payment = response[0].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        this.isCreate = true
        this.loadingCompleted()
      }
    },
  },
}
</script>
