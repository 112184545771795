<template>
  <v-list-item :to="{ name: 'PaymentsView', params: { tenant, paymentId: item.id } }">
    <v-list-item-avatar>
      <v-icon class="grey lighten-3">credit_card</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        ><strong>{{ item.amount }} CZK</strong></v-list-item-title
      >
      <v-list-item-subtitle>
        {{ item.created_at | formatDate }}<br />
        {{ item.sender ? item.sender.fullName : item.from_external }}
        &raquo; {{ item.user ? item.user.fullName : item.to_external }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip bottom class="mx-0"
        ><template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            ripple
            :to="{
              name: 'PaymentsEdit',
              params: { tenant, paymentId: item.id },
            }"
            v-bind="attrs"
            v-on="on"
            v-if="item.isEditable"
          >
            <v-icon class="grey--text text--lighten-1">edit</v-icon>
          </v-btn></template
        >
        <span>Modify</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'payment-list-item',
  props: ['item', 'tenant'],
}
</script>
