var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"pl-3"},[_vm._v("Waiting list")]),_c('v-spacer'),(!_vm.loading && _vm.waitingListItems.length > 1)?_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":"","color":"#EA580C","disabled":_vm.deletingAllWaitingListItems},on:{"click":function($event){return _vm.deleteAllWaitingListItems()}}},[_c('v-icon',[_vm._v("delete_forever")])],1)],1)]}}],null,false,1476878758)},[_c('span',[_vm._v("Remove from all waiting lists")])]):_vm._e()],1),_c('v-divider'),(!_vm.loading)?_c('v-card-text',[_c('v-list',[(_vm.showingGroupSelector)?_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.groups,"label":"Training group","item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"icon":"","ripple":"","color":"#EA580C","disabled":_vm.deletingWaitingListItem},on:{"click":function($event){_vm.showingGroupSelector = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,false,865258948)},[_c('span',[_vm._v("Close")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":"","color":"green","loading":_vm.addingWaitingListItem,"disabled":_vm.group == null || _vm.addingWaitingListItem},on:{"click":function($event){return _vm.addWaitingListItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("done")])],1)]}}],null,false,1551996784)},[_c('span',[_vm._v("Save")])])],1):_vm._e(),_vm._l((_vm.waitingListItems),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
          name: 'GroupsView',
          params: { groupId: item.group_id },
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item.name))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":"","color":"#EA580C","disabled":_vm.deletingWaitingListItem},on:{"click":function($event){$event.preventDefault();return _vm.deleteWaitingListItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("person_remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove from waiting list")])])],1)],1)})],2),(_vm.waitingListItems.length == 0)?_c('p',{staticClass:"text-center"},[_vm._v("No waiting list items")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }