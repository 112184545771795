<template>
  <v-card :loading="loading">
    <v-card-title>
      Training Presence
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-4"
            @click="
              $router.push({
                name: 'TrainingsModifyPresence',
                params: { trainingId: training.id },
              })
            "
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="purple--text">person_add</v-icon>
          </v-btn>
        </template>
        <span>Add free user</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="
              $router.push({
                name: 'SubstituteTraining',
                params: { trainingId: training.id },
              })
            "
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="blue--text">published_with_changes</v-icon>
          </v-btn>
        </template>
        <span>Substitute training</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <p v-if="items.length == 0" class="text-center pt-3">No users yet.</p>
    <v-list three-line v-if="items.length > 0">
      <v-list-item v-for="item in limitedItems" @click="open(item)" :key="item.id">
        <v-list-item-avatar class="mt-6">
          <v-icon :class="trainingCssClass(item)">{{ trainingIcon(item) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><strong>{{ item.firstname }} {{ item.lastname }}</strong></v-list-item-title
          >
          <v-list-item-subtitle>
            <span>{{ item.email }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span :class="membershipStatusCss(item)">{{ membershipStatusText(item) }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- Excused training -->
        <v-list-item-action v-if="item.pivot.type === -1">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon ripple class="mt-3" @click.stop="undoExcusion(item)" v-bind="attrs" v-on="on">
                <v-icon class="orange--text">undo</v-icon>
              </v-btn></template
            >
            <span>Undo excusion</span>
          </v-tooltip>
        </v-list-item-action>
        <!-- Substituted training -->
        <v-list-item-action class="mr-4" v-if="item.pivot.type === 2">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon ripple class="mt-3" @click.stop="undoSubstitute(item)" v-bind="attrs" v-on="on">
                <v-icon class="blue--text">undo</v-icon>
              </v-btn></template
            >
            <span>Undo substitution</span>
          </v-tooltip>
        </v-list-item-action>
        <!-- Substituted training -->
        <v-list-item-action class="mr-4" v-if="item.pivot.type === 10">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon ripple class="mt-3" @click.stop="undoFreeTraining(item)" v-bind="attrs" v-on="on">
                <v-icon class="purple--text">undo</v-icon>
              </v-btn></template
            >
            <span>Undo free training</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action class="mr-4" v-if="false">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon ripple class="mt-3" @click.stop="remove(item)" v-bind="attrs" v-on="on">
                <v-icon class="grey--text text--lighten-1">delete</v-icon>
              </v-btn></template
            >
            <span>Remove from training</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action
          v-if="(item.pivot.type === -5 || item.pivot.type === 0) && hasActiveMembership(item)"
          class="mr-4"
        >
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon class="mt-3" @click.stop="excuse(item)" v-bind="attrs" v-on="on">
                <v-icon class="orange--text">person_remove</v-icon>
              </v-btn></template
            >
            <span>Excuse training</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action v-if="item.pivot.type != 0 && item.pivot.type != -2 && item.pivot.type != -1">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon class="mt-3" @click.stop="markMissing(item)" v-bind="attrs" v-on="on">
                <v-icon class="red--text">close</v-icon>
              </v-btn></template
            >
            <span>Mark as missing</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action v-if="item.pivot.type != 1 && item.pivot.type != -2 && item.pivot.type != -1">
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }">
              <v-btn icon @click.stop="markPresent(item)" v-bind="attrs" class="mt-3" v-on="on">
                <v-icon class="green--text">check</v-icon>
              </v-btn></template
            >
            <span>Mark as present</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div v-if="remaining > 0" class="list-more">
      <button @click="showMore()" class="btn btn--raised btn--light primary">Show more ({{ remaining }} hidden)</button>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
import ErrorMixin from '../mixins/ErrorMixin'

export default {
  props: ['training', 'tenant'],
  mixins: [ErrorMixin],
  data() {
    return {
      showDeleteConfirmation: false,
      deleteConfirmationBody: '',
      deleteItem: {},
      limit: 50,
      items: [],
      loading: true,
    }
  },
  computed: {
    limitedItems() {
      var filteredItems = this.items.filter((item) => {
        return item.pivot.type !== -10
      })
      return filteredItems.slice(0, this.limit)
    },
    remaining() {
      return this.items.length - this.limit
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    hasActiveMembership(item) {
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Active'
        })
      ) {
        return true
      }
      return false
    },
    membershipStatusText(item) {
      let exmembership
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Active'
        })
      ) {
        return 'Active'
      }
      if (
        (exmembership = item.memberships.find((membership) => {
          return membership.status === 'Expiring'
        }))
      ) {
        return `Expiring (${exmembership.free_slots} slots)`
      }
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Future'
        })
      ) {
        return 'Future'
      }
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Suspended'
        })
      ) {
        return 'Suspended'
      }
      return 'Expired'
    },
    membershipStatusCss(item) {
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Active'
        })
      ) {
        return 'green pa-2 white--text'
      }
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Expiring'
        })
      ) {
        return 'orange pa-2 white--text'
      }
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Future'
        })
      ) {
        return 'blue pa-2 white--text'
      }
      if (
        item.memberships.find((membership) => {
          return membership.status === 'Suspended'
        })
      ) {
        return 'yellow pa-2'
      }
      return 'red pa-2 white--text'
    },
    trainingCssClass(item) {
      switch (item.pivot.type) {
        case -1:
          return 'orange white--text'
        case 2:
          return 'blue white--text'
        case 10:
          return 'purple white--text'
        case 1:
          return 'green white--text'
        case -2:
          return 'lime'
        case 20:
          return 'blue white--text'
        case 0:
          return 'red white--text'
        case 15:
          return 'yellow'
        case -5:
          return 'grey white--text'
      }
      return 'grey white--text'
    },
    trainingIcon(item) {
      switch (item.pivot.type) {
        case -1:
          return 'highlight_off'
        case 2:
          return 'check_circle_outline'
        case 10:
          return 'star'
        case 1:
          return 'done'
        case -2:
          return 'redo'
        case 20:
          return 'schedule'
        case 0:
          return 'close'
        case 15:
          return 'attach_money'
        case -5:
          return 'help'
      }
      return 'help'
    },
    async fetchData() {
      this.loading = true
      this.items = (
        await this.$http.get(`v1/tenant/${this.$store.state.tenant}/trainings/${this.$route.params.trainingId}/users`)
      ).data
      this.loading = false
    },
    covidStatusText(item) {
      if (item.covid_ok_until === null) return 'COVID status unknown'

      let validUntil = moment(item.covid_ok_until)
      if (moment().isAfter(validUntil)) {
        return 'COVID expired: ' + validUntil.format('DD. MM. YYYY')
      } else {
        let covidStatus = 'COVID OK'
        if (item.covid_status !== null) {
          covidStatus = item.covid_status + ' OK'
        }
        return covidStatus
      }
    },
    covidStatusClass(item) {
      if (item.covid_ok_until === null) return 'orange--text'
      let validUntil = moment(item.covid_ok_until)
      if (moment().isAfter(validUntil)) {
        return 'red--text'
      }
      return 'green--text'
    },
    edit(item) {
      this.$router.push('/groups/' + this.group.id + '/memberships/' + item.id)
    },
    open(item) {
      this.$router.push('/users/' + item.id)
    },
    showMore() {
      this.limit = this.limit + 10
    },
    markPresent(item) {
      item.pivot.type = 1
      this.$http
        .post(`v1/tenant/${this.tenant}/users/${item.id}/trainings/${this.training.id}/present`)
        .then(() => {})
        .catch(() => {
          console.log('error processing request')
        })
    },
    markMissing(item) {
      item.pivot.type = 0
      var sendData = {
        userId: item.id,
        entryType: 0,
      }
      this.$http
        .post('v1/tenant/' + this.tenant + '/trainings/' + this.training.id + '/users', sendData)
        .then(() => {})
        .catch(() => {
          console.log('error processing request')
        })
    },
    undoSubstitute(item) {
      this.$http
        .delete(`v1/tenant/${this.tenant}/users/${item.id}/trainings/${this.training.id}/substitute`)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Substitution was revoked.')
          this.fetchData()
        })
        .catch(this.handleResponseErrors)
    },
    excuse(item) {
      this.$http
        .post(`v1/tenant/${this.tenant}/users/${item.id}/trainings/${this.training.id}/excuse`)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Training was excused')
          this.fetchData()
        })
        .catch(this.handleResponseErrors)
    },
    undoExcusion(item) {
      this.$http
        .delete(`v1/tenant/${this.tenant}/users/${item.id}/trainings/${this.training.id}/excuse`)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Excused training was revoked.')
          this.fetchData()
        })
        .catch(this.handleResponseErrors)
    },
    undoFreeTraining(item) {
      this.$http
        .delete(`v1/tenant/${this.tenant}/trainings/${this.training.id}/users/${item.id}`)
        .then(() => {
          var index = this.items.indexOf(item)
          this.items.splice(index, 1)
          this.$store.dispatch('showSuccessMessage', 'Free training was removed.')
          this.fetchData()
        })
        .catch(this.handleResponseErrors)
    },
  },
}
</script>
