<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <the-footer />
    <the-snackbar />
  </v-app>
</template>

<script>
import TheFooter from './TheFooter.vue'
import TheSnackbar from './TheSnackbar.vue'

export default {
  components: { TheFooter, TheSnackbar },
}
</script>
