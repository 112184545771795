<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>{{ text.title }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div class="al-l" v-html="text.text"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
    mixins: [WithLoading],
    data() {
        return {
            text: null,
        }
    },
    methods: {
        fetchData() {
            this.loadingStart()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/texts/' +
                        this.$route.params.slug
                )
                .then((data) => {
                    this.text = data.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
