<template>
    <v-list-item
        :to="{ name: 'TrainingsView', params: { tenant, trainingId: item.id } }"
    >
        <v-list-item-avatar>
            <v-icon class="yellow" v-if="item.type == 15">attach_money</v-icon>
            <v-icon class="orange white--text" v-if="item.type == -1"
                >highlight_off</v-icon
            >
            <v-icon class="blue white--text" v-if="item.type == 2"
                >check_circle_outline</v-icon
            >
            <v-icon class="purple white--text" v-if="item.type == 10"
                >star</v-icon
            >
            <v-icon class="green white--text" v-if="item.type == 1"
                >done</v-icon
            >
            <v-icon class="lime" v-if="item.type == -2">redo</v-icon>
            <v-icon class="blue white--text" v-if="item.type == 20"
                >schedule</v-icon
            >
            <v-icon
                class="red white--text"
                v-if="item.type == 0 || item.type === null"
                >close</v-icon
            >
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title
                ><strong>{{
                    item.start | formatDate
                }}</strong></v-list-item-title
            >
            <v-list-item-subtitle
                ><span v-if="item.pattern !== null">{{
                    item.pattern.group.name
                }}</span></v-list-item-subtitle
            >
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'user-training-presence-item',
    props: ['item', 'tenant'],
}
</script>
