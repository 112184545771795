<template>
    <v-container fluid>
        <registrations-statistics :users_count="users.length" v-if="$vuetify.breakpoint.lgAndUp" />
        <v-row>
            <v-col>
                <v-card :loading="loading">
                    <v-card-title>New Users</v-card-title>
                    <v-divider></v-divider>
                    <v-skeleton-loader type="list-item-two-line" v-if="loading"></v-skeleton-loader>
                    <v-card-text v-if="!loading">
                        <v-text-field
                            name="search"
                            label="Search for a user"
                            v-model="searchText"
                            prepend-icon="search"
                            single-line
                        ></v-text-field>
                        <v-select
                            v-model="state"
                            :items="userStates"
                            attach
                            chips
                            label="Filter by user states"
                            item-text="name"
                            item-value="id"
                            multiple
                            @input="fetchUsers"
                        ></v-select>
                        <v-progress-linear v-if="loadingUsers" v-bind:indeterminate="true"></v-progress-linear>
                        <div v-if="!loadingUsers">
                            <p v-if="users.length === 0" class="text-center pt-3">No users matching selected filter.</p>
                            <o-list
                                :loadedItems="users"
                                :filterData="searchText"
                                :filter="searchFilter"
                                :tenant="tenant"
                                listItemComponent="UserRegistrationItem"
                            ></o-list>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <registrations-statistics :users_count="users.length" v-if="$vuetify.breakpoint.mdAndDown" />
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'
import RegistrationsStatistics from './components/RegistrationsStatistics.vue'

export default {
    mixins: [WithLoading],
    components: {
        RegistrationsStatistics,
        OList,
    },
    data() {
        return {
            state: null,
            users: [],
            searchText: null,
            userStates: [],
            showLimit: 20,
            loadingUsers: true,
            cachedSelection: null,
        }
    },
    methods: {
        fetchUsers() {
            this.loadingUsers = true
            this.$http
                .get('v1/tenant/' + this.tenant + '/users/?states=' + this.state.join() + '&order=user_state_change')
                .then((response) => {
                    this.users = response.data
                    this.loadingUsers = false
                })
                .catch(() => {
                    this.loadingUsers = false
                })
        },
        searchFilter(item) {
            let search = new RegExp(this.searchText, 'i')
            return (
                search.test(item.firstname) ||
                search.test(item.lastname) ||
                search.test(item.email) ||
                search.test(item.phone)
            )
        },
        fetchData() {
            this.loadingStart()
            this.$http
                .get('v1/tenant/' + this.tenant + '/userStates')
                .then((response) => {
                    this.userStates = response.data
                    let filteredUserStates = this.userStates.filter((item) => {
                        return item.show_in_list === true
                    })

                    this.state = filteredUserStates.map((item) => {
                        return item.id
                    })
                    this.fetchUsers()
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
