<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4">
          <v-card-title>
            {{ training.title }} at
            {{ training.start | formatTime }}
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="navigateTo('/trainings/' + training.id + '/edit')" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ training.start | formatDateTime }} - {{ training.end | formatTime }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Date</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ training.group_name }}</v-list-item-title>
                    <v-list-item-subtitle>Training Group</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ training.user_name }}</v-list-item-title>
                    <v-list-item-subtitle>Instructor</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ training.capacity }} / {{ training.capacity + training.overbooking_count }} /
                      {{ training.substitution_slots }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Capacity / Overbooked Capacity / Substitution Slots</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ expecting }} / {{ arrived }} </v-list-item-title>
                    <v-list-item-subtitle>Expecting / Arrived</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isPast(training)">
                  <v-list-item-content>
                    <v-list-item-title>{{ freeSlots }}</v-list-item-title>
                    <v-list-item-subtitle>Free Slots</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>

        <training-presence-list :training="training" :tenant="tenant"></training-presence-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrainingPresenceList from '../../components/TrainingPresenceList.vue'
import WithLoading from '../../mixins/WithLoading.js'
import moment from 'moment'

export default {
  mixins: [WithLoading],
  components: { TrainingPresenceList },
  data() {
    return {
      training: {},
    }
  },
  computed: {
    arrived() {
      return this.training.memberCounts[1] !== undefined ? this.training.memberCounts[1] : 0
    },
    expecting() {
      const present = this.training.memberCounts[1] !== undefined ? this.training.memberCounts[1] : 0
      const neutral = this.training.memberCounts[-5] !== undefined ? this.training.memberCounts[-5] : 0
      const first = this.training.memberCounts[10] !== undefined ? this.training.memberCounts[10] : 0
      const substituted = this.training.memberCounts[2] !== undefined ? this.training.memberCounts[2] : 0
      return present + neutral + first + substituted
    },
    freeSlots() {
      const present = this.training.memberCounts[1] !== undefined ? this.training.memberCounts[1] : 0
      const neutral = this.training.memberCounts[-5] !== undefined ? this.training.memberCounts[-5] : 0
      const first = this.training.memberCounts[10] !== undefined ? this.training.memberCounts[10] : 0
      const substituted = this.training.memberCounts[2] !== undefined ? this.training.memberCounts[2] : 0

      let freeSlots = this.training.capacity - neutral - present - first
      if (freeSlots < 0) {
        freeSlots = 0
      }

      freeSlots = freeSlots + this.training.substitution_slots - substituted

      if (freeSlots < 0) {
        freeSlots = 0
      }

      return freeSlots
    },
  },
  methods: {
    isPast(training) {
      return moment(training.start) >= moment()
    },
    fetchData() {
      this.loadingStart()
      var trainingId = this.$route.params.trainingId
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/trainings/' + trainingId)])
        .then((response) => {
          this.training = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
