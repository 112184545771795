var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('span',{staticClass:"pl-3"},[_vm._v("Waiting List")]),_c('v-spacer'),_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.$router.push({
              name: 'GroupsReservesCreate',
              params: { groupId: _vm.groupId },
            })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("Add user to waiting list")])])],1),_c('v-divider'),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):_vm._e(),(!_vm.isLoading)?_c('v-card-text',[(_vm.waitingListItems.length === 0)?_c('span',[_vm._v("Nobody is in waiting list.")]):_vm._e(),(_vm.waitingListItems.length > 0)?_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.waitingListItems),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
          name: 'UsersView',
          params: { userId: item.user.id },
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.user.email)+" ")])],1),_c('v-list-item-action',[_c('v-tooltip',{staticClass:"mx-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("person_remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove from waiting list")])])],1)],1)}),1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }