var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{attrs:{"to":{
        name: 'EmailTemplatesView',
        params: { tenant: _vm.tenant, templateId: _vm.item.id },
    }}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-3"},[_vm._v("drafts")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(_vm.item.name))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":"","to":{
                        name: 'EmailTemplatesEdit',
                        params: { tenant: _vm.tenant, templateId: _vm.item.id },
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text text--lighten-1"},[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Modify")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }