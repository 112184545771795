<template>
    <v-list two-line>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ item.salutation }}</v-list-item-title>
                <v-list-item-subtitle>Salutation</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    props: ['item'],
}
</script>
