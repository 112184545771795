<template>
    <v-row>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Active Users</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="!loading">
                    <div class="display-2 mt-10 mb-10">
                        {{ active_users_count }}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Active Users (by month)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[monthlyDataAverage, monthlyDataPeak]"
                        :labels="monthlyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card height="100%" :loading="loading">
                <v-card-title>Active Users (last 90 days)</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!loading">
                    <z-line-chart
                        :datasets="[dailyDataAverage]"
                        :labels="dailyLabels"
                    ></z-line-chart>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ZLineChart from '../../../components/charts/ZLineChart.vue'
import StatsService from '../../../services/StatsService'

export default {
    components: { ZLineChart },
    props: {
        active_users_count: {
            default: 0,
        },
    },
    data() {
        return {
            loading: true,
            monthlyDataAverage: null,
            monthlyDataPeak: null,
            monthlyLabels: [],
            dailyDataAverage: null,
            dailyLabels: [],
        }
    },
    mounted() {
        StatsService.load(this, this.drawCharts)
    },
    methods: {
        drawCharts(response) {
            this.loading = true

            let rawMonthlyStats = response[0].data
            let rawDailyStats = response[1].data

            this.monthlyDataAverage = {
                label: 'Average active users',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            this.monthlyDataPeak = {
                label: 'Peak active users',
                data: [],
                backgroundColor: '#FF5252',
                borderColor: '#FF5252',
                fill: false,
                showLine: false,
                pointStyle: 'triangle',
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawMonthlyStats.map((stat) => {
                this.monthlyLabels.push(stat.f_date)
                this.monthlyDataAverage.data.push(stat.avg_active_users)
                this.monthlyDataPeak.data.push(stat.max_active_users)
            })

            this.dailyDataAverage = {
                label: 'Average active users',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: true,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawDailyStats.map((stat) => {
                this.dailyLabels.push(stat.f_date)
                this.dailyDataAverage.data.push(stat.active_users)
            })

            this.loading = false
        },
    },
}
</script>
