<template>
    <v-card class="mb-4" :loading="isLoading">
        <v-card-title>
            Trainings
            <v-spacer />
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mr-3"
                        icon
                        color="#64748B"
                        @click="
                            $router.push({
                                name: 'Substitutions',
                                params: { userId: userId },
                            })
                        "
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>visibility</v-icon>
                    </v-btn>
                </template>
                <span>User's view</span>
            </v-tooltip>
            <v-tooltip bottom class="mx-0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        @click="
                            $router.push({
                                name: 'FirstTraining',
                                params: { userId: userId },
                            })
                        "
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="purple--text">person_add</v-icon>
                    </v-btn>
                </template>
                <span>Assign first training</span>
            </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-skeleton-loader type="list-item-two-line" v-if="isLoading"></v-skeleton-loader>
        <v-card-text v-if="!isLoading">
            <p class="text-center pt-3" v-if="trainings.length == 0">No trainings</p>
            <v-list two-line v-if="trainings.length > 0">
                <v-list-item
                    :to="{
                        name: 'TrainingsView',
                        params: { tenant: tenantId, trainingId: item.id },
                    }"
                    v-for="item in trainings"
                    :key="item.id"
                >
                    <v-list-item-avatar>
                        <v-icon :class="trainingCssClass(item)">{{ trainingIcon(item) }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>{{ item.start | formatDate }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item.start | formatTime }} - {{ item.end | formatTime }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-pagination v-if="trainings.length > 0" v-model="page" :length="lastPage"></v-pagination>
        </v-card-text>
    </v-card>
</template>

<script>
import ErrorMixin from '../../../mixins/ErrorMixin'

export default {
    mixins: [ErrorMixin],
    props: ['tenantId', 'userId'],
    data() {
        return {
            trainings: [],
            page: 1,
            lastPage: 1,
            isLoading: true,
        }
    },
    mounted() {
        this.fetchData()
    },
    watch: {
        page: 'fetchData',
    },
    methods: {
        trainingCssClass(item) {
            switch (item.pivot.type) {
                case -1:
                    return 'orange white--text'
                case 2:
                    return 'blue white--text'
                case 10:
                    return 'purple white--text'
                case 1:
                    return 'green white--text'
                case -2:
                    return 'lime'
                case 20:
                    return 'blue white--text'
                case 0:
                    return 'red white--text'
                case 15:
                    return 'yellow'
                case -5:
                    return 'grey white--text'
            }
            return 'grey white--text'
        },
        trainingIcon(item) {
            switch (item.pivot.type) {
                case -1:
                    return 'highlight_off'
                case 2:
                    return 'check_circle_outline'
                case 10:
                    return 'star'
                case 1:
                    return 'done'
                case -2:
                    return 'redo'
                case 20:
                    return 'schedule'
                case 0:
                    return 'close'
                case 15:
                    return 'attach_money'
                case -5:
                    return 'help'
            }
            return 'help'
        },
        fetchData() {
            this.isLoading = true
            this.$http
                .get(`v1/tenant/${this.tenantId}/users/${this.userId}/trainings?page=${this.page}`)
                .then((response) => {
                    this.trainings = response.data.data.filter((item) => item.pivot.type !== -10)
                    this.lastPage = response.data.last_page
                    this.isLoading = false
                })
                .catch(this.handleResponseErrors)
        },
    },
}
</script>
