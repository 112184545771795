<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            <span v-if="isCreate">Create new training</span>
            <span v-if="!isCreate">{{ training.title }} #{{ training.id }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'General'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-radio-group v-model="createMethod" :mandatory="true" v-if="isCreate">
                  <v-radio label="Create single training" value="single"></v-radio>
                  <v-radio label="Generate multiple trainings" value="multiple"></v-radio>
                </v-radio-group>

                <v-text-field name="title" label="Title" id="title" v-model="training.title"></v-text-field>

                <v-autocomplete
                  v-model="training.instructor_id"
                  :items="instructors"
                  :loading="instructorLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  item-text="selectText"
                  item-value="id"
                  label="Instructor"
                  placeholder="Start typing to Search"
                  @input="$v.training.instructor_id.$touch()"
                  :error-messages="instructorErrors"
                ></v-autocomplete>

                <div v-if="createMethod === 'single'">
                  <vuetify-date-picker
                    :initialDate="date"
                    v-on:changed="updateDate"
                    label="Training date"
                  ></vuetify-date-picker>
                  <v-text-field name="start" label="Start (time)" id="start" v-model="start"></v-text-field>
                  <v-text-field name="end" label="End (time)" id="end" v-model="end"></v-text-field>
                </div>

                <div v-if="createMethod === 'multiple'">
                  <vuetify-date-picker
                    :initialDate="rangeStart"
                    v-on:changed="updateRangeStart"
                    label="Range Start"
                  ></vuetify-date-picker>
                  <vuetify-date-picker
                    :initialDate="rangeEnd"
                    v-on:changed="updateRangeEnd"
                    label="Range End"
                  ></vuetify-date-picker>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Capacity'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-text-field
                  name="capacity"
                  label="Capacity"
                  id="capacity"
                  v-model="training.capacity"
                  @input="$v.training.capacity.$touch()"
                  :error-messages="capacityErrors"
                  tabindex="6"
                ></v-text-field>
                <v-text-field
                  name="overbooking"
                  label="Overbooking"
                  id="overbooking_count"
                  hint="Number of people on top of standard capacity"
                  v-model="training.overbooking_count"
                  @input="$v.training.overbooking_count.$touch()"
                  :error-messages="overbookingCountErrors"
                  persistent-hint
                ></v-text-field>
                <v-text-field
                  name="substitution_slots"
                  label="Substitution Slots"
                  id="substitution_slots"
                  v-model="training.substitution_slots"
                  hint="Number of substitution slots always available"
                  persistent-hint
                  @input="$v.training.substitution_slots.$touch()"
                  :error-messages="substitutionSlotsErrors"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-btn light @click="showAreYouSure = true" v-if="!isCreate">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save()" :loading="sending">{{ submitText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <are-you-sure :show="showAreYouSure" @decline="showAreYouSure = false" @confirm="doDelete()"></are-you-sure>
  </v-container>
</template>

<script>
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'

import moment from 'moment'
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'
import positiveInteger from '../../validators/PositiveInteger.js'
import ErrorMixin from '../../mixins/ErrorMixin'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'

export default {
  mixins: [WithLoading, WithSubmitting, ErrorMixin],
  components: { VuetifyDatePicker, AreYouSure },
  data() {
    return {
      showAreYouSure: false,
      training: {
        instructor_id: null,
      },
      instructor: null,
      trainingPatternId: null,
      isCreate: false,
      createMethod: 'single',
      rangeStart: null,
      rangeEnd: null,
      date: null,
      start: null,
      end: null,
      instructors: [],
      search: null,
      instructorLoading: false,
    }
  },
  validations: {
    training: {
      instructor_id: { required },
      capacity: { required, positiveInteger },
      overbooking_count: { required, positiveInteger },
      substitution_slots: { required, positiveInteger },
    },
  },
  computed: {
    instructorErrors() {
      const errors = []
      if (!this.$v.training.instructor_id.$dirty) return errors
      !this.$v.training.instructor_id.required && errors.push('Instructor is required.')
      return errors
    },
    capacityErrors() {
      const errors = []
      if (!this.$v.training.capacity.$dirty) return errors
      !this.$v.training.capacity.required && errors.push('Capacity is required.')
      !this.$v.training.capacity.positiveInteger && errors.push('Capacity must be a positive number.')
      return errors
    },
    overbookingCountErrors() {
      const errors = []
      if (!this.$v.training.overbooking_count.$dirty) return errors
      !this.$v.training.overbooking_count.required && errors.push('Overbooking is required.')
      !this.$v.training.overbooking_count.positiveInteger && errors.push('Overbooking must be a positive number.')
      return errors
    },
    substitutionSlotsErrors() {
      const errors = []
      if (!this.$v.training.substitution_slots.$dirty) return errors
      !this.$v.training.substitution_slots.required && errors.push('Substitution slots is required.')
      !this.$v.training.substitution_slots.positiveInteger &&
        errors.push('Substitution slots must be a positive number.')
      return errors
    },
  },
  watch: {
    search(val) {
      val && this.querySelections(val)
    },
  },
  methods: {
    querySelections(query) {
      if (query.length !== 0) {
        this.instructorLoading = false
        this.$http
          .get('v1/tenant/' + this.tenant + '/users/?filter=instructors&search=' + query)
          .then((response) => {
            this.instructors = response.data
            this.instructorLoading = false
          })
          .catch(() => {
            console.log('Error getting users')
          })
      }
    },
    updateUser(user) {
      this.training.instructor_id = user.id
    },
    updateDate(datetime) {
      this.date = datetime
    },
    updateStart(datetime) {
      this.training.start = datetime
    },
    updateEnd(datetime) {
      this.training.end = datetime
    },
    updateRangeStart(date) {
      this.rangeStart = date
    },
    updateRangeEnd(date) {
      this.rangeEnd = date
    },
    doDelete() {
      this.$http
        .delete('v1/tenant/' + this.tenant + '/trainings/' + this.training.id)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Training has been deleted.')
          this.$router.push({ name: 'Trainings' })
        })
        .catch(this.handleResponseErrors)
    },
    save() {
      if (!this.isFormValid()) {
        return
      }
      if (typeof this.training.id === 'undefined') {
        //
        // Create Single Instance
        //
        if (this.createMethod === 'single') {
          this.submitStarted()

          this.training.start = this.date + ' ' + this.start + ':00'
          this.training.end = this.date + ' ' + this.end + ':00'

          this.$http
            .post(
              'v1/tenant/' + this.tenant + '/training-patterns/' + this.trainingPatternId + '/trainings/',
              this.training
            )
            .then(() => {
              this.submitCompleted()
              this.$router.go(-1)
            })
            .catch((error) => {
              this.submitFailed(error.response)
            })
        }
        //
        // Generate multiple trainings
        //
        if (this.createMethod === 'multiple') {
          this.submitStarted()
          this.$http
            .get('v1/tenant/' + this.tenant + '/trainingPatterns/' + this.trainingPatternId)
            .then((response) => {
              let trainingPattern = response.data

              let start = moment(this.rangeStart)
              let end = moment(this.rangeEnd)

              let promises = []

              while (start.isBefore(end)) {
                if (parseInt(trainingPattern.weekday) === start.day()) {
                  let training = {
                    start: start
                      .clone()
                      .set('hour', trainingPattern.timeStart.substr(0, 2))
                      .set('minute', trainingPattern.timeStart.substr(3, 2))
                      .format('YYYY-MM-DD HH:mm:00'),
                    end: start
                      .clone()
                      .set('hour', trainingPattern.timeEnd.substr(0, 2))
                      .set('minute', trainingPattern.timeEnd.substr(3, 2))
                      .format('YYYY-MM-DD HH:mm:00'),
                    instructor_id: this.training.instructor_id,
                    title: this.training.title,
                    capacity: this.training.capacity,
                    overbooking_count: this.training.overbooking_count,
                    substitution_slots: this.training.substitution_slots,
                  }
                  promises.push(
                    this.$http
                      .post(
                        'v1/tenant/' + this.tenant + '/training-patterns/' + this.trainingPatternId + '/trainings/',
                        training
                      )
                      .then(() => {})
                      .catch(this.failedSubmit)
                  )
                }
                start.add(1, 'days')
              }
              Promise.all(promises).then(() => {
                this.submitCompleted()
                this.$router.go(-1)
              })
            })
            .catch(() => {
              console.log('Unable to fetch training pattern details')
            })
        }
      } else {
        this.submitStarted()
        //
        // Update
        //

        this.training.start = this.date + ' ' + this.start + ':00'
        this.training.end = this.date + ' ' + this.end + ':00'

        this.$http
          .put('v1/tenant/' + this.tenant + '/trainings/' + this.training.id, this.training)
          .then(() => {
            this.submitCompleted()
            this.$store.dispatch('showSuccessMessage', 'Training has been updated.')
            this.$router.go(-1)
          })
          .catch(this.failedSubmit)
      }
    },
    fetchData() {
      this.loadingStart()
      if (!this.$route.params.trainingId) {
        this.isCreate = true
        this.trainingPatternId = this.$route.params.trainingPatternId
        this.loadingCompleted()
      } else {
        return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/trainings/' + this.$route.params.trainingId)])
          .then((response) => {
            this.instructors.push(response[0].data.instructor)
            this.training = response[0].data
            this.date = moment(this.training.start).format('YYYY-MM-DD')
            this.start = moment(this.training.start).format('HH:mm')
            this.end = moment(this.training.end).format('HH:mm')
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      }
    },
  },
}
</script>
