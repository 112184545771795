<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        prepend-icon="event"
        v-bind="attrs"
        :disabled="disabled"
        @blur="date = parseDate(dateFormatted)"
        :error-messages="errorMessages"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      no-title
      :allowedDates="allowedDates"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
//BUG: Sometimes you need to click twice to open the picker window
import moment from 'moment'
export default {
  props: {
    initialDate: String,
    label: String,
    minDate: String,
    maxDate: String,
    'error-messages': String,
    disabled: { type: Boolean, default: false },
  },
  data: (vm) => ({
    date: vm.initialDate,
    dateFormatted: vm.formatDate(vm.initialDate),
    menu: false,
    allowedDates: (date) => {
      if (vm.minDate && vm.maxDate) {
        return moment(date).isAfter(moment(vm.minDate)) && moment(date).isBefore(moment(vm.maxDate))
      }
      if (vm.minDate) {
        return moment(date).isAfter(moment(vm.minDate))
      }
      if (vm.maxDate) {
        return moment(date).isBefore(moment(vm.maxDate))
      }
      return true
    },
  }),
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
      this.$emit('changed', this.date)
    },
    initialDate(val) {
      this.date = val
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}. ${month}. ${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year.trim()}-${month.trim().padStart(2, '0')}-${day.trim().padStart(2, '0')}`
    },
  },
}
</script>
