<template>
    <Multiselect
        v-model="selectedMembership"
        :options="options"
        :placeholder="placeholder"
        :multiple="false"
        :show-labels="false"
        :custom-label="membershipFormatting"
        track-by="id"
    >
    </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
export default {
    components: { Multiselect },
    props: ['selected', 'update', 'placeholder', 'options'],
    methods: {
        membershipFormatting({ dateStart, dateEnd }) {
            var start = moment(dateStart).format('DD. MM. YYYY')
            var end = moment(dateEnd).format('DD. MM. YYYY')
            return `${start} — ${end}`
        },
        sendUpdate() {
            this.$emit('changed', this.selectedMembership)
        },
    },
    data() {
        return {
            selectedMembership: this.selected,
        }
    },
    watch: {
        selectedMembership: 'sendUpdate',
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
