<template>
  <v-card class="mb-4" :loading="isLoading">
    <v-card-title>
      <span class="pl-3">Waiting List</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom class="mx-0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="
              $router.push({
                name: 'GroupsReservesCreate',
                params: { groupId: groupId },
              })
            "
            v-bind="attrs"
            v-on="on"
            color="green"
            class="mr-4"
          >
            <v-icon>person_add</v-icon>
          </v-btn>
        </template>
        <span>Add user to waiting list</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-skeleton-loader type="list-item-three-line" v-if="isLoading"></v-skeleton-loader>
    <v-card-text v-if="!isLoading">
      <span v-if="waitingListItems.length === 0">Nobody is in waiting list.</span>
      <v-list two-line v-if="waitingListItems.length > 0">
        <v-list-item
          v-for="item in waitingListItems"
          :key="item.id"
          :to="{
            name: 'UsersView',
            params: { userId: item.user.id },
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              ><strong>{{ item.user.firstname }} {{ item.user.lastname }}</strong></v-list-item-title
            >
            <v-list-item-subtitle>
              {{ item.user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom class="mx-0"
              ><template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.stop="removeUser(item)" color="red" v-bind="attrs" v-on="on">
                  <v-icon>person_remove</v-icon>
                </v-btn></template
              >
              <span>Remove from waiting list</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ErrorMixin from '../../mixins/ErrorMixin'
export default {
  props: ['tenantId', 'groupId'],
  mixins: [ErrorMixin],
  data() {
    return {
      waitingListItems: [],
      isLoading: true,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    removeUser(waitingListItem) {
      this.$http
        .delete(`v1/tenant/${this.tenantId}/groupReserves/${waitingListItem.id}`)
        .then(() => {
          this.waitingListItems.splice(this.waitingListItems.indexOf(waitingListItem), 1)
          this.$store.dispatch('showSuccessMessage', 'User has been removed from waiting list.')
        })
        .catch(this.handleResponseErrors)
    },
    fetchData() {
      this.isLoading = true
      this.$http
        .get('v1/tenant/' + this.tenantId + '/groups/' + this.groupId + '/reserves')
        .then((response) => {
          this.waitingListItems = response.data
          this.isLoading = false
        })
        .catch(this.handleResponseErrors)
    },
  },
}
</script>
