<template>
    <v-list-item
        :to="{ name: 'GroupsView', params: { tenant, groupId: item.id } }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">date_range</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong>{{ item.title }} - {{ item.humanWeekday }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle
                >{{ item.timeStart.substring(0, 5) }} -
                {{ item.timeEnd.substring(0, 5) }}</v-list-item-subtitle
            >
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        @click.stop="sendDelete(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="grey--text text--lighten-1"
                            >delete</v-icon
                        >
                    </v-btn>
                </template>
                <span>Remove from group</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'user-training-pattern-item',
    props: ['item', 'tenant'],
    methods: {
        sendDelete(item) {
            this.$emit('delete', item)
        },
    },
}
</script>
