<template>
    <v-container fluid>
        <v-progress-linear
            :indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg4 md6 offset-lg4 offset-md3>
                <v-card>
                    <v-card-title>Login</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            name="email"
                            label="Email"
                            id="email"
                            v-model="credentials.username"
                            @keyup.enter="loginUser"
                        ></v-text-field>
                        <div
                            class="red--text"
                            v-if="$v.credentials.username.$error"
                        >
                            <span
                                class="form-messages__item"
                                v-if="!$v.credentials.username.required"
                                >Email is required</span
                            >
                            <br />
                            <span
                                class="form-messages__item"
                                v-if="!$v.credentials.username.email"
                                >Email format is invalid</span
                            >
                        </div>
                        <v-text-field
                            name="password"
                            label="Password"
                            id="password"
                            v-model="credentials.password"
                            type="password"
                            @keyup.enter="loginUser"
                        ></v-text-field>
                        <div
                            class="red--text"
                            v-if="$v.credentials.password.$error"
                        >
                            <span
                                class="form-messages__item"
                                v-if="!$v.credentials.password.required"
                                >Password is required</span
                            >
                        </div>
                        <v-select
                            label="Gym"
                            v-model="tenant"
                            item-text="name"
                            return-object
                            :items="tenants"
                        ></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click.prevent="loginUser"
                            :loading="submitting"
                            :disabled="submitting"
                            >Login</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            loading: true,
            submitting: false,
            tenants: [],
            tenant: {},
            credentials: {
                username: null,
                password: null,
            },
        }
    },
    validations: {
        credentials: {
            username: { required, email },
            password: { required },
        },
    },
    created() {
        this.$nextTick(() => {
            this.fetchData()
        })
    },
    watch: {
        $route: 'fetchData',
    },
    methods: {
        fetchData() {
            this.$http
                .get('v1/public/gyms')
                .then((response) => {
                    this.loading = false
                    this.tenants = response.data
                    this.tenant = response.data[0]
                })
                .catch(() => {
                    this.$store.dispatch(
                        'showWarningMessage',
                        'Unable to load available gyms'
                    )
                })
        },
        loginUser() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            this.submitting = true
            this.$http
                .post('v1/public/login', this.credentials)
                .then((response) => {
                    if (
                        response.data.tenants &&
                        response.data.tenants.find(
                            (tenant) => tenant.id === this.tenant.id
                        )
                    ) {
                        this.$store.dispatch(
                            'setToken',
                            response.data.access_token
                        )
                        this.$store.dispatch('setTenant', this.tenant.id)
                        this.$store.dispatch(
                            'setPermissions',
                            response.data.tenants
                        )
                        this.$router.push({ name: 'Dashboard' })
                    } else {
                        this.$store.dispatch(
                            'showWarningMessage',
                            "You don't have permissions to log into this gym."
                        )
                    }
                })
                .catch(() => {
                    this.$store.dispatch(
                        'showWarningMessage',
                        'Invalid email or password.'
                    )
                })
                .finally(() => {
                    this.submitting = false
                })
        },
    },
}
</script>
