export default {
    methods: {
        handleResponseErrors(error) {
            if (error.response.data && error.response.data.message) {
                this.$store.dispatch(
                    'showWarningMessage',
                    `${error.response.data.message}`
                )
            } else {
                this.$store.dispatch(
                    'showWarningMessage',
                    `Something went wrong. Error: ${error.response.status}`
                )
            }
        },
    },
}
