<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>
                        <span v-if="!state.id">Create new user state</span>
                        <span v-if="state.id"
                            >Edit user state {{ state.name }}</span
                        >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            name="name"
                            label="Name"
                            id="name"
                            v-model="state.name"
                        ></v-text-field>
                        <v-select
                            label="Color"
                            v-model="state.highlight_color"
                            :items="colors"
                        ></v-select>
                        <v-checkbox
                            label="Show this state in registrations list"
                            v-model="state.show_in_list"
                            light
                        ></v-checkbox>

                        <h3>Notification when entering this state</h3>

                        <v-select
                            label="Email for user"
                            v-model="state.in_user_email_template_id"
                            item-text="name"
                            item-value="id"
                            :items="emailTemplates"
                            clearable
                        ></v-select>
                        <v-select
                            label="Email for user"
                            v-model="state.in_admin_email_template_id"
                            item-text="name"
                            item-value="id"
                            :items="emailTemplates"
                            clearable
                        ></v-select>

                        <h3 class="pb-4">
                            Notification when staying in this state
                        </h3>

                        <v-select
                            label="Email for user"
                            v-model="state.no_change_user_email_template_id"
                            item-text="name"
                            item-value="id"
                            :items="emailTemplates"
                            clearable
                        ></v-select>
                        <v-select
                            label="Email for admins"
                            v-model="state.no_change_admin_email_template_id"
                            item-text="name"
                            item-value="id"
                            :items="emailTemplates"
                            clearable
                        ></v-select>
                        <v-text-field
                            name="days_without_change"
                            label="Number of days without change"
                            id="days_without_change"
                            v-model="state.days_without_change"
                        ></v-text-field>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3 px-2">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click.prevent="showAreYouSure = true"
                            v-if="state.id"
                            >Delete</v-btn
                        >
                        <v-btn color="primary" @click.prevent="save()"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <are-you-sure
            :show="showAreYouSure"
            @decline="showAreYouSure = false"
            @confirm="doDelete()"
        ></are-you-sure>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'

export default {
    mixins: [WithLoading, ErrorMixin],
    components: { AreYouSure },
    data() {
        return {
            showAreYouSure: false,
            colors: [
                'red',
                'pink',
                'purple',
                'deep-purple',
                'indigo',
                'blue',
                'light-blue',
                'cyan',
                'teal',
                'green',
                'light-green',
                'lime',
                'yellow',
                'amber',
                'orange',
                'deep-orange',
                'brown',
                'blue-grey',
                'grey',
            ],
            state: {},
            emailTemplates: [],
        }
    },
    methods: {
        doDelete() {
            this.$http
                .delete(
                    'v1/tenant/' + this.tenant + '/userStates/' + this.state.id
                )
                .then(() => {
                    this.$store.dispatch(
                        'showSuccessMessage',
                        'User state has been deleted.'
                    )
                    this.$router.push({ name: 'UserStates' })
                })
                .catch(this.handleResponseErrors)
        },
        save() {
            if (typeof this.state.id === 'undefined') {
                //
                // Create new
                //
                this.$http
                    .post(
                        'v1/tenant/' + this.tenant + '/userStates',
                        this.state
                    )
                    .then(() => {
                        this.$store.dispatch(
                            'showSuccessMessage',
                            'User state has been created.'
                        )
                        this.$router.go(-1)
                    })
                    .catch(() => {
                        console.log('Error creating payment')
                    })
            } else {
                //
                // Update existing
                //
                this.$http
                    .put(
                        'v1/tenant/' +
                            this.tenant +
                            '/userStates/' +
                            this.state.id,
                        this.state
                    )
                    .then(() => {
                        this.$store.dispatch(
                            'showSuccessMessage',
                            'User state has been changed.'
                        )
                        this.$router.go(-1)
                    })
                    .catch(() => {
                        console.log('Error updating payment')
                    })
            }
        },
        fetchData() {
            this.loadingStart()
            var id = this.$route.params.userStateId
            if (id) {
                return Promise.all([
                    this.$http.get(
                        'v1/tenant/' + this.tenant + '/userStates/' + id
                    ),
                    this.$http.get(
                        'v1/tenant/' +
                            this.tenant +
                            '/emailTemplates?category=states'
                    ),
                ])
                    .then((response) => {
                        this.inUserEmail = response[0].data.in_user_email
                        this.inAdminEmail = response[0].data.in_admin_email
                        this.noChangeUserEmail =
                            response[0].data.no_change_user_email
                        this.noChangeAdminEmail =
                            response[0].data.no_change_admin_email

                        this.state = response[0].data
                        this.emailTemplates = response[1].data
                        this.loadingCompleted()
                    })
                    .catch((response) => {
                        this.loadingFailed(response)
                    })
            } else {
                return Promise.all([
                    this.$http.get(
                        'v1/tenant/' +
                            this.tenant +
                            '/emailTemplates?category=states'
                    ),
                ])
                    .then((response) => {
                        this.emailTemplates = response[0].data
                        this.loadingCompleted()
                    })
                    .catch((response) => {
                        this.loadingFailed(response)
                    })
            }
        },
    },
}
</script>
