<template>
    <v-container fluid>
        <gym-statistics
            :groups_count="groupReports.length"
            v-if="$vuetify.breakpoint.lgAndUp"
        />
        <v-row>
            <v-col>
                <v-card :loading="loading">
                    <v-card-title>Group Report</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="!loading">
                        <vuetify-date-picker
                            label="From"
                            :initialDate="rangeStart"
                            v-on:changed="updateStart"
                        ></vuetify-date-picker>
                        <vuetify-date-picker
                            label="To"
                            :initialDate="rangeEnd"
                            v-on:changed="updateEnd"
                        ></vuetify-date-picker>
                        <v-divider></v-divider>
                        <o-list
                            :loadedItems="groupReports"
                            :tenant="tenant"
                            listItemComponent="GroupReportItem"
                        ></o-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <gym-statistics
            :groups_count="groupReports.length"
            v-if="$vuetify.breakpoint.mdAndDown"
        />
    </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'
import GymStatistics from './components/GymStatistics.vue'

export default {
    mixins: [WithLoading],
    components: {
        OList,
        VuetifyDatePicker,
        GymStatistics,
    },
    data() {
        return {
            groupReports: [],
            rangeStart: null,
            rangeEnd: null,
        }
    },
    methods: {
        updateStart(value) {
            this.rangeStart = value
            sessionStorage.setItem('group-start', value)
            this.fetchData()
        },
        updateEnd(value) {
            this.rangeEnd = value
            sessionStorage.setItem('group-end', value)
            this.fetchData()
        },
        openGroup(id) {
            this.$router.push('/groups/' + id)
        },
        setDateRange() {
            this.rangeStart = sessionStorage.getItem('group-start')
            if (this.rangeStart === null) {
                this.rangeStart = moment()
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD')
                sessionStorage.setItem('group-start', this.rangeStart)
            }
            this.rangeEnd = sessionStorage.getItem('group-end')
            if (this.rangeEnd === null) {
                this.rangeEnd = moment().format('YYYY-MM-DD')
                sessionStorage.setItem('group-end', this.rangeEnd)
            }
        },
        fetchData() {
            this.loadingStart()
            this.setDateRange()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/report/groups/users?rangeStart=' +
                        this.rangeStart +
                        '&rangeEnd=' +
                        this.rangeEnd
                )
                .then((response) => {
                    this.groupReports = response.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
