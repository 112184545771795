<template>
    <v-list-item
        :to="{
            name: 'EmailTemplatesView',
            params: { tenant, templateId: item.id },
        }"
    >
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">drafts</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <strong>{{ item.name }}</strong>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        ripple
                        :to="{
                            name: 'EmailTemplatesEdit',
                            params: { tenant, templateId: item.id },
                        }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="grey--text text--lighten-1">edit</v-icon>
                    </v-btn>
                </template>
                <span>Modify</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'email-templates-list-item',
    props: ['item', 'tenant'],
}
</script>
