<template>
    <v-container fluid>
        <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>
                        <span>Add user to a training</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-select
                            label="Entry type"
                            v-model="presence.entryType"
                            item-text="name"
                            item-value="id"
                            :items="presenceOptions"
                        ></v-select>
                        <user-autocomplete @change="updateUser"></user-autocomplete>
                        <div v-if="(presence.entryType == 90 || presence.entryType == 20) && presence.userId">
                            <div class="form-group">
                                <label for="membershipType" class="form-group__label">Active Memberships</label>
                                <membership-select
                                    :selected="membership"
                                    :options="activeMemberships"
                                    placeholder="Select membership"
                                    v-on:changed="updateMembership"
                                ></membership-select>
                            </div>
                            <div class="form-group">
                                <label for="membershipType" class="form-group__label" v-if="membership !== null"
                                    >Source Training</label
                                >
                                <training-select
                                    :selected="sourceTraining"
                                    :options="availableTrainings"
                                    placeholder="Select training"
                                    v-on:changed="updateSourceTraining"
                                    v-if="membership !== null"
                                ></training-select>
                            </div>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="save()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TrainingSelect from '../../components/selects/TrainingSelect.vue'
import MembershipSelect from '../../components/selects/MembershipSelect.vue'
import WithLoading from '../../mixins/WithLoading.js'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'

export default {
    mixins: [WithLoading],
    components: {
        MembershipSelect,
        TrainingSelect,
        UserAutocomplete,
    },
    data: () => ({
        training: {},
        missedTrainings: [],
        futureTrainings: [],
        availableTrainings: [],
        sourceTraining: null,
        membership: null,
        activeMemberships: [],
        presence: {
            entryType: 10,
            userId: null,
            sourceTrainingId: null,
        },
        presenceOptions: [
            { id: 10, name: 'Free user' },
            { id: -5, name: 'Other (this is not a substitution)' },
        ],
    }),
    methods: {
        updatePresenceType(item) {
            this.presence.entryType = item.id
        },
        updateMembership(newMembership) {
            this.membership = newMembership
            if (this.membership !== null) {
                this.$http
                    .get(
                        'v1/tenant/' + this.tenant + '/memberships/' + this.membership.id + '/trainingsForSubstitution'
                    )
                    .then((data) => {
                        this.missedTrainings = data.data.missedTrainings
                        this.futureTrainings = data.data.futureTrainings
                        this.availableTrainings = data.data.missedTrainings.concat(data.data.futureTrainings)
                    })
                    .catch((response) => {
                        this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
                    })
            }
        },
        updateSourceTraining(newSourceTraining) {
            this.sourceTraining = newSourceTraining
        },
        updateUser(user) {
            if (user) {
                this.presence.userId = user.id
                if (this.presence.userId !== null) {
                    this.$http
                        .get('v1/tenant/' + this.tenant + '/users/' + user.id + '/memberships?filter=active')
                        .then((response) => {
                            this.activeMemberships = response.data
                        })
                        .catch((response) => {
                            this.$store.dispatch(
                                'showWarningMessage',
                                `Something went wrong. Error: ${response.status}`
                            )
                        })
                }
            }
        },
        save() {
            this.presence.sourceTrainingId = this.sourceTraining === null ? this.training.id : this.sourceTraining.id
            this.$http
                .post('v1/tenant/' + this.tenant + '/trainings/' + this.training.id + '/users', this.presence)
                .then(() => {
                    this.$router.go(-1)
                })
                .catch((response) => {
                    this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
                })
        },
        fetchData() {
            this.loadingStart()
            this.$http
                .get('v1/tenant/' + this.tenant + '/trainings/' + this.$route.params.trainingId)
                .then((response) => {
                    this.training = response.data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
