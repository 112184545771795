<template>
    <v-list-item>
        <v-list-item-avatar>
            <v-icon class="grey lighten-3">lock</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title
                ><strong>{{ item.user.firstname }} {{ item.user.lastname }}</strong></v-list-item-title
            >
            <v-list-item-subtitle>{{ item.user.email }}<br />{{ item.role }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-tooltip bottom class="mx-0"
                ><template v-slot:activator="{ on, attrs }">
                    <v-btn icon ripple @click.stop="sendDelete(item)" v-bind="attrs" v-on="on">
                        <v-icon class="grey--text text--lighten-1">delete</v-icon>
                    </v-btn></template
                >
                <span>Remove permission</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'permission-list-item',
    props: ['item', 'tenant'],
    methods: {
        sendDelete(item) {
            this.$emit('delete', item)
        },
    },
}
</script>
