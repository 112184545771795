<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Assign training group</v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item v-if="hideGroup">
              <v-list-item-content>
                <v-list-item-title>{{ group.name }}</v-list-item-title>
                <v-list-item-subtitle>Training Group</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hideUser">
              <v-list-item-content>
                <v-list-item-title>{{ user.firstname }} {{ user.lastname }}</v-list-item-title>
                <v-list-item-subtitle>User</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="px-3 pb-4">
            <v-select
              :items="groups"
              v-model="group"
              label="Training group"
              item-text="name"
              item-value="id"
              return-object
              v-if="!hideGroup"
            ></v-select>
            <user-autocomplete v-if="hideGroup" @change="updateUser" :user="user" label="User"></user-autocomplete>

            <v-select
              v-if="group !== null"
              v-model="patterns"
              :items="group.patterns"
              :menu-props="{ maxHeight: '400' }"
              label="Training patterns"
              multiple
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index !== 0">,&nbsp;</span>
                {{ item.humanWeekday }} ({{ item.timeStart.substring(0, item.timeStart.length - 3) }}
                -
                {{ item.timeEnd.substring(0, item.timeEnd.length - 3) }})
              </template>
              <template v-slot:item="{ item }">
                {{ item.humanWeekday }} ({{ item.timeStart.substring(0, item.timeStart.length - 3) }}
                -
                {{ item.timeEnd.substring(0, item.timeEnd.length - 3) }})
              </template>
            </v-select>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="done()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'
import WithLoading from '../../mixins/WithLoading.js'

export default {
  mixins: [WithLoading],
  components: { UserAutocomplete },
  data() {
    return {
      user: null,
      group: null,
      users: [],
      patterns: [],
      groups: [],
      saving: false,
      hideUser: false,
      hideGroup: false,
      loadingPatterns: true,
    }
  },
  methods: {
    updateUser(newUser) {
      this.user = newUser
    },
    refreshPatterns() {},
    done() {
      this.saving = true
      var pattern_ids = this.patterns.map((pattern) => {
        return pattern.id
      })

      const uid = typeof this.$route.params.userId === 'undefined' ? this.user.id : this.$route.params.userId

      this.$http
        .post(`v1/tenant/${this.tenant}/users/${uid}/patterns`, { pattern_ids: pattern_ids })
        .then(() => {
          this.saving = false
          this.$router.go(-1)
        })
        .catch(() => {
          console.log('Chyba pri ukladani patternu')
          this.saving = false
        })
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.userId) {
        this.hideUser = true
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/users/' + this.$route.params.userId),
          this.$http.get('v1/tenant/' + this.tenant + '/groups'),
        ])
          .then((response) => {
            this.user = response[0].data
            this.groups = response[1].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else if (this.$route.params.groupId) {
        this.hideGroup = true
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/groups/' + this.$route.params.groupId),
          this.$http.get('v1/tenant/' + this.tenant + '/groups'),
        ])
          .then((response) => {
            this.group = response[0].data
            this.groups = response[1].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/groups')])
          .then((response) => {
            this.groups = response[0].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      }
    },
  },
}
</script>
