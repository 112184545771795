<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>
                        Emails
                        <v-spacer></v-spacer>
                        <v-tooltip bottom class="mx-0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="navigateTo('/emails/create')"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="primary--text mr-3"
                                >
                                    <v-icon>add_box</v-icon>
                                </v-btn>
                            </template>
                            <span>Send email</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            name="search"
                            label="Search for a user"
                            v-model="searchText"
                            prepend-icon="search"
                            single-line
                        ></v-text-field>
                    </v-card-text>
                    <o-list
                        :loadedItems="emails"
                        :filterData="searchText"
                        :filter="searchFilter"
                        :tenant="tenant"
                        listItemComponent="EmailItem"
                    ></o-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import OList from '../../components/list.vue'
import WithLoading from '../../mixins/WithLoading.js'

export default {
    components: { OList },
    mixins: [WithLoading],
    data() {
        return {
            emails: [],
            searchText: null,
        }
    },
    methods: {
        searchFilter(item) {
            let search = new RegExp(this.searchText, 'i')
            return search.test(item.subject)
        },
        navigateToCreate() {
            this.$router.push(`/emails/create`)
        },
        fetchData() {
            this.loadingStart()
            return Promise.all([
                this.$http.get('v1/tenant/' + this.tenant + '/emails'),
            ])
                .then((response) => {
                    this.emails = response[0].data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
