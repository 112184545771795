<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>Select first training</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" xs="12" md="6">
                <div class="text-center">
                  <v-date-picker
                    v-model="date"
                    @click:date="fetchTrainings"
                    elevation="1"
                    :min="minimalDate"
                    locale="cs-CZ"
                    :first-day-of-week="1"
                  ></v-date-picker>
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-list v-if="trainings.length > 0">
                  <v-list-item v-for="training in trainings" :key="training.id">
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ training.start | formatTime }}
                        -
                        {{ training.end | formatTime }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        {{ training.instructor.fullName }} (Free slots: {{ freeSlots(training) }})</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            ripple
                            color="purple"
                            :disabled="sending"
                            @click="addUser(training)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>person_add</v-icon>
                          </v-btn>
                        </template>
                        <span>Add user</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <span v-else>No available trainings</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import moment from 'moment'

export default {
  mixins: [WithLoading, WithSubmitting],
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      minimalDate: moment().format('YYYY-MM-DD'),
      trainings: [],
      training: null,
      sending: false,
    }
  },
  methods: {
    freeSlots(training) {
      const present = training.memberCounts[1] !== undefined ? training.memberCounts[1] : 0
      const neutral = training.memberCounts[-5] !== undefined ? training.memberCounts[-5] : 0
      const first = training.memberCounts[10] !== undefined ? training.memberCounts[10] : 0
      const substituted = training.memberCounts[2] !== undefined ? training.memberCounts[2] : 0

      let freeSlots = training.capacity - neutral - present - first
      if (freeSlots < 0) {
        freeSlots = 0
      }

      freeSlots = freeSlots + training.substitution_slots - substituted

      if (freeSlots < 0) {
        freeSlots = 0
      }

      return freeSlots
    },
    async fetchTrainings() {
      try {
        this.trainings = (
          await this.$http.get(
            `v1/tenant/${this.$store.state.tenant}/trainings/?start=${this.date}&end=${this.date}&withCount=yes`
          )
        ).data
      } catch (err) {
        this.errorHandler(err)
      }
    },
    async addUser(training) {
      var sendData = {
        userId: this.$route.params.userId,
        entryType: 10,
      }
      try {
        await this.$http.post(`v1/tenant/${this.$store.state.tenant}/trainings/${training.id}/users`, sendData)
        this.$router.go(-1)
        this.$store.dispatch('showSuccessMessage', 'First training was assigned.')
      } catch (err) {
        this.errorHandler(err)
      }
    },
    fetchData() {
      this.loadingStart()
      this.fetchTrainings()
      this.loadingCompleted()
    },
  },
}
</script>
