<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4">
          <v-card-title>Trainings</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <vuetify-date-picker
              label="From"
              :initialDate="rangeStart"
              v-on:changed="updateStart"
            ></vuetify-date-picker>
            <v-divider></v-divider>
            <o-list :loadedItems="trainings" :tenant="tenant" listItemComponent="TrainingItem"></o-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'

export default {
  mixins: [WithLoading],
  components: {
    VuetifyDatePicker,
    OList,
  },
  data() {
    return {
      trainings: [],
      rangeStart: moment().format('YYYY-MM-DD'),
    }
  },
  methods: {
    updateStart(value) {
      this.rangeStart = value
      sessionStorage.setItem('trainings-start', value)
      this.fetchData()
    },
    setDateRange() {
      this.rangeStart = sessionStorage.getItem('trainings-start')
      if (this.rangeStart === null) {
        this.rangeStart = moment().format('YYYY-MM-DD')
      }
    },
    fetchData() {
      this.loadingStart()
      this.setDateRange()
      return Promise.all([
        this.$http.get(
          'v1/tenant/' + this.tenant + '/trainings?order=-date&start=' + this.rangeStart + '&end=' + this.rangeStart
        ),
      ])
        .then((response) => {
          this.trainings = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
