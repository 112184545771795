<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            <span v-if="isCreate">Create new training pattern</span>
            <span v-if="!isCreate">Modify training pattern</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'General'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-text-field
                  name="title"
                  label="Title"
                  id="title"
                  v-model="pattern.title"
                  :error-messages="titleErrors"
                  @input="$v.pattern.title.$touch()"
                  tabindex="1"
                ></v-text-field>
                <v-autocomplete
                  v-model="pattern.user_id"
                  :items="instructors"
                  :loading="instructorLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  item-text="selectText"
                  item-value="id"
                  label="Instructor"
                  placeholder="Start typing to Search"
                  @input="$v.pattern.user_id.$touch()"
                  :error-messages="instructorErrors"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Dates'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-select
                  label="Weekday"
                  :items="weekdays"
                  item-text="name"
                  :disabled="!isCreate"
                  item-value="id"
                  v-model="pattern.weekday"
                  :error-messages="weekdayErrors"
                  @input="$v.pattern.weekday.$touch()"
                ></v-select>
                <v-select
                  label="Week parity"
                  :items="weekParities"
                  item-text="name"
                  item-value="id"
                  clearable
                  :disabled="!isCreate"
                  persistent-placeholder
                  placeholder="Každý týden"
                  v-model="pattern.week_parity"
                ></v-select>

                <v-text-field
                  name="timeStart"
                  label="Start Time"
                  id="timeStart"
                  v-model="pattern.timeStart"
                  @input="$v.pattern.timeStart.$touch()"
                  :error-messages="timeStartErrors"
                ></v-text-field>

                <v-text-field
                  name="timeEnd"
                  label="End Time"
                  id="timeEnd"
                  v-model="pattern.timeEnd"
                  @input="$v.pattern.timeEnd.$touch()"
                  :error-messages="timeEndErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Capacity'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <v-text-field
                  name="capacity"
                  label="Capacity"
                  id="capacity"
                  v-model="pattern.capacity"
                  @input="$v.pattern.capacity.$touch()"
                  :error-messages="capacityErrors"
                ></v-text-field>
                <v-text-field
                  name="overbooking"
                  label="Overbooking"
                  id="overbooking_count"
                  hint="Number of people on top of standard capacity"
                  v-model="pattern.overbooking_count"
                  @input="$v.pattern.overbooking_count.$touch()"
                  :error-messages="overbookingCountErrors"
                  persistent-hint
                ></v-text-field>
                <v-text-field
                  name="substitution_slots"
                  label="Substitution Slots"
                  id="substitution_slots"
                  v-model="pattern.substitution_slots"
                  hint="Number of substitution slots always available"
                  persistent-hint
                  @input="$v.pattern.substitution_slots.$touch()"
                  :error-messages="substitutionSlotsErrors"
                ></v-text-field>
              </v-col> </v-row
            ><v-row>
              <v-col xs12 md4>
                <v-subheader v-text="'Automatic training generation'"></v-subheader>
              </v-col>
              <v-col xs12 md8>
                <vuetify-date-picker
                  :initialDate="pattern.start_at"
                  v-on:changed="changeStartAt"
                  :disabled="!isCreate"
                  label="Start at"
                ></vuetify-date-picker>
                <vuetify-date-picker
                  :initialDate="pattern.end_at"
                  v-on:changed="changeEndAt"
                  :disabled="!isCreate"
                  label="End at"
                ></vuetify-date-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-btn light @click.prevent="showAreYouSure = true" v-if="!isCreate">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <are-you-sure :show="showAreYouSure" @decline="showAreYouSure = false" @confirm="doDelete()"></are-you-sure>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'
import positiveInteger from '../../validators/PositiveInteger.js'
import time from '../../validators/Time.js'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'

import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
export default {
  mixins: [WithLoading, WithSubmitting, ErrorMixin],
  components: { AreYouSure, VuetifyDatePicker },
  props: ['gym'],
  data() {
    return {
      showAreYouSure: false,
      pattern: {
        weekday: null,
        timeStart: null,
        timeEnd: null,
        substitution_slots: null,
        start_at: null,
        end_at: null,
      },
      isCreate: false,
      weekdays: [
        { id: '1', name: 'Monday' },
        { id: '2', name: 'Tuesday' },
        { id: '3', name: 'Wednesday' },
        { id: '4', name: 'Thursday' },
        { id: '5', name: 'Friday' },
        { id: '6', name: 'Saturday' },
        { id: '0', name: 'Sunday' },
      ],
      weekParities: [
        { id: 0, name: 'Liché týdny' },
        { id: 1, name: 'Sudé týdny' },
      ],
      weekday: null,
      instructors: [],
      search: null,
      instructorLoading: false,
      instructor: null,
    }
  },
  validations: {
    pattern: {
      title: { required },
      user_id: { required },
      weekday: { required },
      timeStart: { required, time },
      timeEnd: { required, time },
      capacity: { required, positiveInteger },
      overbooking_count: { required, positiveInteger },
      substitution_slots: { required, positiveInteger },
    },
  },
  computed: {
    titleErrors() {
      const errors = []
      if (!this.$v.pattern.title.$dirty) return errors
      !this.$v.pattern.title.required && errors.push('Title is required.')
      return errors
    },
    instructorErrors() {
      const errors = []
      if (!this.$v.pattern.user_id.$dirty) return errors
      !this.$v.pattern.user_id.required && errors.push('Instructor is required.')
      return errors
    },
    weekdayErrors() {
      const errors = []
      if (!this.$v.pattern.weekday.$dirty) return errors
      !this.$v.pattern.weekday.required && errors.push('Weekday is required.')
      return errors
    },
    timeStartErrors() {
      const errors = []
      if (!this.$v.pattern.timeStart.$dirty) return errors
      !this.$v.pattern.timeStart.required && errors.push('Start time is required.')
      !this.$v.pattern.timeStart.time && errors.push('Start time has invalid format.')
      return errors
    },
    timeEndErrors() {
      const errors = []
      if (!this.$v.pattern.timeEnd.$dirty) return errors
      !this.$v.pattern.timeEnd.required && errors.push('End time is required.')
      !this.$v.pattern.timeEnd.time && errors.push('End time has invalid format.')
      return errors
    },
    capacityErrors() {
      const errors = []
      if (!this.$v.pattern.capacity.$dirty) return errors
      !this.$v.pattern.capacity.required && errors.push('Capacity is required.')
      !this.$v.pattern.capacity.positiveInteger && errors.push('Capacity must be a positive number.')
      return errors
    },
    overbookingCountErrors() {
      const errors = []
      if (!this.$v.pattern.overbooking_count.$dirty) return errors
      !this.$v.pattern.overbooking_count.required && errors.push('Overbooking is required.')
      !this.$v.pattern.overbooking_count.positiveInteger && errors.push('Overbooking must be a positive number.')
      return errors
    },
    substitutionSlotsErrors() {
      const errors = []
      if (!this.$v.pattern.substitution_slots.$dirty) return errors
      !this.$v.pattern.substitution_slots.required && errors.push('Substitution slots is required.')
      !this.$v.pattern.substitution_slots.positiveInteger &&
        errors.push('Substitution slots must be a positive number.')
      return errors
    },
  },
  watch: {
    search(val) {
      val && this.querySelections(val)
    },
  },
  methods: {
    changeStartAt(date) {
      this.pattern.start_at = date
    },
    changeEndAt(date) {
      this.pattern.end_at = date
    },
    querySelections(query) {
      if (query.length !== 0) {
        this.instructorLoading = false
        this.$http
          .get('v1/tenant/' + this.tenant + '/users/?filter=instructors&search=' + query)
          .then((response) => {
            this.instructors = response.data
            this.instructorLoading = false
          })
          .catch(() => {
            console.log('Error getting users')
          })
      }
    },
    selectWeekday(key) {
      this.weekdays.forEach((item) => {
        if (item.id === key) {
          this.weekday = item
        }
      })
    },
    doDelete() {
      this.$http
        .delete('v1/tenant/' + this.tenant + '/trainingPatterns/' + this.pattern.id)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'Training pattern has been deleted.')
          this.$router.go(-1)
        })
        .catch(this.handleReponseErrors)
    },
    save() {
      if (!this.isFormValid()) {
        return
      }
      if (this.isCreate) {
        this.$http
          .post('v1/tenant/' + this.tenant + '/trainingPatterns', this.pattern)
          .then(() => {
            this.submittingCompleted()
            this.$router.go(-1)
          })
          .catch((response) => {
            this.submittingFailed(response)
          })
      } else {
        this.$http
          .put('v1/tenant/' + this.tenant + '/trainingPatterns/' + this.pattern.id, this.pattern)
          .then(() => {
            this.submittingCompleted()
            this.$router.go(-1)
          })
          .catch((response) => {
            this.submittingFailed(response)
          })
      }
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.groupId) {
        this.pattern.group_id = this.$route.params.groupId
        this.isCreate = true
        this.loadingCompleted()
      } else {
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/trainingPatterns/' + this.$route.params.trainingPatternId),
        ])
          .then((response) => {
            response[0].data.timeStart = response[0].data.timeStart.substr(0, 5)
            response[0].data.timeEnd = response[0].data.timeEnd.substr(0, 5)
            this.pattern = response[0].data
            this.instructors.push(response[0].data.user)
            this.selectWeekday(this.pattern.weekday)
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      }
    },
  },
}
</script>
