<template>
    <component :is="layout"></component>
</template>

<script>
  import GuestLayout from './layouts/GuestLayout'
import AdminLayout from './layouts/AdminLayout'

export default {
    components: { AdminLayout, GuestLayout },
    computed: {
        layout() {
            return `${this.$route.meta.layout || 'admin'}-layout`
        },
    },
}
</script>
