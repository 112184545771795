<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            <span v-if="isCreate">Create new user</span>
            <span v-if="!isCreate">{{ user.firstname }} {{ user.lastname }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Basic Information'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-text-field
                  name="firstname"
                  label="First name"
                  id="firstname"
                  :error="$v.user.firstname.$error"
                  v-model="user.firstname"
                  @input="$v.user.firstname.$touch()"
                  :error-messages="firstnameErrors"
                ></v-text-field>

                <v-text-field
                  name="lastname"
                  label="Last name"
                  id="lastname"
                  :error="$v.user.lastname.$error"
                  v-model="user.lastname"
                  @input="$v.user.lastname.$touch()"
                  :error-messages="lastnameErrors"
                ></v-text-field>

                <v-text-field
                  name="email"
                  label="Email"
                  id="email"
                  :disabled="!isCreate"
                  :error="$v.user.email.$error"
                  v-model="user.email"
                  @input="$v.user.email.$touch()"
                  :error-messages="emailErrors"
                ></v-text-field>

                <v-text-field
                  name="phone"
                  label="Phone"
                  id="phone"
                  :error="$v.user.phone.$error"
                  v-model="user.phone"
                  @input="$v.user.phone.$touch()"
                  :error-messages="phoneErrors"
                ></v-text-field>
                
                <v-text-field
                  name="birthdate"
                  label="Datum narození"
                  id="birthdate"
                  v-model="user.birthdate"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Adresa trvalého pobytu'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-text-field
                  name="contact_street"
                  label="Ulice"
                  id="contact_street"
                  v-model="user.contact_street"
                ></v-text-field>
                <v-text-field
                  name="contact_city"
                  label="Město"
                  id="contact_city"
                  v-model="user.contact_city"
                ></v-text-field>
                <v-text-field
                  name="contact_zip"
                  label="PSČ"
                  id="contact_zip"
                  v-model="user.contact_zip"
                ></v-text-field>
                <v-text-field
                  name="contact_country"
                  label="Země"
                  id="contact_country"
                  v-model="user.contact_country"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Fakturační adresa'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-text-field
                  name="invoice_company_name"
                  label="Firma"
                  id="invoice_company_name"
                  v-model="user.invoice_company_name"
                ></v-text-field>
                <v-text-field
                  name="invoice_company_number"
                  label="IČ"
                  id="invoice_company_number"
                  v-model="user.invoice_company_number"
                ></v-text-field>
                <v-text-field
                  name="invoice_street"
                  label="Ulice"
                  id="invoice_street"
                  v-model="user.invoice_street"
                ></v-text-field>
                <v-text-field
                  name="invoice_city"
                  label="Město"
                  id="invoice_city"
                  v-model="user.invoice_city"
                ></v-text-field>
                <v-text-field
                  name="invoice_zip"
                  label="PSČ"
                  id="invoice_zip"
                  v-model="user.invoice_zip"
                ></v-text-field>
                <v-text-field
                  name="invoice_country"
                  label="Země"
                  id="invoice_country"
                  v-model="user.invoice_country"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'COVID'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-select
                  label="COVID status"
                  v-model="user.covid_status"
                  item-text="name"
                  item-value="id"
                  :items="covid_statuses"
                  clearable
                ></v-select>
                <vuetify-date-picker
                  :initialDate="user.covid_ok_until"
                  v-on:changed="updateCovidOkUntil"
                  label="COVID valid until"
                  :error-messages="covidOkErrors"
                ></vuetify-date-picker>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Additional Information'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-textarea
                  name="description"
                  label="Description"
                  id="description"
                  v-model="user.description"
                  multi-line
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Registration Details'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-select
                  label="Registration source"
                  v-model="registrationSource"
                  item-text="name"
                  :items="registrationSources"
                  return-object
                  clearable
                ></v-select>
                <user-autocomplete
                  @change="updateReferralUser"
                  :user="referralUser"
                  label="Referral user"
                  :clearable="true"
                ></user-autocomplete>

                <v-text-field
                  name="registration_source_other"
                  label="Note"
                  id="registration_source_other"
                  v-model="user.registration_source_other"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4"></v-divider>
            <v-row>
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Leaving Reason'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-select
                  label="Reason"
                  v-model="leavingReason"
                  item-text="name"
                  :items="leavingReasons"
                  return-object
                  clearable
                ></v-select>
                <v-text-field
                  name="leaving_reason_note"
                  label="Note"
                  id="leaving_reason_note"
                  v-model="user.leaving_reason_note"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-4" v-if="hasRole('administrator')"></v-divider>
            <v-row v-if="hasRole('administrator')">
              <v-col xs="12" md="6" cols="12">
                <v-subheader v-text="'Instructor Settings'" class="pa-0"></v-subheader>
              </v-col>
              <v-col xs="12" md="6" cols="12">
                <v-text-field
                  name="training_reward"
                  label="Training Reward"
                  id="training_reward"
                  v-model="user.training_reward"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3 px-2">
            <v-btn light @click.prevent="showAreYouSure = true" v-if="!isCreate">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()" :loading="sending" :disabled="sending">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <are-you-sure :show="showAreYouSure" @decline="showAreYouSure = false" @confirm="doDelete(user)"></are-you-sure>
  </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import phone from '../../validators/Phone.js'
import czech from '../../validators/Czech.js'
import AreYouSure from '../../components/dialogs/AreYouSure.vue'
import ErrorMixin from '../../mixins/ErrorMixin'
import date from '../../validators/Date.js'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'

export default {
  mixins: [WithLoading, WithSubmitting, ErrorMixin],
  components: { VuetifyDatePicker, UserAutocomplete, AreYouSure },
  data() {
    return {
      showAreYouSure: false,
      user: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        birthdate: null,
        covid_ok_until: null,
        covid_status: null,
      },
      covid_statuses: [
        { id: 'Vaccination', name: 'Vaccination' },
        { id: 'Booster', name: 'Booster' },
        { id: 'Past desease', name: 'Past desease' },
        { id: 'Negative test', name: 'Negative test' },
      ],
      isCreate: false,
      registrationSource: null,
      registrationSources: [],
      leavingReason: null,
      leavingReasons: [],
      referralUser: null,
    }
  },
  validations: {
    user: {
      firstname: { required, czech },
      lastname: { required, czech },
      email: { required, email },
      phone: { required, phone },
      covid_status: {},
      covid_ok_until: {
        date,
        required: requiredIf((vm) => {
          return vm.covid_status
        }),
      },
    },
  },
  computed: {
    firstnameErrors() {
      const errors = []
      if (!this.$v.user.firstname.$dirty) return errors
      !this.$v.user.firstname.required && errors.push('First name is required.')
      !this.$v.user.firstname.czech && errors.push("First name can't contain special characters or numbers")
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.user.lastname.$dirty) return errors
      !this.$v.user.lastname.required && errors.push('Last name is required.')
      !this.$v.user.lastname.czech && errors.push("Last name can't contain special characters or numbers")
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.user.email.$dirty) return errors
      !this.$v.user.email.required && errors.push('Email is required.')
      !this.$v.user.email.email && errors.push('Email format is invalid')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.user.phone.$dirty) return errors
      !this.$v.user.phone.required && errors.push('Phone is required.')
      !this.$v.user.phone.phone && errors.push('Phone format is invalid')
      return errors
    },
    covidOkErrors() {
      const errors = []
      if (!this.$v.user.covid_ok_until.$dirty) return errors
      !this.$v.user.covid_ok_until.date && errors.push('Date format is invalid')
      !this.$v.user.covid_ok_until.requiredIf && errors.push('COVID date must be filled if COVID status is filled.')
      return errors
    },
  },
  methods: {
    updateReferralUser(user) {
      this.referralUser = user
    },
    updateCovidOkUntil(date) {
      this.user.covid_ok_until = date
    },
    hasRole(roles) {
      if (this.$store.getters.role === null) {
        return false
      }
      if (roles.indexOf(this.$store.getters.role) >= 0) {
        return true
      }
      return false
    },
    doDelete(item) {
      this.$http
        .delete('v1/tenant/' + this.tenant + '/users/' + item.id)
        .then(() => {
          this.$store.dispatch('showSuccessMessage', 'User has been deleted.')
          this.$router.push({ name: 'Users' })
        })
        .catch(this.handleResponseErrors)
    },
    save() {
      if (!this.isFormValid()) {
        return
      }

      this.user.registration_source_id = this.registrationSource === null ? null : this.registrationSource.id
      this.user.leaving_reason_id = this.leavingReason === null ? null : this.leavingReason.id
      this.user.referral_user_id = this.referralUser === null ? null : this.referralUser.id

      if (this.isCreate) {
        this.$http
          .post('v1/tenant/' + this.tenant + '/users', this.user)
          .then(() => {
            this.submittingCompleted()
            this.$store.dispatch('showSuccessMessage', 'User has been created.')
            //TODO: Redirect to newly created user
            this.$router.go(-1)
          })
          .catch((response) => {
            this.submittingFailed(response)
          })
      } else {
        this.$http
          .put('v1/tenant/' + this.tenant + '/users/' + this.user.id, this.user)
          .then(() => {
            this.submittingCompleted()
            this.$store.dispatch('showSuccessMessage', 'User has been updated.')
            this.$router.go(-1)
          })
          .catch((response) => {
            this.submittingFailed(response)
          })
      }
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.userId) {
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/users/' + this.$route.params.userId),
          this.$http.get('v1/tenant/' + this.tenant + '/registration-sources/'),
          this.$http.get('v1/tenant/' + this.tenant + '/leaving-reasons/'),
        ])
          .then((response) => {
            if (response[0].data.covid_ok_until) {
              response[0].data.covid_ok_until = moment(response[0].data.covid_ok_until).format('YYYY-MM-DD')
            }
            this.user = response[0].data
            this.registrationSources = response[1].data
            this.leavingReasons = response[2].data
            this.registrationSource = this.user.registration_source
            this.leavingReason = this.user.leaving_reason
            this.referralUser = this.user.referral_user
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        this.loadingStart()
        this.isCreate = true
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/registration-sources/'),
          this.$http.get('v1/tenant/' + this.tenant + '/leaving-reasons/'),
        ])
          .then((response) => {
            this.registrationSources = response[0].data
            this.leavingReasons = response[1].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      }
    },
  },
}
</script>
