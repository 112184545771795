<template>
    <div>
        <v-select
            :items="membershipTypes"
            v-model="membershipType"
            label="Membership type"
            item-text="name"
            item-value="id"
            return-object
            v-on:input="updateMembershipType"
        ></v-select>
        <div v-if="pType">
            <vuetify-date-picker
                :initialDate="pMembership.dateStart"
                v-on:changed="updateStart"
                label="Start date"
            ></vuetify-date-picker>

            <div class="form-messages" v-if="$v.pMembership.dateStart.$error">
                <span
                    class="form-messages__item"
                    v-if="!$v.pMembership.dateStart.required"
                    >Start date is required</span
                >
            </div>
            <vuetify-date-picker
                :initialDate="pMembership.dateEnd"
                v-on:changed="updateEnd"
                :min-date="minEndDate"
                :max-date="maxEndDate"
                label="Date end"
            ></vuetify-date-picker>
        </div>
        <v-checkbox
            label="Send email notification"
            v-model="sendEmail"
            light
        ></v-checkbox>
        <v-divider></v-divider>
        <div class="pt-3 px-2 text-xs-right">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm">Create membership</v-btn>
        </div>
    </div>
</template>

<script>
import VuetifyDatePicker from '../components/vuetify/DatePicker.vue'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import WithSubmitting from '../mixins/WithSubmitting.js'

export default {
    mixins: [WithSubmitting],
    props: {
        membership: Object,
        membershipTypes: Array,
        membershipType: Object,
        save: Function,
        cancel: Function,
    },
    components: { VuetifyDatePicker },
    data() {
        return {
            sendEmail: true,
            pType: this.membershipType,
            pMembership: this.membership,
            newDateEnd: null,
            minEndDate: null,
            maxEndDate: null,
        }
    },
    validations: {
        pType: { required },
        pMembership: {
            dateStart: { required },
        },
    },
    computed: {
        isCreate() {
            return typeof this.pMembership.id === 'undefined'
        },
    },
    mounted() {
        if (!this.isCreate) {
            this.updateLimits()
        }
    },
    methods: {
        updateMembershipType(newMembershipType) {
            this.pType = newMembershipType
            if (this.pMembership.dateStart) {
                this.pMembership.dateEnd = moment(this.pMembership.dateStart)
                    .add(this.pType.duration - 1, 'days')
                    .format('YYYY-MM-DD')
                this.newDateEnd = this.pMembership.dateEnd
                this.updateLimits()
            }
        },
        submitForm() {
            if (!this.isFormValid()) {
                return
            }
            this.pMembership.membership_type_id = this.pType.id
            this.pMembership.sendEmail = this.sendEmail
            this.save(this.pMembership)
        },
        updateStart(date) {
            this.pMembership.dateStart = date
            this.pMembership.dateEnd = moment(this.pMembership.dateStart)
                .add(this.pType.duration - 1, 'days')
                .format('YYYY-MM-DD')
            this.newDateEnd = this.pMembership.dateEnd
            this.updateLimits()
        },
        updateEnd(date) {
            this.pMembership.dateEnd = date
        },
        hasRole(roles) {
            if (this.$store.getters.role === null) {
                return false
            }
            if (roles.indexOf(this.$store.getters.role) >= 0) {
                return true
            }
            return false
        },
        updateLimits() {
            this.minEndDate = moment(this.pMembership.dateStart)
                .add(this.pType.duration - 1, 'days')
                .format('YYYY-MM-DD')
            if (this.hasRole('administrator') || this.hasRole('manager')) {
                this.maxEndDate = null
            } else {
                this.maxEndDate = moment(this.pMembership.dateStart)
                    .add(
                        this.pType.duration + this.pType.extendDuration - 1,
                        'days'
                    )
                    .format('YYYY-MM-DD')
            }
        },
    },
}
</script>
