export default {
  data() {
    return {
      serverValidationErrors: null,
      isSubmitting: false,
    }
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.isSubmitting = true
      this.submit()
    },
  },
  handleFailedSubmit(error) {
    if (error.response.status === 422) {
      this.$store.dispatch('showWarningMessage', `Server validation failed. Cannot save data.`)
      this.serverValidationErrors = error.response.data.errors
    } else {
      if (error.response.data && error.response.data.message) {
        this.$store.dispatch('showWarningMessage', `${error.response.data.message}`)
      } else {
        this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${error.response.status}`)
      }
    }
    this.isSubmitting = false
  },
}
