<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4">
          <v-card-title>
            <span>Instructor trainings ({{ user.firstname }} {{ user.lastname }})</span>
            <v-spacer></v-spacer>
            <vuetify-date-picker :initialDate="rangeStart" v-on:changed="updateStart"></vuetify-date-picker>
            <vuetify-date-picker :initialDate="rangeEnd" v-on:changed="updateEnd"></vuetify-date-picker>
          </v-card-title>
          <o-list :loadedItems="trainings" :tenant="tenant" listItemComponent="TrainingItem"></o-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../../components/vuetify/DatePicker.vue'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'

export default {
  mixins: [WithLoading],
  components: {
    VuetifyDatePicker,
    OList,
  },
  data() {
    return {
      user: {},
      trainings: [],
      searchText: null,
      showDeleteConfirmation: false,
      deleteConfirmationBody: '',
      deleteItem: {},
      showLimit: 80,
      rangeStart: null,
      rangeEnd: null,
    }
  },
  methods: {
    updateStart(value) {
      this.rangeStart = value
      sessionStorage.setItem('finance-start', value)
      this.fetchData()
    },
    updateEnd(value) {
      this.rangeEnd = value
      sessionStorage.setItem('finance-end', value)
      this.fetchData()
    },
    setDateRange() {
      this.rangeStart = sessionStorage.getItem('finance-start')
      if (this.rangeStart === null) {
        this.rangeStart = moment().subtract(30, 'days').format('YYYY-MM-DD')
      }
      this.rangeEnd = sessionStorage.getItem('finance-end')
      if (this.rangeEnd === null) {
        this.rangeEnd = moment().format('YYYY-MM-DD')
      }
    },
    fetchData() {
      this.loadingStart()
      this.setDateRange()
      return Promise.all([
        this.$http.get('v1/tenant/' + this.tenant + '/users/' + this.$route.params.userId),
        this.$http.get(
          'v1/tenant/' +
            this.tenant +
            '/users/' +
            this.$route.params.userId +
            '/led-trainings?order=-date&start=' +
            this.rangeStart +
            '&end=' +
            this.rangeEnd
        ),
      ])
        .then((response) => {
          this.user = response[0].data
          this.trainings = response[1].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
