import moment from 'moment'

export default {
  load(context, groupId, callback) {
    let startMonthly = encodeURIComponent(moment().subtract(13, 'months').format('YYYY-MM-DD'))
    let endMonthly = encodeURIComponent(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    let startDaily = encodeURIComponent(moment().subtract(91, 'days').format('YYYY-MM-DD'))
    let endDaily = encodeURIComponent(moment().subtract(1, 'days').format('YYYY-MM-DD'))

    let monthlyRequestUrl = `v1/tenant/${context.$store.state.tenant}/groups/${groupId}/monthly-stats?start=${startMonthly}&end=${endMonthly}`
    let dailyRequestUrl = `v1/tenant/${context.$store.state.tenant}/groups/${groupId}/daily-stats?start=${startDaily}&end=${endDaily}`

    if (sessionStorage.getItem(monthlyRequestUrl) !== null && sessionStorage.getItem(dailyRequestUrl) !== null) {
      callback([
        JSON.parse(sessionStorage.getItem(monthlyRequestUrl)),
        JSON.parse(sessionStorage.getItem(dailyRequestUrl)),
      ])
    } else {
      Promise.all([context.$http.get(monthlyRequestUrl), context.$http.get(dailyRequestUrl)])
        .then((response) => {
          sessionStorage.setItem(monthlyRequestUrl, JSON.stringify(response[0]))
          sessionStorage.setItem(dailyRequestUrl, JSON.stringify(response[1]))
          callback(response)
        })
        .catch(() => {
          console.log('Stats service failed to load data')
        })
    }
  },
}
