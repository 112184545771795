<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title
                        >Edit text {{ $route.params.slug }}</v-card-title
                    >
                    <v-divider></v-divider>
                    <v-card-text>
                        <editor
                            :initialValue="text"
                            api-key="e8c9yad0xu5cscudzuquigaztxkjjplb4z8zygifm61o0d7c"
                            v-model="text"
                            :init="{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap',
                                    'searchreplace visualblocks code fullscreen',
                                    'print preview anchor insertdatetime media',
                                    'paste code help wordcount table',
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | help',
                            }"
                        ></editor>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3 px-2">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="save()"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import Editor from '@tinymce/tinymce-vue'

export default {
    components: { Editor },
    mixins: [WithLoading],
    data() {
        return {
            text: '',
        }
    },
    methods: {
        applyTextEdit(text) {
            this.text = text
        },
        save() {
            this.text = this.text === '<p><br></p>' ? '' : this.text
            this.text = this.text === '<br>' ? '' : this.text
            this.$http
                .put(
                    'v1/tenant/' +
                        this.tenant +
                        '/texts/' +
                        this.$route.params.slug,
                    { text: this.text }
                )
                .then(() => {
                    this.$router.go(-1)
                })
                .catch((response) => {
                    this.$store.dispatch(
                        'showWarningMessage',
                        `Something went wrong. Error: ${response.status}`
                    )
                })
        },
        fetchData() {
            this.loadingStart()
            return Promise.all([
                this.$http.get(
                    'v1/tenant/' +
                        this.tenant +
                        '/texts/' +
                        this.$route.params.slug
                ),
            ])
                .then((response) => {
                    this.text = response[0].data.text
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
