<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            Message of the day
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="goTo('gyms/' + tenant + '/message')" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify message</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading">
            <p v-if="!gym.message">There is no message today.</p>
            <div v-html="gym.message" v-if="gym.message"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            Public announcement
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="goTo('public-announcement')" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify public announcement</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading">
            <p v-if="!gym.public_announcement || !gym.public_announcement != ''">There is no public announcement.</p>
            <div v-html="gym.public_announcement" v-if="gym.public_announcement && gym.public_announcement != ''"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" md="6">
        <v-card height="100%" :loading="loading">
          <v-card-title>Upcoming Trainings</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="trainings.length == 0 && !loading">
            <p class="text-center mt-3">There are no upcomming trainings today</p>
          </v-card-text>
          <o-list
            v-if="trainings.length > 0 && !loading"
            :loadedItems="trainings"
            :tenant="tenant"
            listItemComponent="TrainingItem"
          ></o-list>
        </v-card>
      </v-col>
      <v-col xs="12" md="6">
        <v-card height="100%" :loading="loading">
          <v-card-title>Notifications</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="notifications.length == 0 && !loading">
            <p class="text-center mt-3">There are no notifications</p>
          </v-card-text>
          <v-list two-line v-if="notifications.length > 0 && !loading">
            <v-list-item v-for="notification in limitedNotifications" :key="notification.id">
              <v-list-item-avatar>
                <v-icon class="grey lighten-3">person</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ notification.created_at | formatDate }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-html="notification.body"></span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon ripple @click.stop="sendEmailToUser(notification)" v-bind="attrs" v-on="on">
                      <v-icon class="grey--text">email</v-icon>
                    </v-btn>
                  </template>
                  <span>Send email</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon ripple @click.stop="dismiss(notification)" v-bind="attrs" v-on="on">
                      <v-icon class="grey--text">done</v-icon>
                    </v-btn>
                  </template>
                  <span>Acknowledge</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div v-if="remainingNotifications > 0" class="text-center pb-2">
            <v-btn color="primary" @click="showMoreNotifications()"
              >Show more ({{ remainingNotifications }} hidden)</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'

export default {
  mixins: [WithLoading],
  components: {
    OList,
  },
  data() {
    return {
      gym: null,
      users: [],
      notifications: [],
      notificationLimit: 5,
      membersLimit: 10,
      trainings: [],
      message: null,
    }
  },
  computed: {
    limitedNotifications() {
      return this.notifications.slice(0, this.notificationLimit)
    },
    remainingNotifications() {
      return this.notifications.length - this.notificationLimit
    },
    limitedMembers() {
      return this.users.slice(0, this.membersLimit)
    },
    remainingMembers() {
      return this.users.length - this.membersLimit
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(`${path}`)
    },
    sendEmailToUser(notification) {
      notification.isHidden = true
      notification.notifyUser = true
      this.$http
        .put('v1/tenant/' + this.$store.state.tenant + '/notifications/' + notification.id, notification)
        .then(() => {
          var index = this.notifications.indexOf(notification)
          this.notifications.splice(index, 1)
        })
        .catch((response) => {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
        })
    },
    showMoreNotifications() {
      this.notificationLimit = this.notificationLimit + 10
    },
    showMoreMembers() {
      this.membersLimit = this.membersLimit + 10
    },
    openUser(id) {
      window.location = '/users/' + id
    },
    openTraining(training) {
      window.location = '/trainings/' + training.id
    },
    dismiss(notification) {
      notification.isHidden = 1
      this.$http
        .put('v1/tenant/' + this.$store.state.tenant + '/notifications/' + notification.id, notification)
        .then(() => {
          var index = this.notifications.indexOf(notification)
          this.notifications.splice(index, 1)
        })
        .catch((response) => {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
        })
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([
        this.$http.get('v1/tenant/' + this.$store.state.tenant + '/notifications'),
        this.$http.get(
          'v1/tenant/' +
            this.$store.state.tenant +
            '/trainings?start=' +
            encodeURIComponent(moment().subtract(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')) +
            '&end=' +
            moment().format('YYYY-MM-DD')
        ),
        this.$http.get('v1/tenant/' + this.$store.state.tenant + '/gyms/' + this.$store.state.tenant),
      ])
        .then((response) => {
          this.notifications = response[0].data
          this.trainings = response[1].data
          this.gym = response[2].data

          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
