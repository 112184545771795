<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="UserText"
        item-value="id"
        :label="label"
        placeholder="Start typing to search for users"
        prepend-icon="person"
        return-object
        :error-messages="errorMessages"
        @change="$emit('change', model)"
        :clearable="clearable"
    ></v-autocomplete>
</template>
<script>
export default {
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'User',
        },
        user: {
            type: Object,
            default: null,
        },
        errorMessages: {
            type: Array,
        },
    },
    data: () => ({
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        model: null,
        search: null,
    }),
    mounted() {
        if (this.user) {
            this.model = this.user
            this.search = this.user.selectText
        }
    },
    computed: {
        fields() {
            if (!this.model) return []

            return Object.keys(this.model).map((key) => {
                return {
                    key,
                    value: this.model[key] || 'n/a',
                }
            })
        },
        items() {
            return this.entries.map((entry) => {
                const UserText = entry.selectText
                return Object.assign({}, entry, { UserText })
            })
        },
    },
    watch: {
        search() {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            this.$http
                .get(`v1/tenant/${this.$store.state.tenant}/users?search=${this.search}`)
                .then((response) => {
                    this.entries = response.data
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
