<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            <span>{{ gym.name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field name="name" label="Name" id="name" v-model="gym.name"></v-text-field>
            <v-text-field
              name="default_email_name"
              label="Email Name"
              id="default_email_name"
              v-model="gym.default_email_name"
            ></v-text-field>
            <v-text-field
              name="default_email_address"
              label="Email Address"
              id="default_email_address"
              v-model="gym.default_email_address"
            ></v-text-field>
            <v-checkbox label="Is publicly visible?" v-model="gym.is_public" light></v-checkbox>
            <v-text-field name="public_url" label="Public URL" id="public_url" v-model="gym.public_url"></v-text-field>
            <v-select
              label="Email send to a lost user"
              v-model="gym.leaving_reason_template_id"
              item-text="name"
              item-value="id"
              :items="emailTemplates"
              clearable
            ></v-select>
            <v-select
              label="Email when an user registers for event"
              v-model="gym.event_user_registered_template_id"
              item-text="name"
              item-value="id"
              :items="emailTemplates"
              clearable
            ></v-select>
            <v-select
              label="Email asking user in waiting list, if he wants to join"
              v-model="gym.waiting_list_question_template_id"
              item-text="name"
              item-value="id"
              :items="emailTemplates"
              clearable
            ></v-select>
            <v-select
              label="Email confirming to user, that he was moved from waiting list to a group"
              v-model="gym.waiting_list_confirmation_template_id"
              item-text="name"
              item-value="id"
              :items="emailTemplates"
              clearable
            ></v-select>
            <v-select
              label="Email notifing admins that user was moved from waiting list to a group"
              v-model="gym.waiting_list_notification_template_id"
              item-text="name"
              item-value="id"
              :items="emailTemplates"
              clearable
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
  mixins: [WithLoading],
  data() {
    return {
      gym: {
        public_url: null,
      },
      isCreate: false,
      emailTemplates: [],
    }
  },
  methods: {
    save() {
      if (this.isCreate) {
        this.$http
          .post('v1/tenant/' + this.tenant + '/gyms', this.gym)
          .then(() => {
            this.$router.go(-1)
          })
          .catch((response) => {
            this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
          })
      } else {
        this.$http
          .put('v1/tenant/' + this.tenant + '/gyms/' + this.tenant, this.gym)
          .then(() => {
            this.$router.go(-1)
          })
          .catch((response) => {
            this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${response.status}`)
          })
      }
    },
    fetchData() {
      this.loadingStart()
      if (this.$route.params.gymId) {
        return Promise.all([
          this.$http.get('v1/tenant/' + this.tenant + '/gyms/' + this.$route.params.gymId),
          this.$http.get('v1/tenant/' + this.tenant + '/emailTemplates'),
        ])
          .then((response) => {
            this.gym = response[0].data
            this.emailTemplates = response[1].data
            this.loadingCompleted()
          })
          .catch((response) => {
            this.loadingFailed(response)
          })
      } else {
        this.isCreate = true
        this.loadingCompleted()
      }
    },
  },
}
</script>
