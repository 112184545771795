export default (value) => {
    if (value === null) return true

    var isFormatOK = /^[1-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$/.test(value)
    if (!isFormatOK) {
        return false
    }

    var parts = value.split('-')
    if (parseInt(parts[1]) > 12 || parseInt(parts[2]) > 31) {
        return false
    }

    return true
}
