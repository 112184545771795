<template>
  <v-container fluid>
    <users-statistics :users_count="count" v-if="$vuetify.breakpoint.lgAndUp" />
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            Users
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="navigateTo('/users/create')" v-bind="attrs" v-on="on" class="primary--text mr-3">
                  <v-icon>add_box</v-icon>
                </v-btn>
              </template>
              <span>Create new user</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-skeleton-loader type="list-item-two-line" v-if="loading"></v-skeleton-loader>
          <v-card-text v-if="!loading">
            <v-text-field
              name="search"
              label="Search for a user"
              v-model="searchText"
              @input="debo"
              prepend-icon="search"
              single-line
            ></v-text-field>
            <p class="text-center pt-3" v-if="searchText.length < 3">Type at least 3 characters to get users.</p>
            <p class="text-center pt-3" v-if="users.length === 0 && searchText.length >= 3">
              No users matching selected filter.
            </p>
            <o-list
              :loadedItems="users"
              :tenant="tenant"
              listItemComponent="UserItem"
              :filter="searchFilter"
              :filterData="searchText"
            ></o-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <users-statistics :users_count="users.length" v-if="$vuetify.breakpoint.mdAndDown" />
  </v-container>
</template>

<script>
import OList from '../../components/list.vue'
import WithLoading from '../../mixins/WithLoading.js'
import UsersStatistics from './components/UsersStatistics.vue'
import { debounce } from 'debounce'

export default {
  name: 'users-list',
  mixins: [WithLoading],
  components: {
    UsersStatistics,
    OList,
  },
  data() {
    return {
      users: [],
      count: 0,
      searchText: '',
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(`${path}`)
    },
    searchFilter() {
      return true
    },
    debo: debounce(function (e) {
      if (e.length < 3) {
        this.users = []
        return
      }
      this.$http.get(`v1/tenant/${this.tenant}/users?search=${this.searchText}`).then((response) => {
        this.users = response.data
      })
    }, 500),
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get(`v1/tenant/${this.tenant}/users/count`)])
        .then((response) => {
          this.count = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
