<template>
  <v-card class="mb-4" :loading="loading">
    <v-card-title>
      <span class="pl-3">Waiting list</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom class="mx-0" v-if="!loading && waitingListItems.length > 1">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              icon
              class="mr-4"
              color="#EA580C"
              @click="deleteAllWaitingListItems()"
              :disabled="deletingAllWaitingListItems"
            >
              <v-icon>delete_forever</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Remove from all waiting lists</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <v-list>
        <v-list-item v-if="showingGroupSelector">
          <v-select
            :items="groups"
            v-model="group"
            label="Training group"
            item-text="name"
            item-value="id"
            return-object
          ></v-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                ripple
                v-bind="attrs"
                v-on="on"
                color="#EA580C"
                class="mx-3"
                @click="showingGroupSelector = false"
                :disabled="deletingWaitingListItem"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                ripple
                v-bind="attrs"
                v-on="on"
                color="green"
                @click="addWaitingListItem()"
                :loading="addingWaitingListItem"
                :disabled="group == null || addingWaitingListItem"
              >
                <v-icon>done</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-for="item in waitingListItems"
          :key="item.id"
          :to="{
            name: 'GroupsView',
            params: { groupId: item.group_id },
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              ><strong>{{ item.name }}</strong></v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  ripple
                  v-bind="attrs"
                  v-on="on"
                  color="#EA580C"
                  @click.prevent="deleteWaitingListItem(item.id)"
                  :disabled="deletingWaitingListItem"
                >
                  <v-icon>person_remove</v-icon>
                </v-btn>
              </template>
              <span>Remove from waiting list</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <p class="text-center" v-if="waitingListItems.length == 0">No waiting list items</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      waitingListItems: [],
      deletingWaitingListItem: false,
      deletingAllWaitingListItems: false,
      groups: [],
      group: null,
      showingGroupSelector: false,
      loading: true,
      addingWaitingListItem: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async addWaitingListItem() {
      this.addingWaitingListItem = true
      try {
        const data = {
          user_id: parseInt(this.$route.params.userId),
          group_id: this.group.id,
        }
        const response = await this.$http.post(`v2/tenant/${this.$store.state.tenant}/waiting-list-items`, data)
        this.$store.dispatch('showSuccessMessage', response.data.message)
        this.fetchData()
      } catch (err) {
        this.$store.dispatch('showWarningMessage', err.response.data.message)
      }
      this.addingWaitingListItem = false
      this.showingGroupSelector = false
    },
    async showGroupSelector() {
      this.group = null
      try {
        const response = await this.$http.get(`v1/tenant/${this.$store.state.tenant}/groups`)
        this.groups = response.data

        this.showingGroupSelector = true
      } catch (err) {
        this.$store.dispatch('showWarningMessage', err.response.data.message)
      }
    },
    async deleteAllWaitingListItems() {
      this.deletingAllWaitingListItems = true
      try {
        const response = await this.$http.delete(
          `v2/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/waiting-list-items`
        )
        this.$store.dispatch('showSuccessMessage', response.data.message)
        this.fetchData()
      } catch (err) {
        this.$store.dispatch('showWarningMessage', err.response.data.message)
      }
      this.deletingAllWaitingListItems = false
    },
    async deleteWaitingListItem(id) {
      this.deletingWaitingListItem = true
      try {
        const response = await this.$http.delete(`v2/tenant/${this.$store.state.tenant}/waiting-list-items/${id}`)
        this.$store.dispatch('showSuccessMessage', response.data.message)
        this.fetchData()
      } catch (err) {
        this.$store.dispatch('showWarningMessage', err.response.data.message)
      }
      this.deletingWaitingListItem = false
    },
    async fetchData() {
      try {
        this.waitingListItems = (
          await this.$http.get(
            `v2/tenant/${this.$store.state.tenant}/users/${this.$route.params.userId}/waiting-list-items`
          )
        ).data
        this.loading = false
      } catch (err) {
        this.$store.dispatch('showWarningMessage', err.response.data.message)
      }
    },
  },
}
</script>
