<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mb-4">
          <v-card-title>
            {{ item.name }}
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="navigateToEdit" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <component :is="component" :item="item"></component>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../mixins/WithLoading.js'
import MembershipTypeItem from '../components/view-items/MembershipTypeItem.vue'
import SalutationItem from '../components/view-items/SalutationItem.vue'
import UserStateItem from '../components/view-items/UserStateItem.vue'

export default {
  components: {
    UserStateItem,
    SalutationItem,
    MembershipTypeItem,
  },
  mixins: [WithLoading],
  data() {
    return {
      item: null,
    }
  },
  methods: {
    navigateToEdit() {
      this.$router.push(`/${this.$route.meta.routeEndpoint}/${this.item.id}/edit`)
    },
    fetchData() {
      this.loadingStart()
      this.$http
        .get('v1/tenant/' + this.tenant + '/' + this.$route.meta.apiEndpoint + '/' + this.$route.params.id)
        .then((response) => {
          this.item = response.data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
  computed: {
    component() {
      return this.$route.meta.component
    },
  },
}
</script>
