<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>Salary</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <vuetify-date-picker
                            label="From"
                            :initialDate="rangeStart"
                            v-on:changed="updateStart"
                        ></vuetify-date-picker>
                        <vuetify-date-picker
                            label="To"
                            :initialDate="rangeEnd"
                            v-on:changed="updateEnd"
                        ></vuetify-date-picker>
                        <v-divider></v-divider>
                        <o-list
                            :loadedItems="salaryData"
                            :tenant="tenant"
                            listItemComponent="SalaryItem"
                        ></o-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../components/vuetify/DatePicker.vue'
import WithLoading from '../mixins/WithLoading.js'
import OList from '../components/list.vue'

export default {
    mixins: [WithLoading],
    components: { VuetifyDatePicker, OList },
    data() {
        return {
            salaryData: [],
            rangeStart: null,
            rangeEnd: null,
        }
    },
    methods: {
        updateStart(value) {
            this.rangeStart = value
            sessionStorage.setItem('finance-start', value)
            this.fetchData()
        },
        updateEnd(value) {
            this.rangeEnd = value
            sessionStorage.setItem('finance-end', value)
            this.fetchData()
        },
        setDateRange() {
            this.rangeStart = sessionStorage.getItem('finance-start')
            if (this.rangeStart === null) {
                this.rangeStart = moment().startOf('month').format('YYYY-MM-DD')
                sessionStorage.setItem('finance-start', this.rangeStart)
            }
            this.rangeEnd = sessionStorage.getItem('finance-end')
            if (this.rangeEnd === null) {
                this.rangeEnd = moment().endOf('month').format('YYYY-MM-DD')
                sessionStorage.setItem('finance-end', this.rangeEnd)
            }
        },
        showTrainings(item) {
            this.$router.push(
                '/users/' + item.user.id + '/instructor-trainings'
            )
        },
        openUser(id) {
            this.$router.push('/users/' + id)
        },
        fetchData() {
            this.setDateRange()
            this.loadingStart()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/finance/salary/' +
                        this.rangeStart +
                        '/' +
                        this.rangeEnd
                )
                .then((response) => {
                    this.salaryData = response.data.salaryData
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingCompleted(response)
                })
        },
    },
}
</script>
