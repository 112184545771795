<template>
    <v-list two-line>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ item.template }}</v-list-item-title>
                <v-list-item-subtitle>Template</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.show_in_list ? 'Yes' : 'No'
                }}</v-list-item-title>
                <v-list-item-subtitle>Visible in list</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.show_in_chart ? 'Yes' : 'No'
                }}</v-list-item-title>
                <v-list-item-subtitle>Visible in charts</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-subheader class="headline"
            >Notification when entering this state</v-subheader
        >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.in_user_email ? item.in_user_email.name : 'None'
                }}</v-list-item-title>
                <v-list-item-subtitle>Email for user</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.in_admin_email ? item.in_admin_email.name : 'None'
                }}</v-list-item-title>
                <v-list-item-subtitle>Email for admins</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-subheader class="headline"
            >Notification when staying in this state</v-subheader
        >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title
                    >{{ item.days_without_change }} days without
                    change</v-list-item-title
                >
                <v-list-item-subtitle>Send email after</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.no_change_user_email
                        ? item.no_change_user_email.name
                        : 'None'
                }}</v-list-item-title>
                <v-list-item-subtitle>Email for user</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.no_change_admin_email
                        ? item.no_change_admin_email.name
                        : 'None'
                }}</v-list-item-title>
                <v-list-item-subtitle>Email for admins</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    props: ['item'],
}
</script>
