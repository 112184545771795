<template>
    <v-list two-line>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ item.price }} CZK</v-list-item-title>
                <v-list-item-subtitle>Price</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ item.duration }}</v-list-item-title>
                <v-list-item-subtitle>Duration</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ item.extendDuration }}</v-list-item-title>
                <v-list-item-subtitle>Extend duration</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{
                    item.trainings_per_week
                }}</v-list-item-title>
                <v-list-item-subtitle>Training per week</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    props: ['item'],
}
</script>
