<template>
    <v-container fluid>
        <v-progress-linear
            v-bind:indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>{{ email.subject }}</v-card-title>
                    <v-divider></v-divider>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    email.start | formatDate
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Date</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    email.from
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >From</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    email.to
                                }}</v-list-item-title>
                                <v-list-item-subtitle>To</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    email.recipients
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Total Recipients</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    email.start | formatDate
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Date</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

                <v-card class="mb-4">
                    <v-card-title>Email Content</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div v-html="email.body"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
    mixins: [WithLoading],
    data() {
        return {
            email: {},
        }
    },
    methods: {
        fetchData() {
            this.loadingStart()
            return Promise.all([
                this.$http.get(
                    'v1/tenant/' +
                        this.tenant +
                        '/emails/' +
                        this.$route.params.emailId
                ),
            ])
                .then((response) => {
                    this.email = response[0].data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
