<template>
  <v-col xs12 md6>
    <v-card class="mb-4">
      <v-card-title>User's Feed</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-textarea
          name="description"
          label="Note"
          id="description"
          v-model="feedItem.body"
          v-on:keyup="saveDraft"
          :error-messages="bodyErrors"
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text v-if="hasDraft" @click="restoreDraft" class="clickable-link">Restore draft</v-btn>
        <v-btn text v-if="hasDraft" @click="discardDraft" class="clickable-link">Discard draft</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.prevent="save()" :loading="submitting" :disabled="submitting">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-timeline dense reverse>
      <v-timeline-item large class="mb-4" v-for="item in limitedItems" :key="item.id" color="grey lighten-2">
        <template v-slot:icon>
          <!-- <v-avatar>
                        <img src="https://i.pravatar.cc/64" />
                    </v-avatar> -->
          <v-icon v-if="item.type == 'USER_GENERAL'">person</v-icon>
          <v-icon v-else>smart_toy</v-icon>
        </template>
        <v-card>
          <v-card-text>
            <span v-if="!item.editing" style="white-space: pre-wrap; word-wrap: break-word" v-html="item.body"></span>
            <v-textarea
              v-if="item.editing"
              name="description"
              label="Note"
              id="description"
              v-model="item.body"
            ></v-textarea>
          </v-card-text>
          <v-card-actions v-if="!item.editing">
            <span class="caption pl-2">{{ item.date | formatDate }}</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0" v-if="!editing && item.type == 'USER_GENERAL'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.stop.native="switchToEdit(item)" v-bind="attrs" v-on="on">
                  <v-icon class="grey--text text--lighten-1">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify note</span>
            </v-tooltip>
          </v-card-actions>
          <v-divider v-if="item.editing"></v-divider>
          <v-card-actions v-if="item.editing">
            <v-btn
              icon
              @mouseover="deleteBtnColor = 'red darken-4'"
              @mouseout="deleteBtnColor = ''"
              @click.prevent="deleteItem(item)"
              :loading="deletingItem"
              :disabled="deletingItem"
              ><v-icon :color="deleteBtnColor">delete_forever</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveItem(item)" :loading="submittingItem" :disabled="submittingItem"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <div v-if="remaining > 0" class="text-center">
      <v-btn @click="showMore()" color="primary">Show more ({{ remaining }} hidden)</v-btn>
    </div>
  </v-col>
</template>

<script>
import WithSubmitting from '../../mixins/WithSubmitting.js'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [WithSubmitting],
  props: ['userId', 'tenant'],
  data() {
    return {
      deleteBtnColor: '',
      submittingItem: false,
      deletingItem: false,
      editing: false,
      limit: 25,
      feedItems: [],
      feedItem: {
        type: 'USER_GENERAL',
        start: null,
        end: null,
        location: null,
        itemBody: null,
        body: null,
        state: null,
        attendees: [],
      },
      user: {},
      submitting: false,
      draft: null,
    }
  },
  validations() {
    return {
      feedItem: {
        body: { required },
      },
    }
  },
  computed: {
    bodyErrors() {
      const errors = []
      if (!this.$v.feedItem.body.$dirty) return errors
      !this.$v.feedItem.body.required && errors.push('Description is required.')
      return errors
    },
    limitedItems() {
      return this.feedItems.slice(0, this.limit)
    },
    remaining() {
      return this.feedItems.length - this.limit
    },
    hasDraft() {
      return this.draft !== null
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData()
      this.draft = localStorage.getItem('note-draft-' + this.userId)
    })
  },
  methods: {
    saveDraft() {
      this.draft = this.feedItem.body
      localStorage.setItem('note-draft-' + this.userId, this.feedItem.body)
    },
    restoreDraft() {
      this.feedItem.body = localStorage.getItem('note-draft-' + this.userId)
      this.draft = null
      localStorage.removeItem('note-draft-' + this.userId)
    },
    discardDraft() {
      this.draft = null
      localStorage.removeItem('note-draft-' + this.userId)
    },
    showMore() {
      this.limit = this.limit + 10
    },
    switchToEdit(item) {
      item.editing = true
      this.editing = true
    },
    deleteItem(item) {
      this.deletingItem = true
      this.$http
        .delete('v1/tenant/' + this.tenant + '/feedItems/' + item.id, item)
        .then(() => {
          this.deletingItem = false
          this.editing = false
          var index = this.feedItems.indexOf(item)
          this.feedItems.splice(index, 1)
          this.$store.dispatch('showSuccessMessage', 'Note has been deleted.')
        })
        .catch(() => {
          this.deleteBtnColor = ''
          this.deletingItem = false
          this.$store.dispatch('showWarningMessage', 'Unable to delete this note.')
        })
    },
    saveItem(item) {
      this.submittingItem = true
      this.$http
        .put('v1/tenant/' + this.tenant + '/feedItems/' + item.id, item)
        .then(() => {
          this.submittingItem = false
          item.editing = false
          this.editing = false
          this.$store.dispatch('showSuccessMessage', 'Note has been modified.')
        })
        .catch((data) => {
          this.submittingItem = false
          if (data.status !== 403 && data.status !== 404 && data.status !== 401 && data.status !== 422) {
            console.log('Unable to edit feed item')
          }
        })
    },
    save() {
      if (!this.isFormValid()) {
        return
      }

      this.submitting = true

      // Put together date
      if (this.date) {
        this.feedItem.start = this.date + ' ' + this.start + ':00'
        this.feedItem.end = this.date + ' ' + this.end + ':00'
      }

      this.$http
        .post('v1/tenant/' + this.tenant + '/users/' + this.userId + '/feedItems', this.feedItem)
        .then(() => {
          this.fetchData()
          this.feedItem = {
            type: 'USER_GENERAL',
            start: null,
            end: null,
            location: null,
            itemBody: null,
            body: null,
            state: null,
            attendees: [],
          }
          this.attendees = []
          this.createReminer = false
          this.submitting = false
          this.$v.$reset()
          this.discardDraft()
          this.$store.dispatch('showSuccessMessage', 'Note has been created.')
        })
        .catch(() => {
          this.submitting = false
          this.$store.dispatch('showWarningMessage', 'Something went wrong')
        })
    },
    fetchData() {
      this.$http
        .get('v1/tenant/' + this.tenant + '/users/' + this.userId + '/feedItems')
        .then((data) => {
          this.feedItems = data.data.feedItems
          this.user = data.data.user
        })
        .catch(() => {
          this.$store.dispatch('showWarningMessage', 'Something went wrong')
        })
    },
  },
}
</script>
