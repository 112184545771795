<template>
    <v-container fluid>
        <v-progress-linear
            :indeterminate="true"
            v-if="loading"
        ></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12>
                <v-card class="mb-4">
                    <v-card-title>Extended Memberships</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <vuetify-date-picker
                            label="From"
                            :initialDate="rangeStart"
                            v-on:changed="updateStart"
                        ></vuetify-date-picker>
                        <vuetify-date-picker
                            label="To"
                            :initialDate="rangeEnd"
                            v-on:changed="updateEnd"
                        ></vuetify-date-picker>
                        <v-divider></v-divider>
                        <o-list
                            :loadedItems="extendedMemberships"
                            :tenant="tenant"
                            listItemComponent="ExtendedMembershipItem"
                        ></o-list>
                        <p class="text-xs-right mr-4">
                            <strong>Total: {{ totalLoss }} CZK</strong>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment'
import VuetifyDatePicker from '../components/vuetify/DatePicker.vue'
import WithLoading from '../mixins/WithLoading.js'
import OList from '../components/list.vue'

export default {
    mixins: [WithLoading],
    components: { VuetifyDatePicker, OList },
    data() {
        return {
            extendedMemberships: [],
            trainingPrice: 150,
            totalLoss: 0,
            rangeStart: null,
            rangeEnd: null,
        }
    },
    methods: {
        updateStart(value) {
            this.rangeStart = value
            sessionStorage.setItem('membership-start', value)
            this.fetchData()
        },
        updateEnd(value) {
            this.rangeEnd = value
            sessionStorage.setItem('membership-end', value)
            this.fetchData()
        },
        setDateRange() {
            this.rangeStart = sessionStorage.getItem('membership-start')
            if (this.rangeStart === null) {
                this.rangeStart = moment()
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD')
                sessionStorage.setItem('membership-start', this.rangeStart)
            }
            this.rangeEnd = sessionStorage.getItem('membership-end')
            if (this.rangeEnd === null) {
                this.rangeEnd = moment().format('YYYY-MM-DD')
                sessionStorage.setItem('membership-end', this.rangeEnd)
            }
        },
        fetchData() {
            this.loadingStart()
            this.setDateRange()
            this.$http
                .get(
                    'v1/tenant/' +
                        this.tenant +
                        '/memberships?state=extended&rangeStart=' +
                        this.rangeStart +
                        '&rangeEnd=' +
                        this.rangeEnd
                )
                .then((response) => {
                    this.extendedMemberships = response.data
                    this.totalLoss = 0
                    this.extendedMemberships.forEach((item) => {
                        this.totalLoss = this.totalLoss + item.trainingCount
                    })
                    this.totalLoss = this.totalLoss * this.trainingPrice
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
