<template>
  <v-container fluid>
    <group-statistics
      :groupId="$route.params.groupId"
      :members_count="members.length"
      v-if="$vuetify.breakpoint.lgAndUp"
    />
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-title>
            {{ group.name }}
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="navigateTo('/groups/' + group.id + '/edit')" v-bind="attrs" v-on="on">
                  <v-icon class="primary--text">edit</v-icon>
                </v-btn>
              </template>
              <span>Modify</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><div
                    v-if="group.description"
                    style="white-space: pre-wrap; word-wrap: break-word"
                    v-html="group.description"
                  ></div
                ></v-list-item-title>
                <v-list-item-subtitle>Note</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row wrap v-if="!loading">
      <v-col xs12 md6>
        <v-card class="mb-4" v-if="trainings.length > 0">
          <v-card-title>Trainings</v-card-title>
          <v-divider></v-divider>
          <o-list
            :loadedItems="trainings"
            :filterData="null"
            :filter="null"
            :tenant="tenant"
            listItemComponent="TrainingItem"
          ></o-list>
        </v-card>

        <v-card class="mb-4">
          <v-card-title>
            Training Days
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="navigateTo('/groups/' + group.id + '/training-patterns/create')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="primary--text">add_box</v-icon>
                </v-btn>
              </template>
              <span>Create training day</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="patterns.length === 0">
            <p class="text-center pt-3">No training days assigned to this group.</p>
          </v-card-text>
          <o-list
            v-if="patterns.length > 0"
            :loadedItems="patterns"
            filterData
            filter
            :tenant="tenant"
            listItemComponent="TrainingPatternItem"
          ></o-list>
        </v-card>
      </v-col>
      <v-col xs12 md6>
        <members-list :group="group" :tenantId="tenant" />
        <waiting-list :tenantId="tenant" :groupId="group.id"></waiting-list>
      </v-col>
    </v-row>
    <group-statistics
      :groupId="$route.params.groupId"
      :members_count="members.length"
      v-if="$vuetify.breakpoint.mdAndDown"
    />
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'
import WaitingList from './WaitingList.vue'
import MembersList from './MembersList.vue'
import GroupStatistics from './components/GroupStatistics.vue'

export default {
  mixins: [WithLoading],
  data() {
    return {
      group: {},
      patterns: [],
      trainings: [],
      members: [],
      loading: true,
    }
  },
  components: {
    OList,
    WaitingList,
    GroupStatistics,
    MembersList,
  },
  methods: {
    navigateToEditGroupUser(item) {
      this.$router.push(`/groups/${this.group.id}/users/${item.id}`)
    },
    open(item) {
      this.$router.push('/users/' + item.user.id)
    },
    fetchData() {
      this.loadingStart()
      var groupId = this.$route.params.groupId
      return Promise.all([
        this.$http.get('v1/tenant/' + this.tenant + '/groups/' + groupId),
        this.$http.get('v1/tenant/' + this.tenant + '/groups/' + groupId + '/patterns'),
        this.$http.get('v1/tenant/' + this.tenant + '/groups/' + groupId + '/trainings'),
        this.$http.get('v1/tenant/' + this.tenant + '/groups/' + groupId + '/members'),
      ])
        .then((response) => {
          this.group = response[0].data
          this.patterns = response[1].data
          this.trainings = response[2].data
          this.members = response[3].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
