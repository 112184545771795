<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card height="100%" :loading="loading">
                    <v-card-title>Cashflow</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="!loading">
                        <z-line-chart
                            :datasets="[incomeSet, costSet]"
                            :labels="monthlyLabels"
                        ></z-line-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import StatsService from '../../services/StatsService'
import ZLineChart from '../../components/charts/ZLineChart.vue'

export default {
    mixins: [WithLoading],
    components: { ZLineChart },
    data() {
        return {
            incomeSet: null,
            costSet: null,
            monthlyLabels: [],
        }
    },
    methods: {
        fetchData() {
            this.loadingStart()
            StatsService.load(this, this.drawCharts)
        },
        drawCharts(response) {
            let rawMonthlyStats = response[0].data

            this.incomeSet = {
                label: 'Income',
                data: [],
                backgroundColor: '#4CAF50',
                borderColor: '#4CAF50',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            this.costSet = {
                label: 'Outcome',
                data: [],
                backgroundColor: '#FF5252',
                borderColor: '#FF5252',
                fill: false,
                radius: 0,
                hoverRadius: 6,
                hitRadius: 3,
            }

            rawMonthlyStats.map((stat) => {
                this.monthlyLabels.push(stat.f_date)
                this.incomeSet.data.push(stat.income)
                this.costSet.data.push(-1 * stat.outcome)
            })

            this.loadingCompleted()
        },
    },
}
</script>
