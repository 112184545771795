<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mb-4">
          <v-card-title>
            {{ gym.name }}
            <v-spacer></v-spacer>
            <v-tooltip bottom class="mx-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="navigateTo('/gyms/' + gym.id + '/edit')"
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text mr-3"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>Modify</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ gym.name }}</v-list-item-title>
                <v-list-item-subtitle>Name</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ gym.is_public ? 'Public' : 'Office only' }}</v-list-item-title>
                <v-list-item-subtitle>Visiblity</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ gym.default_email_name }}</v-list-item-title>
                <v-list-item-subtitle>Default Email Name</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ gym.default_email_address }}</v-list-item-title>
                <v-list-item-subtitle>Default Email Name</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <a :href="gym.public_url">{{ gym.public_url }}</a>
                </v-list-item-title>
                <v-list-item-subtitle>Public URL</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card class="mb-4">
          <v-card-title>Email Settings</v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email send to a lost user</v-list-item-title>
                <v-list-item-subtitle>{{
                  gym.leaving_reason_template ? gym.leaving_reason_template.name : 'Nothing'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email when an user registers for event</v-list-item-title>
                <v-list-item-subtitle>{{
                  gym.event_user_registered_template ? gym.event_user_registered_template.name : 'Nothing'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email asking user in waiting list, if he wants to join</v-list-item-title>
                <v-list-item-subtitle>{{
                  gym.waiting_list_question_template ? gym.waiting_list_question_template.name : 'Nothing'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Email confirming to user, that he was moved from waiting list to a group</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  gym.waiting_list_confirmation_template ? gym.waiting_list_confirmation_template.name : 'Nothing'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'

export default {
  mixins: [WithLoading],
  data() {
    return {
      gym: {},
      loading: true,
    }
  },
  methods: {
    testTemplate(templateId) {
      console.log('Sending test email - ' + templateId)
    },
    fetchData() {
      this.loadingStart()
      return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/gyms/' + this.$route.params.gymId)])
        .then((response) => {
          this.gym = response[0].data
          this.loadingCompleted()
        })
        .catch((response) => {
          this.loadingFailed(response)
        })
    },
  },
}
</script>
