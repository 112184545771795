<template>
    <v-container fluid>
        <active-users-statistics
            :active_users_count="users.length"
            v-if="$vuetify.breakpoint.lgAndUp"
        />
        <v-row>
            <v-col>
                <v-card class="mb-4" :loading="loading">
                    <v-card-title>Active Users</v-card-title>
                    <v-divider></v-divider>
                    <v-skeleton-loader
                        v-if="loading"
                        type="list-item-two-line"
                    ></v-skeleton-loader>
                    <v-card-text v-if="!loading">
                        <v-text-field
                            name="search"
                            label="Search for a user"
                            v-model="searchText"
                            prepend-icon="search"
                            single-line
                        ></v-text-field>
                    </v-card-text>
                    <o-list
                        :loadedItems="users"
                        :filterData="searchText"
                        :filter="searchFilter"
                        :tenant="tenant"
                        listItemComponent="UserItem"
                    ></o-list>
                </v-card>
            </v-col>
        </v-row>
        <active-users-statistics
            :active_users_count="users.length"
            v-if="$vuetify.breakpoint.mdAndDown"
        />
    </v-container>
</template>

<script>
import ActiveUsersStatistics from './components/ActiveUsersStatistics.vue'
import WithLoading from '../../mixins/WithLoading.js'
import OList from '../../components/list.vue'

export default {
    mixins: [WithLoading],
    components: {
        ActiveUsersStatistics,
        OList,
    },
    data() {
        return {
            users: [],
            searchText: null,
        }
    },
    methods: {
        searchFilter(item) {
            let search = new RegExp(this.searchText, 'i')
            return (
                search.test(item.firstname) ||
                search.test(item.lastname) ||
                search.test(item.email) ||
                search.test(item.phone)
            )
        },
        fetchData() {
            this.loadingStart()
            return Promise.all([
                this.$http.get('v1/tenant/' + this.tenant + '/active-users'),
            ])
                .then((response) => {
                    this.users = response[0].data
                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
