import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../views/Dashboard/Dashboard.vue'
import RegistrationsList from '../views/Registrations/List.vue'
import ExtendedMemberships from '../views/ExtendedMemberships.vue'
import GroupsReport from '../views/Groups/GroupsReport.vue'
import Salary from '../views/Salary.vue'
import EmailForm from '../views/Emails/Form.vue'
import EmailsView from '../views/Emails/View.vue'
import EmailTemplatesView from '../views/EmailTemplates/View.vue'
import EmailTemplatesForm from '../views/EmailTemplates/Form.vue'
import UserStatesForm from '../views/UserStates/Form.vue'
import GroupReservesForm from '../views/GroupReserves/Form.vue'
import InstructorTrainingsList from '../views/InstructorTrainings/List.vue'
import ActiveUsersList from '../views/ActiveUsers/List.vue'
import LostUsersList from '../views/LostUsers/List.vue'
import GymsView from '../views/Gyms/View.vue'
import GymsForm from '../views/Gyms/Form.vue'
import GymMessageForm from '../views/GymMessage/Form.vue'
import PublicAnnouncementForm from '../views/PublicAnnouncement/Form.vue'
import PermissionsList from '../views/Permissions/List.vue'
import PermissionsForm from '../views/Permissions/Form.vue'
import UsersList from '../views/Users/List.vue'
import Login from '../views/Login.vue'
import GroupsList from '../views/Groups/List.vue'
import GroupsForm from '../views/Groups/Form.vue'
import GroupsView from '../views/Groups/View.vue'
import SalutationsForm from '../views/Salutations/Form.vue'
import CashFlow from '../views/Finance/Cashflow.vue'
import EmailsList from '../views/Emails/List.vue'
import TrainingPatternsForm from '../views/TrainingPatterns/Form.vue'
import MembershipTypesForm from '../views/MembershipTypes/Form.vue'
import PaymentsList from '../views/Payments/List.vue'
import PaymentsForm from '../views/Payments/Form.vue'
import PaymentsView from '../views/Payments/View.vue'
import TrainingsForm from '../views/Trainings/Form.vue'
import TrainingsView from '../views/Trainings/View.vue'
import TrainingsList from '../views/Trainings/List.vue'
import ModifyPresence from '../views/Trainings/ModifyPresence.vue'
import UsersForm from '../views/Users/Form.vue'
import AssignTraining from '../views/Users/AssignTraining.vue'
import Substitutions from '../views/Users/Substitutions.vue'
import AssignGroup from '../views/Users/AssignGroup.vue'
import FirstTraining from '../views/Users/FirstTraining.vue'
import UsersView from '../views/Users/View.vue'
import MembershipsList from '../views/Memberships/List.vue'
import MembershipsForm from '../views/Memberships/Form.vue'
import MembershipsSuspend from '../views/Memberships/Suspend.vue'
import MembershipsResume from '../views/Memberships/Resume.vue'
import TriggersList from '../views/Triggers/List.vue'
import TriggersForm from '../views/Triggers/Form.vue'
import TextList from '../views/Texts/List.vue'
import TextForm from '../views/Texts/Form.vue'
import TextView from '../views/Texts/View.vue'
import Logout from '../views/Authentication/Logout.vue'
import SubstituteForm from '../views/Trainings/SubstituteForm.vue'

import GenericView from '../views/GenericView.vue'
import GenericList from '../views/GenericList.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Dashboard',
    path: '/',
    component: Dashboard,
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      layout: 'guest',
      guestAuth: 'yes',
    },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'Permissions',
    path: '/permissions',
    component: PermissionsList,
  },
  {
    name: 'PermissionsCreate',
    path: '/permissions/create',
    component: PermissionsForm,
  },
  {
    name: 'UsersRegistrations',
    path: '/users/registrations',
    component: RegistrationsList,
  },
  {
    name: 'MembershipsExtended',
    path: '/memberships/extended',
    component: ExtendedMemberships,
  },
  {
    name: 'GymsView',
    path: '/gyms/:gymId',
    component: GymsView,
  },
  {
    name: 'GymsMessage',
    path: '/gyms/:gymId/message',
    component: GymMessageForm,
  },
  {
    name: 'GymsEdit',
    path: '/gyms/:gymId/edit',
    component: GymsForm,
  },
  {
    name: 'Groups',
    path: '/groups',
    component: GroupsList,
  },
  {
    name: 'GroupsReport',
    path: '/groups/report',
    component: GroupsReport,
  },
  {
    name: 'GroupsCreate',
    path: '/groups/create',
    component: GroupsForm,
  },
  {
    name: 'GroupsView',
    path: '/groups/:groupId',
    component: GroupsView,
  },
  {
    name: 'GroupsEdit',
    path: '/groups/:groupId/edit',
    component: GroupsForm,
  },
  {
    name: 'GroupsReservesCreate',
    path: '/groups/:groupId/reserves/create',
    component: GroupReservesForm,
  },
  {
    name: 'Salutations',
    path: '/salutations',
    component: GenericList,
    meta: {
      headline: 'Salutations',
      listItemComponent: 'SalutationItem',
      apiEndpoint: 'salutations',
      routeEndpoint: 'salutations',
    },
  },
  {
    name: 'SalutationsView',
    path: '/salutations/:id',
    component: GenericView,
    meta: {
      component: 'SalutationItem',
      apiEndpoint: 'salutations',
      routeEndpoint: 'salutations',
    },
  },
  {
    name: 'SalutationsEdit',
    path: '/salutations/:salutationId/edit',
    component: SalutationsForm,
  },
  {
    name: 'FinanceSalary',
    path: '/finance/salary',
    component: Salary,
  },
  {
    name: 'FinanceCashflow',
    path: '/finance/cashflow',
    component: CashFlow,
  },
  {
    name: 'Emails',
    path: '/emails',
    component: EmailsList,
  },
  {
    name: 'EmailsCreate',
    path: '/emails/create',
    component: EmailForm,
  },
  {
    name: 'EmailsView',
    path: '/emails/:emailId',
    component: EmailsView,
  },
  {
    name: 'EmailTemplates',
    path: '/emailTemplates',
    component: GenericList,
    meta: {
      headline: 'Email Templates',
      listItemComponent: 'EmailTemplateItem',
      apiEndpoint: 'emailTemplates',
      routeEndpoint: 'emailTemplates',
    },
  },
  {
    name: 'EmailTemplatesCreate',
    path: '/emailTemplates/create',
    component: EmailTemplatesForm,
  },
  {
    name: 'EmailTemplatesView',
    path: '/emailTemplates/:templateId',
    component: EmailTemplatesView,
  },
  {
    name: 'EmailTemplatesEdit',
    path: '/emailTemplates/:templateId/edit',
    component: EmailTemplatesForm,
  },
  {
    name: 'TrainingPatternsCreate',
    path: '/groups/:groupId/training-patterns/create',
    component: TrainingPatternsForm,
  },
  {
    name: 'TrainingPatternsEdit',
    path: '/training-patterns/:trainingPatternId/edit',
    component: TrainingPatternsForm,
  },
  {
    name: 'UserStates',
    path: '/userStates',
    component: GenericList,
    meta: {
      headline: 'User States',
      listItemComponent: 'UserStateItem',
      apiEndpoint: 'userStates',
      routeEndpoint: 'userStates',
    },
  },
  {
    name: 'UserStatesCreate',
    path: '/userStates/create',
    component: UserStatesForm,
  },
  {
    name: 'UserStatesView',
    path: '/userStates/:id',
    component: GenericView,
    meta: {
      component: 'UserStateItem',
      apiEndpoint: 'userStates',
      routeEndpoint: 'userStates',
    },
  },
  {
    name: 'UserStatesEdit',
    path: '/userStates/:userStateId/edit',
    component: UserStatesForm,
  },
  {
    name: 'MembershipTypes',
    path: '/membershipTypes',
    component: GenericList,
    meta: {
      headline: 'Membership Types',
      listItemComponent: 'MembershipTypeItem',
      apiEndpoint: 'membershipTypes',
      routeEndpoint: 'membershipTypes',
    },
  },
  {
    name: 'MembershipTypesCreate',
    path: '/membershipTypes/create',
    component: MembershipTypesForm,
  },
  {
    name: 'MembershipTypesView',
    path: '/membershipTypes/:id',
    component: GenericView,
    meta: {
      component: 'MembershipTypeItem',
      apiEndpoint: 'membershipTypes',
      routeEndpoint: 'membershipTypes',
    },
  },
  {
    name: 'MembershipTypesEdit',
    path: '/membershipTypes/:membershipTypeId/edit',
    component: MembershipTypesForm,
  },
  {
    name: 'Payments',
    path: '/payments',
    component: PaymentsList,
  },
  {
    name: 'PaymentsCreate',
    path: '/payments/create',
    component: PaymentsForm,
  },
  {
    name: 'PaymentsView',
    path: '/payments/:paymentId',
    component: PaymentsView,
  },
  {
    name: 'PaymentsEdit',
    path: '/payments/:paymentId/edit',
    component: PaymentsForm,
  },
  {
    name: 'TrainingPatternsTrainingsCreate',
    path: '/training-patterns/:trainingPatternId/trainings/create',
    component: TrainingsForm,
  },
  {
    name: 'Trainings',
    path: '/trainings',
    component: TrainingsList,
  },
  {
    name: 'TrainingsView',
    path: '/trainings/:trainingId',
    component: TrainingsView,
  },
  {
    name: 'TrainingsEdit',
    path: '/trainings/:trainingId/edit',
    component: TrainingsForm,
  },
  {
    name: 'TrainingsModifyPresence',
    path: '/trainings/:trainingId/modifyPresence',
    component: ModifyPresence,
  },
  {
    name: 'SubstituteTraining',
    path: '/trainings/:trainingId/substitute',
    component: SubstituteForm,
  },
  {
    name: 'ActiveUsers',
    path: '/active-users',
    component: ActiveUsersList,
  },
  {
    name: 'LostUsers',
    path: '/lost-users',
    component: LostUsersList,
  },
  {
    name: 'Users',
    path: '/users',
    component: UsersList,
  },
  {
    name: 'UsersCreate',
    path: '/users/create',
    component: UsersForm,
  },
  {
    name: 'Substitutions',
    path: '/users/:userId/substitutions',
    component: Substitutions,
  },
  {
    name: 'FirstTraining',
    path: '/users/:userId/first-training',
    component: FirstTraining,
  },
  {
    name: 'UsersAssignTraining',
    path: '/users/:userId/assignTraining',
    component: AssignTraining,
  },
  {
    name: 'UsersInstructorTrainings',
    path: '/users/:userId/instructor-trainings',
    component: InstructorTrainingsList,
  },
  {
    name: 'UsersGroups',
    path: '/users/:userId/groups',
    component: AssignGroup,
  },
  {
    name: 'GroupsUsersAssign',
    path: '/groups/:groupId/users/:userId',
    component: AssignGroup,
  },
  {
    name: 'GroupsUsers',
    path: '/groups/:groupId/users',
    component: AssignGroup,
  },
  {
    name: 'UsersView',
    path: '/users/:userId',
    component: UsersView,
  },
  {
    name: 'UsersEdit',
    path: '/users/:userId/edit',
    component: UsersForm,
  },
  {
    name: 'Memberships',
    path: '/memberships',
    component: MembershipsList,
  },
  {
    name: 'MembershipSuspend',
    path: '/memberships/:membershipId/suspend',
    component: MembershipsSuspend,
  },
  {
    name: 'MembershipResume',
    path: '/memberships/:membershipId/resume',
    component: MembershipsResume,
  },
  {
    name: 'MembershipsCreate',
    path: '/users/:userId/memberships/create',
    component: MembershipsForm,
  },
  {
    name: 'MembershipsEdit',
    path: '/memberships/:membershipId/edit',
    component: MembershipsForm,
  },
  {
    name: 'PublicAnnouncement',
    path: '/public-announcement',
    component: PublicAnnouncementForm,
  },
  {
    name: 'Triggers',
    path: '/triggers',
    component: TriggersList,
  },
  {
    name: 'TriggersCreate',
    path: '/triggers/:id/create',
    component: TriggersForm,
  },
  {
    name: 'Texts',
    path: '/texts',
    component: TextList,
  },
  {
    name: 'TextsView',
    path: '/texts/:slug',
    component: TextView,
  },
  {
    name: 'TextsEdit',
    path: '/texts/:slug/edit',
    component: TextForm,
  },
]

const router = new VueRouter({
  routes,
})

export default router
