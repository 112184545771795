<template>
    <v-container fluid>
        <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
        <v-row wrap v-if="!loading">
            <v-col xs12 lg8 offset-lg2>
                <v-card class="mb-4">
                    <v-card-title>
                        <span>Add user to waiting list</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <user-autocomplete @change="updateUser"></user-autocomplete>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="addUser()" :loading="submitting" :disabled="submitting"
                            >Add user</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WithLoading from '../../mixins/WithLoading.js'
import UserAutocomplete from '../../components/Autocompletes/UserAutocomplete.vue'
import ErrorMixin from '../../mixins/ErrorMixin.js'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [WithLoading, ErrorMixin],
    components: { UserAutocomplete },
    data() {
        return {
            user: null,
            group: null,
            submitting: false,
        }
    },
    validations: {
        user: { required },
    },
    computed: {
        userErrors() {
            const errors = []
            if (!this.$v.user.$dirty) return errors
            !this.$v.user.required && errors.push('User is required.')
            return errors
        },
    },
    methods: {
        updateUser(user) {
            this.user = user
        },
        addUser() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return false
            }

            this.submitting = true

            var reservation = {
                user_id: this.user.id,
                group_id: this.group.id,
            }

            this.$http
                .post('v1/tenant/' + this.tenant + '/groupReserves/', reservation)
                .then(() => {
                    this.submitting = false
                    this.$router.go(-1)
                    this.$store.dispatch('showSuccessMessage', 'User has been added on waiting list.')
                })
                .catch(this.handleResponseErrors)
        },
        fetchData() {
            this.loadingStart()
            return Promise.all([this.$http.get('v1/tenant/' + this.tenant + '/groups/' + this.$route.params.groupId)])
                .then((response) => {
                    this.group = response[0].data

                    this.$store.dispatch('setBreadcrumbs', [
                        {
                            text: 'Home',
                            href: this.$router.resolve({ name: 'Dashboard' }).href,
                        },
                        {
                            text: 'Groups',
                            href: this.$router.resolve({ name: 'Groups' }).href,
                        },
                        {
                            text: this.group.name,
                            href: this.$router.resolve({
                                name: 'GroupsView',
                                params: { groupId: this.group.id },
                            }).href,
                        },
                        {
                            text: 'Add user to waiting list',
                        },
                    ])

                    this.loadingCompleted()
                })
                .catch((response) => {
                    this.loadingFailed(response)
                })
        },
    },
}
</script>
