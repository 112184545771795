<template>
  <div>
    <v-list two-line>
      <component
        :is="listItemComponent"
        :item="item"
        v-for="item in items"
        :key="item.id"
        :tenant="tenant"
        @delete="deleteItem"
        @edit="editItem"
      ></component>
    </v-list>
    <div class="text-center pb-2" v-if="showMoreButton">
      <v-btn color="primary" @click.native="showMore"> Show more ({{ hiddenCount }} hidden) </v-btn>
    </div>
  </div>
</template>

<script>
import GroupItem from './list-items/GroupItem.vue'
import UserItem from './list-items/UserItem.vue'
import TrainingItem from './list-items/TrainingItem.vue'
import EmailItem from './list-items/EmailItem.vue'
import EmailTemplateItem from './list-items/EmailTemplateItem.vue'
import UserStateItem from './list-items/UserStateItem.vue'
import MembershipTypeItem from './list-items/MembershipTypeItem.vue'
import SalutationItem from './list-items/SalutationItem.vue'
import TrainingPatternItem from './list-items/TrainingPatternItem.vue'
import WaitingListItem from './list-items/WaitingListItem.vue'
import UserMembershipItem from './list-items/UserMembershipItem.vue'
import PaymentItem from './list-items/PaymentItem.vue'
import ExtendedMembershipItem from './list-items/ExtendedMembershipItem.vue'
import GroupReportItem from './list-items/GroupReportItem.vue'
import SalaryItem from './list-items/SalaryItem.vue'
import PermissionItem from './list-items/PermissionItem.vue'
import UserTrainingPatternItem from './list-items/UserTrainingPatternItem.vue'
import UserPaymentItem from './list-items/UserPaymentItem.vue'
import UserTrainingPresenceItem from './list-items/UserTrainingPresenceItem.vue'
import UserRegistrationItem from './list-items/UserRegistrationItem.vue'
import TextItem from './list-items/TextItem.vue'

export default {
  components: {
    TextItem,
    UserTrainingPresenceItem,
    UserPaymentItem,
    UserTrainingPatternItem,
    PermissionItem,
    SalaryItem,
    GroupReportItem,
    ExtendedMembershipItem,
    PaymentItem,
    UserMembershipItem,
    WaitingListItem,
    TrainingPatternItem,
    GroupItem,
    TrainingItem,
    UserItem,
    EmailItem,
    EmailTemplateItem,
    UserStateItem,
    MembershipTypeItem,
    SalutationItem,
    UserRegistrationItem,
  },
  name: 'list-component',
  props: ['loadedItems', 'filterData', 'filter', 'tenant', 'listItemComponent'],
  data() {
    return {
      limitCount: 15,
    }
  },
  methods: {
    showMore() {
      this.limitCount = this.limitCount + 10
    },
    deleteItem(item) {
      var index = this.loadedItems.indexOf(item)
      this.loadedItems.splice(index, 1)
      this.$emit('delete', item)
    },
    editItem(item) {
      this.$emit('edit', item)
    },
  },
  computed: {
    filteredItems() {
      if (this.filterData) {
        return this.loadedItems.filter(this.filter)
      }
      return this.loadedItems
    },
    filteredCount() {
      return this.filteredItems.length
    },
    showMoreButton() {
      return this.hiddenCount > 0
    },
    hiddenCount() {
      return this.filteredCount - this.limitCount
    },
    items() {
      return this.filteredItems.slice(0, this.limitCount)
    },
  },
}
</script>
